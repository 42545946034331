
import Logo from "../../../core/resources/assets/logos/logo.png";
import "../../styles/Navbars.css";
import { NavLink } from "react-router-dom";
import { Container, Navbar, Col, Row, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Logo1, Logo2 } from "../../../core/resources/assets";
import { useEffect, useState } from "react";

function Navbar_tablet_view() {
  const { t } = useTranslation();
  const path = useLocation()
  const [activeLogo, setActiveLogo] = useState(Logo2)
  const [ourProductPage, setOurProductPage] = useState(false);

  useEffect(() => {
    if (path.pathname === "/why-nsano") {
      setActiveLogo(Logo1)
    }
    if (path.pathname == "/our-products") {
      setOurProductPage(true)
    }
  }, [])

  return (
    <Navbar className="bg-transparent">
      <Container className=" mt-4">
        <Row className=" w-100">
          <Col md={3} className="">
            <Navbar.Brand href="/" className="">
              <img
                src={activeLogo}
                style={{height:"30px", width:"90px"}}
                className="d-inline-block align-top"
                alt=""
              />
            </Navbar.Brand>
          </Col>
          <Col>
            <div className="text-center ">
              <ul
                id="menu-menu-1"
                className="navbar-nav me-auto mb-2 mb-md-0 navbar-nav "
              >
                <li className="menu-item menu-item-type-custom menu-item-object-custom nav-item nav-item-32739 ">
                  <NavLink
                    to="/why-nsano"
                    className="nav-link fw-semibold"
                    style={{ fontSize: "13px" }}
                  >
                    {t("Why_Nsano")}
                  </NavLink>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-home nav-item nav-item-32738  dropdown position-static px-4">
                  <NavLink
                    className="nav-link fw-semibold dropdown-toggle"
                    to="/our-products"
                    id="navbarDropdown"
                    role="button"
                    data-mdb-toggle="dropdown"
                    aria-expanded="false"

                    style={{ fontSize: "13px", width: "auto" }}
                  >
                    {t("Our_Products")}
                  </NavLink>


                  {!ourProductPage ? (
                    <div
                      className="dropdown-menu w-20  mt-2"
                      aria-labelledby="navbarDropdown"
                      style={{
                        borderRadius: "20px",
                        border: "none",
                        //   width:"100px"
                        marginLeft: "-1%"
                      }}
                    >
                      <Card
                        style={{
                          background: "#f2f2f2",
                          width: "450px",
                          border: "none",
                          borderRadius: "20px",
                          // marginLeft:"-5% !important",
                        }}
                      >
                        <Card.Body>
                          <Row className="mb-4">
                            <Col className="bg-transparent" md={6} style={{ fontSize: "12px" }}>
                              <NavLink
                                to="/our-products"
                                className="list-group-item bg-transparent  list-group-item-action border-0"
                              >
                                <div className="fw-bold ">{t("Third_Party_Payment_Processing")}</div>

                                <span style={{ fontSize: "0.7rem" }} className=" text-muted">
                                  {t("Third_Party_Payment_Processing_content")}
                                </span>
                              </NavLink>
                            </Col>
                            <Col className="bg-transparent" md={6} style={{ fontSize: "12px" }}>
                              <NavLink
                                to="/our-products"
                                className="list-group-item bg-transparent list-group-item-action border-0"
                              >
                                <div className="fw-bold ">{t("Mobile_Money_Aggregation")}</div>

                                <span style={{ fontSize: "0.7rem" }} className=" text-muted">
                                  {t("Mobile_Money_Aggregation_content")}
                                </span>
                              </NavLink>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="bg-transparent" md={6} style={{ fontSize: "12px" }}>
                              <NavLink
                                to="/our-products"
                                className="list-group-item bg-transparent list-group-item-action border-0"
                              >
                                <div className="fw-bold ">{t("Bulk_Payment_Solutions")}</div>

                                <span style={{ fontSize: "0.7rem" }} className=" text-muted">
                                  {t("Bulk_Payment_Solutions_content")}
                                </span>
                              </NavLink>
                            </Col>
                            <Col className="bg-transparent" md={6} style={{ fontSize: "12px" }}>
                              <NavLink
                                to="/our-products"
                                className="list-group-item bg-transparent list-group-item-action border-0"
                              >
                                <div className="fw-bold ">{t("Remittance")}</div>

                                <span style={{ fontSize: "0.7rem" }} className=" text-muted">
                                  {t("Remittance_content")}
                                </span>
                              </NavLink>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>

                    </div>
                  ) : ""}

                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-home nav-item nav-item-32738 px-4">
                  <NavLink
                    to="/our-people"
                    className="nav-link fw-semibold "
                    style={{ fontSize: "13px" }}
                  >
                    {t("Our_People")}
                  </NavLink>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-home nav-item nav-item-32738 px-4">
                  <NavLink
                    to="/blog"
                    className="nav-link fw-semibold"
                    style={{ fontSize: "13px" }}
                  >
                    {t("Blog")}
                  </NavLink>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-home nav-item nav-item-32738 px-4">
                  <NavLink
                    to="/careers"
                    className="nav-link fw-semibold"
                    style={{ fontSize: "13px" }}
                  >
                    {t("Careers")}
                  </NavLink>
                </li>
              </ul>{" "}
            </div>
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
}

export default Navbar_tablet_view;
