import React from "react";
import "../styles/footer.css";
import { Link } from "react-router-dom";
import Logo from "../../core/resources/assets/logos/logo.png";
import { useTranslation } from "react-i18next";
import {
  Image,
} from "react-bootstrap";
// import Langswitcher from "./Translations/langswitcher";

import Logo1 from "../../core/resources/assets/logos/msecb.png";
import Logo2 from "../../core/resources/assets/logos/bkgh.png";
import Logo3 from "../../core/resources/assets/logos/bkzm.png";
import Logo4 from "../../core/resources/assets/logos/PCIDSS.png";

import CircleGhFlag from "../../core/resources/assets/images/circlegh.png";
import CircleZmFlag from "../../core/resources/assets/images/circlezambia.png";
import CircleCtFlag from "../../core/resources/assets/images/circlecote.png";
import CircleSlFlag from "../../core/resources/assets/images/circlesierre.png";
import CircleUgFlag from "../../core/resources/assets/images/circleuganda.png";
import CircleRwFlag from "../../core/resources/assets/images/circlerwanda.png";


function FooterNew(props) {
  const { t } = useTranslation()



  return (
    <div>
      <section className="">
        <div className="container bg-transparent py-6 text-center mt-5">
          <div className="col-md-12 navbar-expand">
            <div lc-helper="shortcode" className="live-shortcode ">
              {" "}
              <ul
                id="menu-menu-1"
                className="navbar-nav me-auto mb-2 mb-md-0 d-grid d-flex justify-content-center align-items-center but"
              >
                <li className="menu-item menu-item-type-custom menu-item-object-custom nav-item nav-item-32739">
                  <Link to="/faqs" className="nav-link btn-footer">
                    FAQs
                  </Link>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-home nav-item nav-item-32738">
                  <Link to="/why-nsano" className="nav-link btn-footer">
                  {t("Why_Nsano")}
                  </Link>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-home nav-item nav-item-32738">
                  <a
                    href="mailto:sales@nsano.com"
                    className="nav-link btn-footer"
                  >
                    {t("Contact_us")}
                  </a>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-home nav-item nav-item-32738">
                <Link to="/privacy" className="nav-link btn-footer">
                {t("privacy-footer")}
                  </Link>
                </li>
                <div className="">
                    <div className=" btn-footer-regulators ">
                      <div className="d-flex items-align-center">
                      <div className=""><Image src={Logo1} className="" style={{height:"30px",width:"30px",objectFit:"contain"}}/></div> 
                      <div ><Image src={Logo2} className="" style={{height:"30px",width:"50px",objectFit:"contain"}}/></div> 
                      <div className=""><Image src={Logo3} className="" style={{height:"30px",width:"40px",objectFit:"contain"}}/></div> 
                      <div className=""><Image src={Logo4} className="" style={{height:"30px",width:"50px",objectFit:"contain"}}/></div> 
                    </div>
                </div>
              </div>
              </ul>{" "}
            </div>
          </div>

          <div className="lc-block">
            <div>
              <p className="text-muted my-2"> {t("Copyright")} - Nsano 2022</p>
            </div>
            <img className="my-2" src={Logo} alt="" width="80px" />
          </div>
        </div>
      </section>
    </div>
  );
}

export default FooterNew

