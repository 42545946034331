import { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import '../../styles/blog.css';
import axios from "axios";
import { Card, Col } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ContentShimmer from "react-content-shimmer";



interface dataType {
  id: number;
  title: title;
  excerpt: excerpt;
  date: string;
  better_featured_image: better_featured_image;
}

interface title {
  rendered: string;
}

interface better_featured_image {
  media_details: media_details;
}
interface media_details {
  sizes: sizes;
}
interface sizes {
  thumbnail: thumbnail;
}

interface thumbnail {
  source_url: any;
}

interface excerpt {
  rendered: any;
}

const MobileLatestNews = () => {
  const [data, setData] = useState<dataType[]>([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

const fetch = async () => {
  try {
    const data = await axios
      .get(`${process.env.REACT_APP_BASE_URL}/${t("latest_news")}`, {
        headers: {
          // "ngrok-skip-browser-warning": "69420",
          //         Authorization: `Bearer ${process.env.AUTH_TOKEN}`,
        },
      })
      .then((res) => {
        setData(res.data);
      });
    setLoading(true);
  } catch (e) {
    console.log(e);
  }
};

useEffect(() => {
  fetch();
}, []);

  const colors = ["#40BE65", "#0072CA", "#D9A34A", "#FF4967", "purple"];


  return (
    <>
    {loading ? (<div>
      <div style={{borderRadius:"20px",marginTop:"-15px"}} >
      <Card className="border border-0 shadow-none bg-transparent" style={{height:"600px"}} >
        <Card.Header
          className="text-white text-uppercase fw-semibold text-center"
          style={{ background: "#271C1C", fontSize: "12px" , borderTopLeftRadius:"20px",borderTopRightRadius:"20px"}}
        >
          {t("latest_news_header")}
        </Card.Header>
        <Card.Body className="bg-white">
          {data.slice(0, 4).map((item, index) => (
            <Row key={item.id} className="align-items-center gy-2 gx-4 py-2" style={{height:"122px"}}>
              <Col md={5} sm={3} xs={3}>
                <img
                  src={
                    item.better_featured_image.media_details.sizes.thumbnail
                      .source_url
                  }
                  alt="voopo voip"
                  className="image-fluid rounded "
                  style={{ objectFit: "cover", width: "100%", height:"" }}
                />
              </Col>
              <Col>
                <Link
                  className="text-decoration-none"
                  to={`/news/${item.id}`}
                  style={{ fontSize: "10px" }}
                >
                    <span className=" text-uppercase  fw-semibold" style={{ color: colors[index % colors.length] }}>{item.title.rendered.substring(0,10)+'....' }</span>
                  

                  <span
                    className="text-muted "
                    dangerouslySetInnerHTML={{
                      __html: item.excerpt.rendered.substring(0,70)+'...'+ 'read more',
                    }}
                  ></span>
                </Link>
              </Col>
            </Row>
          ))}
        </Card.Body>
        <Card.Footer className="text-center bg-white shadow-none footer-card-radius" style={{borderBottomLeftRadius:"20px",borderBottomRightRadius:"20px",}}>
          <Link
            to="/more-news"
            className="text-decoration-none text-uppercase  text-black"
            style={{ fontSize: "12px", }}
          >
            {t('more_News')}
          </Link>
        </Card.Footer>
      </Card>
    </div>
    </div>) : (
       <div className="bg-light" style={{marginTop:"-15px",width:"100%", height:"600px", borderRadius:"20px"}}> <div >
       <div  style={{  width: "100%"}}>
         {/* <div className="p-2 lign-items-center "> */}
           <div
             
             style={{
               width: "100%",
               borderRadius: "20px",
               height: "350px",
             }}
           >
            <div className="px-2 mt-3">
                     <ContentShimmer
                     style={{marginTop: "1rem", width: "w-100", height: "20px",
                     }}
                     rounded={"10px"}/>
            </div>
   
             <Row >
               <Col >
                 <div className="p-3">
                   <ContentShimmer
                     style={{width: "100%", height:"90px"}}
                     rounded="5%"
                   />
                 </div>
                 </Col>
                 <Col>
                 <div className="px-2">
                     <ContentShimmer
                     style={{marginTop: "1rem", width: "w-100", height: "10px",
                     }}
                     rounded={"10px"}/>
                      <ContentShimmer
                     style={{marginTop: "1rem", width: "w-100", height: "10px",
                     }}
                     rounded={"10px"}/>
                     <ContentShimmer
                     style={{marginTop: "1rem", width: "w-100", height: "10px",
                     }}
                     rounded={"10px"}/>
                     </div>
                 </Col>
             </Row>
   
             <Row>
                 <Col>
                   <div className="p-3"> 
                     <ContentShimmer
                     style={{width: "100%", height:"90px"}}
                       rounded="5%"
                     />
                   </div> 
                 </Col>
                 <Col>
                 <div className="px-2">
                    <ContentShimmer
                     style={{marginTop: "1rem", width: "w-100", height: "10px",
                     }}
                     rounded={"10px"}/>
                      <ContentShimmer
                     style={{marginTop: "1rem", width: "w-100", height: "10px",
                     }}
                     rounded={"10px"}/>
                     <ContentShimmer
                     style={{marginTop: "1rem", width: "w-100", height: "10px",
                     }}
                     rounded={"10px"}/>
                     </div>
                 </Col>
             </Row>
   
             <Row>
                 <Col>
                   <div className="p-3"> 
                     <ContentShimmer
                     style={{width: "100%", height:"90px"}}
                       rounded="5%"
                     />
                   </div> 
                 </Col>
                 <Col>
                 <div className="px-2">
                    <ContentShimmer
                     style={{marginTop: "1rem", width: "w-100", height: "10px",
                     }}
                     rounded={"10px"}/>
                      <ContentShimmer
                     style={{marginTop: "1rem", width: "w-100", height: "10px",
                     }}
                     rounded={"10px"}/>
                     <ContentShimmer
                     style={{marginTop: "1rem", width: "w-100", height: "10px",
                     }}
                     rounded={"10px"}/>
                     </div>
                 </Col>
             </Row>
             <Row>
                 <Col>
                   <div className="p-3"> 
                     <ContentShimmer
                     style={{width: "100%", height:"90px"}}
                       rounded="5%"
                     />
                   </div> 
                 </Col>
                 <Col>
                 <div className="px-2">
                    <ContentShimmer
                     style={{marginTop: "1rem", width: "w-100", height: "10px",
                     }}
                     rounded={"10px"}/>
                      <ContentShimmer
                     style={{marginTop: "1rem", width: "w-100", height: "10px",
                     }}
                     rounded={"10px"}/>
                     <ContentShimmer
                     style={{marginTop: "1rem", width: "w-100", height: "10px",
                     }}
                     rounded={"10px"}/>
                     </div>
                 </Col>
             </Row>
   
             <div className="px-2 mt-5">
                     <ContentShimmer
                     style={{marginTop: "1rem", width: "w-100", height: "20px",
                     }}
                     rounded={"10px"}/>
            </div>
           </div>
         {/* </div> */}
       </div>
     </div>
   </div>
    )}
    </>
   
     );
};

export default MobileLatestNews;
