import { useState, useEffect } from "react";
import Navbars from "../navbar/Navbars";
import axios from "axios";
import { useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import * as ReactBootstrap from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import ContentShimmer from "react-content-shimmer";

interface dataType {
  id: id;
  title: title;
  content: content;
  acf: acf;
}
interface id {
  id: number;
}

interface title {
  rendered: string;
}

interface content {
  rendered: string;
  protected: boolean;
}

interface acf {
  images: images;
}

interface images {
  first: any;
  second: number;
  third: number;
  fourth: number;
}

function Single_Experience() {
  const { t } = useTranslation();

  const [newsData, setNewsData] = useState<dataType>();
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = useParams();
  //   console.log(`this is the id ${id}`);

  const fetch = async () => {
    try {
      const {data} = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/${t("experience")}/${id}`,
        {
          headers: {
            // "ngrok-skip-browser-warning": "69420",
            //         Authorization: `Bearer ${process.env.AUTH_TOKEN}`,
          },
        }
      );
      setNewsData(data);
      setLoading(true);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetch();
  });

  return (
    <div>
      <Navbars />
      {loading ? (
        <div className="container mt-5 px-5">
          <div className="row mb-3">
            <div className="col-lg-8 mx-auto">
              <div className="lc-block mb-4">
                <h3 className="fw-semibold display-2 animate__animated animate__fadeInRight animate__delay-1s">
                  {newsData && newsData.title?.rendered}
                  {/* {JSON.stringify(newsData!.title!.rendered)} */}
                </h3>
              </div>
              <img
                src={newsData && newsData.acf.images.first}
                // width="800px"
                // height="450px"
                className="d-block w-100 rounded-4 animate__animated animate__fadeInRight animate__delay-2s"
                alt="..."
                style={{ objectFit: "cover", width: "100%", height: "400px" }}
              />
              <div className="lc-block">
                <p
                  className="lead mt-3 justify fs-6 animate__animated animate__fadeInRight animate__delay-3s"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      newsData && newsData.content?.rendered
                    ),
                  }}
                  style={{fontFamily:"Times New Roman"}}
                />
                {/* <span className="fs-6"> {newsData && newsData.date}</span> */}
              </div>
            </div>
          </div>
          {/* <Footer/> */}
        </div>
      ) : (
        <>
          <div style={{ width: "100%", height: "100%", borderRadius: "20px" }}>
            {" "}
            <div>
              <div style={{ width: "100%" }}>
                {/* <div className="p-2 lign-items-center "> */}
                <div
                  style={{
                    width: "100%",
                    borderRadius: "",
                    height: "100vh",
                  }}
                >
                  <Container>
                    <div className="px-5 py-5">
                      <div className="row mb-3">
                        <div className="col-lg-8 mx-auto">
                          <div className="d-inline">
                            <div className="px-2 mt-3">
                              <ContentShimmer
                                style={{
                                  marginTop: "1rem",
                                  width: "40%",
                                  height: "25px",
                                }}
                                rounded={"10px"}
                              />
                              <ContentShimmer
                                style={{
                                  marginTop: "1rem",
                                  width: "60%",
                                  height: "25px",
                                }}
                                rounded={"10px"}
                              />
                              <ContentShimmer
                                style={{
                                  marginTop: "1rem",
                                  width: "w-100",
                                  height: "25px",
                                }}
                                rounded={"10px"}
                              />
                            </div>
                            <div className="px-2 mt-3">
                              <div>
                                <ContentShimmer
                                  style={{ width: "100%", height: "400px" }}
                                  rounded="5%"
                                />
                              </div>
                            </div>
                            <div className="px-2 mt-3">
                              <ContentShimmer
                                style={{
                                  marginTop: "1rem",
                                  width: "w-100",
                                  height: "10px",
                                }}
                                rounded={"10px"}
                              />
                              <ContentShimmer
                                style={{
                                  marginTop: "1rem",
                                  width: "w-100",
                                  height: "10px",
                                }}
                                rounded={"10px"}
                              />
                              <ContentShimmer
                                style={{
                                  marginTop: "1rem",
                                  width: "w-100",
                                  height: "10px",
                                }}
                                rounded={"10px"}
                              />
                              <ContentShimmer
                                style={{
                                  marginTop: "1rem",
                                  width: "w-100",
                                  height: "10px",
                                }}
                                rounded={"10px"}
                              />
                              <ContentShimmer
                                style={{
                                  marginTop: "1rem",
                                  width: "w-100",
                                  height: "10px",
                                }}
                                rounded={"10px"}
                              />
                              <ContentShimmer
                                style={{
                                  marginTop: "1rem",
                                  width: "w-100",
                                  height: "10px",
                                }}
                                rounded={"10px"}
                              />
                              <ContentShimmer
                                style={{
                                  marginTop: "1rem",
                                  width: "w-100",
                                  height: "10px",
                                }}
                                rounded={"10px"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>

                <div className=" h-100">
                  <div className="position-absolute top-50 start-50 translate-middle">
                    <div className="">
                      <ReactBootstrap.Spinner animation="border" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Single_Experience;
