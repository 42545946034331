

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { createMedia } from "@artsy/fresnel";
import Navbar_desktop_view from "./navbar_desktop";
import Navbar_tablet_view from "./navbar_tablet";
import Navbar_mobile_view from "./navbar_mobile";

function Navbars() {
  const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
      // xs: 0,
      sm: 0,
      md: 1200,
      lg: 1600,
      xl: 2065,
    },
  });

  return (
    <MediaContextProvider>
      <Media at="sm">
        <Navbar_mobile_view />
      </Media>
      <Media at="md">
        <Navbar_tablet_view />
      </Media>
      <Media greaterThanOrEqual="lg">
        <Navbar_desktop_view />
      </Media>
    </MediaContextProvider>
  );
}

export default Navbars;
