import { useState, useEffect, useRef } from "react";
import VantaGlobe from "vanta/dist/vanta.globe.min";
import Navbars from "../components/navbar/Navbars";
import "../styles/home.css";
import Footer from "../components/Footer";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
// import globe from "../../core/resources/assets/";
import { Bullet, Upicon } from "../../core/resources/assets/index";
import {
  Container,
  Row,
  Col,
  OverlayTrigger,
  Popover,
  Image,
  Button,
} from "react-bootstrap";
import "animate.css";
import Brand from "../components/brand";
import { useTranslation } from "react-i18next";
import Home_Carousel from "../components/carousel_for_landing_page/carousel_index";

import Logo1 from "../../core/resources/assets/logos/msecb.png";
import Logo2 from "../../core/resources/assets/logos/bkgh.png";
import Logo3 from "../../core/resources/assets/logos/bkzm.png";
import Logo4 from "../../core/resources/assets/logos/PCIDSS.png";

// import { GlobePage } from "../components/globe/globe";
import {
  Zambia,
  Ghana,
  Mali,
  Malawi,
  Cameroon,
  Zimbabwe,
  Gambia,
  Nigeria,
  Togo,
  Tanzania,
  Benin,
  Burkina,
  Sierre,
  Rwanda,
  Senegal,
  Liberia,
  Kenya,
  UK,
  Cote,
  Uganda,
  Gabon,
  Guinea,
  RCA,
  Botswana,
  Chad,
  Burundi,
  DRC,
  SouthSudan,
  SouthAfrica,
  Somalia,
  Morocco,
  Niger,
  EquatorialGuinea,
  Egypt,
} from "../../core/resources/assets/index";
import { IoCloseCircle } from "react-icons/io5";
import { D3Globe } from "../components/globe/d3-globe";
import { CountriesForFooter } from "../components/countries_for_footer";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";

function Home() {
  const [vantaEffect, setVantaEffect] = useState<VantaGlobe | null>(null);
  const myRef = useRef(null);
  const [showPromo, setShowPromo] = useState(true);

  useEffect(() => {
    const element = document.getElementById("myVantaGlobe");

    if (element && !vantaEffect) {
      setVantaEffect(
        VantaGlobe({
          el: element,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.0,
          minWidth: -200.0,
          scale: 1.0,
          scaleMobile: 1.0,
          color: 0xc8c8c8,
          color2: 0xfffdfd,
          size: 1.5,
          backgroundColor: "#ffffff",
        })
      );
    }

    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);

  const handleHidePromoBanner = () => {
    setShowPromo(false);
  };

  const { t } = useTranslation();
  const renderTooltip = (props: any) => (
    <Popover id="popover-basic" {...props}>
      <Popover.Body>
        {t("Presence_in_multiple_African_Markets_Content")}
      </Popover.Body>
    </Popover>
  );

  const renderTooltip2 = (props: any) => (
    <Popover id="popover-basic" {...props}>
      <Popover.Body>{t("Over_a_decade's_experience_content")}</Popover.Body>
    </Popover>
  );

  const renderTooltip3 = (props: any) => (
    <Popover id="popover-basic" {...props}>
      <Popover.Body>{t("Interoperability_Content")}</Popover.Body>
    </Popover>
  );

  const renderTooltip4 = (props: any) => (
    <Popover id="popover-basic" {...props}>
      <Popover.Body>{t("Highest_Security_Standards_Content")}</Popover.Body>
    </Popover>
  );

  const renderTooltip5 = (props: any) => (
    <Popover id="popover-basic" {...props}>
      <Popover.Body>{t("24/7_Support_Content")}</Popover.Body>
    </Popover>
  );

  const renderTooltip6 = (props: any) => (
    <Popover id="popover-basic" {...props}>
      <Popover.Body>
        {t("Robust_Reconciliation_&_Settlement_Content")}
      </Popover.Body>
    </Popover>
  );

  const renderTooltip7 = (props: any) => (
    <Popover id="popover-basic" {...props} placement="top">
      <Popover.Body className="pop-over-style d-none d-md-block">
        <div>
          <Row className="d-flex">
            {[
              Ghana,
              Zambia,
              Cote,
              Mali,
              Rwanda,
              Malawi,
              Cameroon,
              Zimbabwe,
              Gambia,
              Nigeria,
              Togo,
              Tanzania,
              Benin,
              Burkina,
              Sierre,
              Senegal,
              Liberia,
              Kenya,
              Uganda,
              Gabon,
              Guinea,
              RCA,
              Botswana,
              Chad,
              Burundi,
              DRC,
              SouthSudan,
              SouthAfrica,
              Somalia,
              Morocco,
              Niger,
              EquatorialGuinea,
              Egypt,
              UK,
            ].map((data, item) => (
              <Col md={3} sm={3} xs={3} key={item} className="my-2">
                <div>
                  <a className="dropdown-item mx-2" href="#/countries">
                    <img
                      src={data}
                      style={{
                        height: "30px",
                        width: "30px",
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                    />
                  </a>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Popover.Body>
    </Popover>
  );

  const renderTooltip8 = (props: any) => (
    <Popover id="popover-basic" {...props} placement="top">
      <Popover.Body className="pop-over-style d-none d-md-block">
        <div>
          <Row className="d-flex">
            {[Ghana, Rwanda, UK, Cote, Zambia].map((data, item) => (
              <Col md={2} sm={2} xs={2} key={item} className="my-2">
                <div>
                  <a
                    className="dropdown-item mx-2 globe-grid-text"
                    href="#/countries"
                  >
                    <Link to="/countries">
                      {" "}
                      <img
                        src={data}
                        style={{
                          height: "30px",
                          width: "30px",
                          objectFit: "cover",
                          objectPosition: "center",
                        }}
                      />
                    </Link>
                  </a>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Popover.Body>
    </Popover>
  );

  const reading = (
    <Row className="rounded text-md-start  text-center py-4 g-4 counter-1">
      <Col
        xs={4}
        lg={12}
        className=" text-sm-center text-lg-start country-hover"
      >
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 600 }}
          overlay={renderTooltip7}
          trigger="hover"
        >
          <div>
            <p className="lead fw-semibold fs-6 globe-grid-text">
              {t("Countries")} <img src={Upicon} alt="" className="arrow" />
            </p>
            <span className="fw-bold fs-2 display-5 mb-5">
              <>
                <CountUp end={30} redraw={true}>
                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start} delayedCall>
                      <span ref={countUpRef} />
                    </VisibilitySensor>
                  )}
                </CountUp>
                +
              </>
            </span>
          </div>
        </OverlayTrigger>
      </Col>
      <Col xs={4} lg={12} className=" text-center text-lg-start country-hover ">
        <OverlayTrigger
          placement="top"
          delay={{ show: 250, hide: 600 }}
          overlay={renderTooltip8}
          trigger="hover"
        >
          <div>
            <p className="lead fw-semibold fs-6 globe-grid-text ">
              <Link to="/countries" className="text-decoration-none text-muted">
                {" "}
                {t("Clients/Partners")}
                <img src={Upicon} alt="" className="arrow" />
              </Link>
            </p>
            <span className="fw-bold fs-2 display-5 mb-5">
              <>
                <CountUp end={5} redraw={true}>
                  {({ countUpRef, start }) => (
                    <VisibilitySensor onChange={start} delayedCall>
                      <span ref={countUpRef} />
                    </VisibilitySensor>
                  )}
                </CountUp>
              </>
            </span>
          </div>
        </OverlayTrigger>
      </Col>

      <Col xs={4} lg={12} className="text-center text-lg-start">
        <p className="lead fw-semibold fs-6 globe-grid-text">
          {t("Currencies")} <img src={Upicon} alt="" className="arrow" />
        </p>{" "}
        <span className="fw-bold fs-2 display-5 mb-5">
          <>
            <CountUp end={20} redraw={true}>
              {({ countUpRef, start }) => (
                <VisibilitySensor onChange={start} delayedCall>
                  <span ref={countUpRef} />
                </VisibilitySensor>
              )}
            </CountUp>
            +
          </>
        </span>
      </Col>
    </Row>
  );

  return (
    <>
      <Navbars />

      <div>
        <div>
          {showPromo ? (
            <div className="position-relative banner-for-promo  ">
              <div className="text-center py-2 promo-text text-white d-flex align-items-center justify-content-between fs-6">
                <div style={{ opacity: "0" }}>.</div>
                <div className="">
                  {t("promo1")}
                  <button className="bg-transparent border-0 ">
                    <Link to="/betting-biz-learn-more" className="text-white">
                      {t("promo2")}
                    </Link>
                  </button>
                </div>
                <div className="text-end collapse-promo">
                  <Button
                    variant="transparent"
                    className=" collapse-promo-btn text-end rounded-circle"
                    onClick={handleHidePromoBanner}
                  >
                    <IoCloseCircle className="close-promo text-white fs-5" />
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}

          <section className=" d-flex align-items-center justify-content-center hero-header-section">
            <Container fluid className="container-overlay ">
              <Row className="view-text align-items-center justify-content-center text-center">
                <Col className="header-margin" md={{ span: 12 }}>
                  <h1 className="display-2 fw-bold headerFont d-none d-lg-block home-header">
                    {t("Your_payment")} {t("processor")} {t("across")}{" "}
                    <span className="growth">{t("Africa")}</span>
                  </h1>

                  <h1 className="display-2 fw-bold headerFont d-block d-lg-none home-header">
                    <div>{t("Your_payment")} </div>
                    <div>{t("processor")} </div>
                    <div>{t("across")}</div>
                    <span className="growth">{t("Africa")}</span>
                  </h1>

                  <p className="text-muted hero-sub-heading mt-4 px-2 px-md-5">
                    <span className="d-md-block d-lg-block d-xl-block ">
                      {" "}
                      {t("Hero_content")}
                    </span>
                    <span className="d-none "> {t("Hero_content1")} </span>
                    <br />
                    <span className="d-none " style={{ marginTop: "-3%" }}>
                      {" "}
                      {t("Hero_content2")}
                    </span>
                  </p>
                  <a
                    href="mailto:sales@nsano.com"
                    className="btn btn-primary btn-heroCS mt-4 fs-6 px-5"
                  >
                    <span>{t("Work_with_Us")}</span>
                  </a>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="globe-grid">
            <Container>
              <Row className="mb-2 text-center  text-xl-center justify-content-center">
                <Col md={9} lg={6}>
                  <div className="lc-block pb-5">
                    <div className="rich-text">
                      <h2 className="fw-bold mt-4">
                        {t(
                          "Expanding_businesses_into multiple_African_Markets!"
                        )}
                        {t("African_Markets!")}
                      </h2>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="align-items-center gx-5 ">
                <Col md={12} lg={8} className="">
                  {/* <GlobePage/> */}
                  <D3Globe />
                </Col>
                <Col md={12} lg={4}>
                  {reading}
                </Col>
              </Row>
            </Container>
          </section>

          <div>
            {/* partners */}
            <Container
              fluid
              className="py-4 partner-container postion-absolute animate__animated animate__fadeInLeft "
            >
              <div className="text-center text-xl-center align-items-center g-4 g-md-0">
                <Brand />
              </div>
            </Container>

            {/* features */}
            <div className=" animate__animated animate__fadeInLeft">
              <section className="service-section p-t-100 p-b-100 features-container">
                <Container>
                  <Row className="align-items-center justify-content-center">
                    <Col lg={5} md={8}>
                      <div className="service-img text-center text-lg-center">
                        <img
                          src={require(`../../core/resources/assets/images/${t(
                            "AdvImg"
                          )}`)}
                          alt=""
                          className="advimg"
                          width="300px"
                        />
                      </div>
                    </Col>

                    <Col xl={7} lg={7}>
                      <Row className=" no-gutters iconic-boxes-v1 icon-boxes-square-bordered mt-3">
                        <div className="common-heading title-line-bottom text-left m-b-80">
                          <h1 className="title fw-semibold text-start fs-2 features-head mt-3 ">
                            {t("Nsano_Advantage")}
                          </h1>
                        </div>
                        <Row className="align-items-center text-center gy-3 text-md-start ">
                          <Col md={6}>
                            <div className="lc-block">
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip}
                              >
                                <div className="d-flex align-items-center">
                                  <img className="bullet" src={Bullet} alt="" />
                                  <p className="lead features-heading">
                                    {t("Presence_in_multiple")}
                                  </p>
                                </div>
                              </OverlayTrigger>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="lc-block">
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip2}
                              >
                                <div className="d-flex align-items-center">
                                  <img className="bullet" src={Bullet} alt="" />
                                  <p className="lead features-heading">
                                    {t("Over_a_decade's_experience")}
                                  </p>
                                </div>
                              </OverlayTrigger>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="lc-block">
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip3}
                              >
                                <div className="d-flex align-items-center">
                                  <img className="bullet" src={Bullet} alt="" />
                                  <p className="lead features-heading">
                                    {t("Interoperability")}
                                  </p>
                                </div>
                              </OverlayTrigger>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="lc-block">
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip4}
                              >
                                <div className="d-flex align-items-center">
                                  <img className="bullet" src={Bullet} alt="" />
                                  <p className="lead features-heading">
                                    {t("Highest_Security_Standards")}
                                  </p>
                                </div>
                              </OverlayTrigger>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="lc-block">
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip5}
                              >
                                <div className="d-flex align-items-center">
                                  <img className="bullet" src={Bullet} alt="" />
                                  <p className="lead features-heading">
                                    {t("24/7_Support")}
                                  </p>
                                </div>
                              </OverlayTrigger>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="lc-block">
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip6}
                              >
                                <div className="d-flex align-items-center">
                                  <img className="bullet" src={Bullet} alt="" />
                                  <p className="lead features-heading">
                                    {t("Robust_Reconciliation_&_Settlement")}
                                  </p>
                                </div>
                              </OverlayTrigger>
                            </div>
                          </Col>
                        </Row>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </section>
              <Row className="mb-5 text-center text-xl-center justify-content-center  animate__animated animate__fadeInLeft">
                <Col md={9} lg={6}>
                  <div className="lc-block">
                    <div className="rich-text">
                      <h2 className="fw-bold mt-4">
                        {t("Why_we_do_what_we_do")}
                      </h2>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            {/* <Home_Carousel /> */}

            {/* testimonial video section */}
            <section>
              <ReactPlayer
                controls={true}
                url="https://www.youtube.com/watch?v=M9v9Fj0ZrUI"
                width="100%"
                height="100vh"
              />
            </section>

            <Footer>
              <div className="">
                <div className=" btn-footer-regulators ">
                  <div className="d-flex items-align-center">
                    <div className="">
                      <Image
                        src={Logo1}
                        className=""
                        style={{
                          height: "30px",
                          width: "30px",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                    <div>
                      <Image
                        src={Logo2}
                        className=""
                        style={{
                          height: "30px",
                          width: "50px",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                    <div className="">
                      <Image
                        src={Logo3}
                        className=""
                        style={{
                          height: "30px",
                          width: "40px",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                    <div className="">
                      <Image
                        src={Logo4}
                        className=""
                        style={{
                          height: "30px",
                          width: "50px",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <CountriesForFooter />
            </Footer>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
