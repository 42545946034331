import { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../styles/blog.css";
import axios from "axios";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Pagination,Navigation,Mousewheel } from "swiper";
import './swiper_pagination_style_article.css';
import '../../styles/blog.css';
import 'swiper/swiper-bundle.css';
import ContentShimmer from "react-content-shimmer";




interface dataType {
  id: number;
  title: title;
  excerpt: excerpt;
  acf: acf;
  better_featured_image: better_featured_image;
}
interface title {
  rendered: string;
}
interface excerpt {
  rendered: string;
}
interface acf {
  author: string;
}

interface better_featured_image {
  media_details: media_details;
}
interface media_details {
  sizes: sizes;
}
interface sizes {
  thumbnail: thumbnail;
}

interface thumbnail {
  source_url: any;
}

function Article_small_screen() {
    const [data, setData] = useState<dataType[]>([]);
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);

    const pagination = {
      clickable: true,
      dynamicBullets: true,
    }; 


const fetch = async () => {
  try {
    const data = await axios
      .get(`${process.env.REACT_APP_BASE_URL}/${t("articles_from_staffs")}`, {
        headers: {
          // "ngrok-skip-browser-warning": "69420",
          //         Authorization: `Bearer ${process.env.AUTH_TOKEN}`,
        },
      })
      .then((res) => {
        setData(res.data);
      });
    setLoading(true);
  } catch (e) {
    console.log(e);
  }
};

useEffect(() => {
  fetch();
}, []);

  const colors = [ "#FF4967", "#0072CA"];


  return (
    <>
    {loading ? (<div>
      <Container>
    <div className="fs-1 my-4 px-4 fw-semibold content">{t("article")}</div>
   

  
  <Swiper
    slidesPerView={1}
    spaceBetween={5}
    // centeredSlides={true}
    pagination={pagination}
    modules={[Pagination,Navigation,Mousewheel]}
    direction={"horizontal"}
        // navigation={true}
        mousewheel={{  
          forceToAxis: true,
         }}
    className="mySwiper"
    id="swiperPageSmallScreen"
    style={{paddingBottom :"10%" }}
  >
     {data.map((item, index) => (
      <SwiperSlide  key={item.id}>

    <div className="row row-cols-1  g-0 overflow-hidden d-flex bg-white testcard" style={{borderRadius:"20px"}}>
                <Row style={{}}>
                <Col md={4} xs={4}>
                <img className="rounded-3 m-3"
                        src={
                          item.better_featured_image.media_details.sizes
                            .thumbnail.source_url
                        }
                        alt=""
                        style={{objectFit:"cover",width:"100%", height:"110px"}}
                      />
                </Col>
                <Col>
                <div className="mt-4">
                <Link className="text-decoration-none text-black " to={`/article/${item.title.rendered.replace(/\s/g, "_")}/${item.id}`}>
                <h6 className="px-2 fw-semibold text-uppercase" style={{fontSize:"13px",color: colors[index % colors.length] }}>{item.title.rendered.substring(0, 30)+'...'}</h6>
                </Link>
                <Link className="text-decoration-none text-black " to={`/article/${item.title.rendered.replace(/\s/g, "_")}/${item.id}`}>
                      <p className="px-2 my-0 text-black" style={{ fontSize: "0.8rem" }} dangerouslySetInnerHTML={{__html:item.excerpt.rendered.substring(0, 100)+'...' + `read more`}}
                      />
                      </Link>
           
                </div>
                </Col>
              </Row>
               
              </div>
              </SwiperSlide >
            
            ))}
  </Swiper>
  </Container>
    </div>) : (<div> <Container>
            <div className="d-flex">
              
              <div>
                <div className="d-flex">
                  <div className="p-2 d-flex align-items-center">
                    <div
                      className="bg-light"
                      style={{
                        width: "500px",
                        borderRadius: "20px",
                        height: "150px",
                      }}
                    >
                      <div className="p-2 mt-4 d-flex align-items-center ">
                        <div className="px-4">
                          <ContentShimmer
                            size={{ height: 100, width: 150 }}
                            rounded="5%"
                          />
                        </div>
                        <div className="px-3">
                          <ContentShimmer
                            style={{ marginBottom: "1rem" }}
                            size={{ height: 15, width: 150 }}
                          />

                          <div className="mt-2">
                            <ContentShimmer
                              style={{
                                marginTop: "1rem",
                                width: "w-100",
                                height: "10px",
                              }}
                              rounded={"10px"}

                              // size={{ height: 15, width: 300 }}
                            />
                          </div>
                          {/* <ContentShimmer size={{ height: 15, width: 100 }} /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </Container></div>)}
 
</>

  
  );
}
export default Article_small_screen;
