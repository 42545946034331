import React, { ChangeEvent } from "react";
import "./i18n";
import { useTranslation } from "react-i18next";
import { availableLanguages } from "./i18n";

import { Form } from "react-bootstrap";

function Langswitcher(props) {
  const { t, i18n } = useTranslation();

  const onLanguageChange = (e) => {
    i18n.changeLanguage(e.target.value);
    if (typeof props.onLanguageChange === 'function') {
      props.onLanguageChange(e.target.value);
    }
  }
  
 
  return (
    <div>
      <Form.Select
       
        defaultValue={i18n.language}
        onChange={onLanguageChange}
        className="langBtnFooter"
      >
        {availableLanguages.map((language) => (
          <option key={language} value={language}>{language.toLocaleUpperCase()}</option>
        ))}
      </Form.Select>
    </div>
  );
}

export default Langswitcher;
