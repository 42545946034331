import Growth from "../../core/resources/assets/videos/growth.mp4";
import "../styles/whynsano.css";
import { Container, Row, Col , Image} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Navbars from "../components/navbar/Navbars";
import { createMedia } from "@artsy/fresnel";
import Navbar_mobile_view from "../components/navbar/navbar_mobile";
import Footer from "../components/Footer";
import CircleGhFlag from '../../core/resources/assets/images/circlegh.png'
import CircleZmFlag from '../../core/resources/assets/images/circlezambia.png'
import CircleCtFlag from '../../core/resources/assets/images/circlecote.png'
import CircleSlFlag from '../../core/resources/assets/images/circlesierre.png'
import CircleUgFlag from '../../core/resources/assets/images/circleuganda.png'
import CircleRwFlag from '../../core/resources/assets/images/circlerwanda.png'


import Logo1 from "../../core/resources/assets/logos/msecb.png";
import Logo2 from "../../core/resources/assets/logos/bkgh.png";
import Logo3 from "../../core/resources/assets/logos/bkzm.png";
import Logo4 from "../../core/resources/assets/logos/PCIDSS.png";
import { CountriesForFooter } from "../components/countries_for_footer";


function WhyNSANO() {
  const { t } = useTranslation();

  const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
      // xs: 0,
      sm: 0,
      md: 1200,
    },
  });
  return (
    <MediaContextProvider>
      <Media at="md">
        <div className="position-relative overflow-hidden animate__animated animate__fadeInRight">
          <div className=" min-vh-100" lc-helper="video-bg">
            <video
              src={Growth}
              autoPlay
              loop
              controls
              style={{
                objectFit: "cover",
                objectPosition: "50% 50%",
                width: "100%",
              }}
              className="position-absolute min-vh-100"
            />
            <Navbars />
          </div>
        </div>

        <Container>
          <Row className=" accelerating-growth-row justify-content-center animate__animated animate__fadeInRight">
            <Col
              md={12}
              className="gy-2 accelerating-growth-title-col align-items-center  mb-5 text-center "
              style={{ marginTop: "10%" }}
            >
              <div className="lc-block">
                <h2 className="display mb-0">
                  <b>{t("why_nsano_title")}</b>
                </h2>
              </div>
            </Col>
            <Col className="accelerating-growth-content-col">
              <div className="padding-space ">
                <p className="px-4 py-3  text-center">
                  {" "}
                  {t("why_nsano_content")}
                </p>
              </div>
            </Col>
          </Row>
          <Row className=" justify-content-center mt-3  mt-md-5 align-items-stretch">
            <Col
              md={12}
              lg={6}
              xl={6}
              className="mission-card  mb-4 mb-xl-0"
              style={{ height: "auto" }}
            >
              <div
                className="lc-block p-4  rounded-5 bg-card text-dark"
                style={{ height: "100%" }}
              >
                <div className="lc-block mb-4 px-4">
                  <div>
                    <h5 className="fw-bold mt-3 title" style={{ color: "#4d4d4d" }}>
                      {t("Mission")}
                    </h5>
                  </div>
                </div>
                <div className="lc-block mb-lg-5 px-3 mb-2">
                  <div>
                    <p
                      className="fs-1 about-content fw-normal text-muted"
                      style={{ fontFamily:"Onest" }}
                    >
                      {t("Mission_content")}
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              md={12}
              lg={6}
              xl={6}
              className="  vision-card mb-4 mb-xl-0"
              style={{ height: "auto" }}
            >
              <div
                className="lc-block p-4 rounded-5 bg-card text-dark"
                style={{ height: "100%" }}
              >
                <div className="lc-block mb-4 px-4">
                  <div>
                    <h5 className="fw-bold mt-3 title" style={{ color: "#4d4d4d" }}>
                      {t("Vision")}
                    </h5>
                  </div>
                </div>
                <div className="lc-block mb-lg-5 mb-2 px-3">
                  <div>
                    <p
                      className="fs-1 about-content fw-normal text-muted"
                      style={{  fontFamily:"Onest" ,}}
                    >
                      {t("Vision_content")}
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Media>

      <Media at="sm">
        <div className="position-relative overflow-hidden">
          <Navbar_mobile_view />
          <div lc-helper="video-bg">
            <video
              src={Growth}
              autoPlay
              loop
              controls
              style={{
                objectFit: "contain",
                objectPosition: "50% 50%",
                width: "100%",
              }}
              //  className=" min-vh-100"
            />
          </div>
        </div>

        <Container>
          <Row className=" accelerating-growth-row justify-content-center">
            <Col
              md={12}
              className="gy-2 accelerating-growth-title-col align-items-center  mb-5 text-center "
              style={{ marginTop: "10%" }}
            >
              <div className="lc-block">
                <h2 className="display mb-0">
                  <b>{t("why_nsano_title")}</b>
                </h2>
              </div>
            </Col>
            <Col className="accelerating-growth-content-col">
              <div className="padding-space ">
                <p className="px-4 py-3  text-center">
                  {" "}
                  {t("why_nsano_content")}
                </p>
              </div>
            </Col>
          </Row>
          <Row className=" justify-content-center mt-3 mt-md-5">
            <Col md={12} lg={6} xl={6} className="mission-card mb-4 mb-xl-0">
              <div
                className="lc-block p-4  rounded-5 bg-card text-dark"
                style={{ height: "100%" }}
              >
                <div className="lc-block mb-4 px-4">
                  <div>
                    <h5 className="fw-bold mt-3" style={{ color: "#4d4d4d" }}>
                      {t("Mission")}
                    </h5>
                  </div>
                </div>
                <div className="lc-block mb-lg-5 px-3 mb-2">
                  <div>
                    <p
                      className="about-content"
                      style={{ color: "#4d4d4d" }}
                    >
                      {t("Mission_content")}
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={12} lg={6} xl={6} className=" vision-card  mb-4 mb-xl-0">
              <div
                className="lc-block p-4 rounded-5 bg-card text-dark"
                style={{ height: "100%" }}
              >
                <div className="lc-block mb-4 px-4">
                  <div className="mt-5">
                    <h5 className="fw-bold mt-3" style={{ color: "#4d4d4d" }}>
                      {t("Vision")}
                    </h5>
                  </div>
                </div>
                <div className="lc-block mb-lg-5 mb-2 px-3">
                  <div>
                    <p
                      className="about-content "
                      style={{ color: "#4d4d4d" }}
                    >
                      {t("Vision_content")}
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Media>

      <Footer>
      <div className="">
                    <div className=" btn-footer-regulators ">
                      <div className="d-flex items-align-center">
                      <div className=""><Image src={Logo1} className="" style={{height:"30px",width:"30px",objectFit:"contain"}}/></div> 
                      <div ><Image src={Logo2} className="" style={{height:"30px",width:"50px",objectFit:"contain"}}/></div> 
                      <div className=""><Image src={Logo3} className="" style={{height:"30px",width:"40px",objectFit:"contain"}}/></div> 
                      <div className=""><Image src={Logo4} className="" style={{height:"30px",width:"50px",objectFit:"contain"}}/></div> 
                    </div>
                </div>
              </div>

             <CountriesForFooter/>
      </Footer>
    </MediaContextProvider>
  );
}

export default WhyNSANO;
