import { Card,Col,Container,Row,Image } from "react-bootstrap";
import { CountryData } from "../components/countrylist";
import Footer from "../components/Footer";
import Navbars from "../components/navbar/Navbars";
import Logo1 from '../../core/resources/assets/logos/msecb.png'
import Logo2 from '../../core/resources/assets/logos/bkgh.png'
import Logo3 from '../../core/resources/assets/logos/bkzm.png'
import Logo4 from '../../core/resources/assets/logos/PCIDSS.png'

// eslint-disable-next-line
function CountryInfo() {

  const style={
    "background":"black !important",
    "minHeight":"300px",
    "marginBottom":"5%",
    "borderRadius" : "100px !important"
  }

  return (
  <section>
     <Navbars />
    <Container className="mt-5 country-container">
     <Row lg={3} md={2} sm={1} className="gx-3 gy-3 ">
        {CountryData.map((item, index) => (
          <Col >
         <div className="py-5 px-4 card-height" style={{borderRadius : "50px", background:"#F2F2F2 0% 0%", height:"350px"}}>
          <div className="item-align-center d-flex">
            <div ><img className="rounded" src={item.flag} style={{height:"30px"}}/> </div>
            <div className="mx-3 fs-4 fw-bold">{item.country}</div>
          </div>
          <div className="mt-5">
          {item.list.map((data) => (
            <div key={data.id}>
               <span style={{fontSize:"13px"}}>{data.content}</span>
            </div>
          ))}
          </div>
         </div>
     
          </Col>
        ))}
     </Row>
    </Container>
    <Footer >
    <div className="">
                <div className=" btn-footer-regulators ">
                 <div className="d-flex items-align-center">
                 <div className=""><Image src={Logo1} className="" style={{height:"30px",width:"30px",objectFit:"contain"}}/></div> 
                 <div ><Image src={Logo2} className="" style={{height:"30px",width:"50px",objectFit:"contain"}}/></div> 
                 <div className=""><Image src={Logo3} className="" style={{height:"30px",width:"40px",objectFit:"contain"}}/></div> 
                 <div className=""><Image src={Logo4} className="" style={{height:"30px",width:"50px",objectFit:"contain"}}/></div> 
                 </div>
                </div>
              </div>
    </Footer>
  </section>
  );
}

export default CountryInfo;