import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { Navigation, A11y, Autoplay } from "swiper";
import {
  Xbet,
  MtnMomo,
  Zamtel,
  Mtn,
  Uba,
  Ecobank,
  Access,
  ComBank,
  FbnBank,
  Fidelity,
  Omnibsic,
  Orange,
  Pan,
  Serfin,
  Siclife,
  Terrapay,
} from "../../core/resources/assets/index";
import { Col } from "react-bootstrap";
import "../styles/brand.css";

function Brand() {
  return (
    <Swiper
      modules={[Navigation, A11y, Autoplay]}
      spaceBetween={50}
      slidesPerView={6}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      breakpoints={{
        0: {
          spaceBetween: 30,
          slidesPerView: 2,
        },
        375: {
          spaceBetween: 30,
          slidesPerView: 2,
        },
        575: {
          spaceBetween: 30,
          slidesPerView: 3,
        },
        767: {
          spaceBetween: 50,
          slidesPerView: 4,
        },
        991: {
          spaceBetween: 50,
          slidesPerView: 5,
        },
        1199: {
          spaceBetween: 50,
          slidesPerView: 5,
        },
      }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log("slide change")}
    >
      <SwiperSlide>
        <Col className="py-3">
          <div className="lc-block">
            <img
              className="img-fluid brand"
              src={Uba}
              width="120"
              height="100"
              loading="lazy"
              alt=""
            />
          </div>
        </Col>
      </SwiperSlide>
      <SwiperSlide>
        <Col className="py-3">
          <div className="lc-block">
            <img
              className="img-fluid brand"
              src={Xbet}
              width="120"
              height="100"
              loading="lazy"
              alt=""
            />
          </div>
        </Col>
      </SwiperSlide>
      <SwiperSlide>
        <Col className="py-3">
          <div className="lc-block">
            <img
              className="img-fluid brand"
              src={MtnMomo}
              width="100"
              height="200"
              loading="lazy"
              alt=""
            />
          </div>
        </Col>
      </SwiperSlide>
      <SwiperSlide>
        <Col className="py-3">
          <div className="lc-block">
            <img
              className="img-fluid brand"
              src={Zamtel}
              width="90"
              height="50"
              style={{objectFit:"cover"}}
              loading="lazy"
              alt=""
            />
          </div>
        </Col>
      </SwiperSlide>
      <SwiperSlide>
        <Col className="py-3">
          <div className="lc-block brand">
            <img
              className="img-fluid brand"
              src={Mtn}
              width="150"
              height="150"
              style={{objectFit:"cover"}}
              loading="lazy"
              alt=""
            />
          </div>
        </Col>
      </SwiperSlide>
      <SwiperSlide>
        <Col className="py-3">
          <div className="lc-block">
            <img
              className="img-fluid brand"
              src={Ecobank}
              width="150"
              height="200"
              style={{objectFit:"cover"}}
              loading="lazy"
              alt=""
            />
          </div>
        </Col>
      </SwiperSlide>
      <SwiperSlide>
        <Col className="py-3">
          <div className="lc-block">
            <img
              className="img-fluid brand"
              src={Access}
              width="250"
              height="250"
              style={{objectFit:"cover"}}
              loading="lazy"
              alt=""
            />{" "}
          </div>
        </Col>
      </SwiperSlide>
      <SwiperSlide>
        <Col className="py-3">
          <div className="lc-block">
            <img
              className="img-fluid brand"
              src={ComBank}
              width="130"
              height="50"
              style={{objectFit:"cover"}}
              loading="lazy"
              alt=""
            />{" "}
          </div>
        </Col>
      </SwiperSlide>
      <SwiperSlide>
        <Col className="py-3">
          <div className="lc-block">
            <img
              className="img-fluid brand"
              src={FbnBank}
              width="200"
              height="200"
              style={{objectFit:"cover"}}
              loading="lazy"
              alt=""
            />{" "}
          </div>
        </Col>
      </SwiperSlide>
      <SwiperSlide>
        <Col className="py-3">
          <div className="lc-block">
            <img
              className="img-fluid brand"
              src={Fidelity}
              width="250"
              height="250"
              style={{objectFit:"cover"}}
              loading="lazy"
              alt=""
            />
          </div>
        </Col>
      </SwiperSlide>
      <SwiperSlide>
        <img
          className="img-fluid brand"
          src={Omnibsic}
          width="200"
              height="200"
              style={{objectFit:"cover"}}
          loading="lazy"
          alt=""
        />
      </SwiperSlide>
      <SwiperSlide>
        <Col className="py-3">
          <div className="lc-block">
            <img
              className="img-fluid brand"
              src={Orange}
              width="80"
              height="100"
              style={{objectFit:"cover"}}
              loading="lazy"
              alt=""
            />{" "}
          </div>
        </Col>
      </SwiperSlide>
      <SwiperSlide>
        <Col className="py-3">
          <div className="lc-block">
            <img
              className="img-fluid brand"
              src={Pan}
              width="170"
              height="200"
              style={{objectFit:"cover"}}
              loading="lazy"
              alt=""
            />
          </div>
        </Col>
      </SwiperSlide>
      <SwiperSlide>
        <Col className="py-3">
          <div className="lc-block">
            <img
              className="img-fluid brand"
              src={Serfin}
              width="200"
              height="200"
              style={{objectFit:"cover"}}
              loading="lazy"
              alt=""
            />
          </div>
        </Col>
      </SwiperSlide>
      <SwiperSlide>
        <Col className="py-3">
          <div className="lc-block">
            <img
              className="img-fluid brand"
              src={Siclife}
              width="200"
              height="200"
              style={{objectFit:"cover"}}
              loading="lazy"
              alt=""
            />
          </div>
        </Col>
      </SwiperSlide>
      <SwiperSlide>
        <Col className="py-3">
          <div className="lc-block">
            <img
              className="img-fluid brand"
              src={Terrapay}
              width="200"
              height="200"
              style={{objectFit:"cover"}}
              loading="lazy"
              alt=""
            />{" "}
          </div>
        </Col>
      </SwiperSlide>
    </Swiper>
  );
}

export default Brand;
