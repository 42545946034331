// import styles from "./PostDetailsWithSidebar.module.scss";
import { Link } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
import '../../styles/blog.css'

import { createMedia } from "@artsy/fresnel";
import Slider from "react-slick";
import ContentShimmer from "react-content-shimmer";


interface dataType {
  id: id;
  title: title;
  excerpt: excerpt;
  acf: acf;
}
interface id {
  id: number;
}

interface title {
  rendered: string;
}

interface excerpt {
  rendered: string;
  protected: boolean;
}

interface acf {
  images: images;
}

interface images {
  first: any;
  second: number;
  third: number;
  fourth: number;
}

function Tablet_experience_card() {
  var settings = {
    dots: true,
    infinite: true,
    arrows: false,
    className: 'notes-slider',
    autoplay: true,
    autoplaySpeed: 7000,
    speed: 500,
    cssEase: 'linear',
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const [data, setData] = useState<dataType[]>([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [content, setContent] = useState<dataType>();

  const fetch = async () => {
    try {
      const data = await axios
        .get(`${process.env.REACT_APP_BASE_URL}/${t("experience")}`, {
          headers: {
            // "ngrok-skip-browser-warning": "69420",
            //         Authorization: `Bearer ${process.env.AUTH_TOKEN}`,
          },
        })
        .then((res) => {
          setData(res.data);
          if (res.data[0] != undefined)
            setContent(res.data[0])
        });
      setLoading(true);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetch();
  }, []);



  return (

    <>
      {loading ? (<div>
        <div className=" bg-white " style={{ borderRadius: "20px" }}>
          {/* <Slider {...settings} className=" p-3" >
      {data.map((item, key) => (
        <div key={key}>
          <Row style={{ height: "auto",  }}>
            <Col md={5}>
            <img
                      src={item.acf.images.first}
                      // width="100%"
                      // height="80px"
                      style={{
                        objectFit: "cover",
                        width: "100%",
                      height: "300px",
                      }}
                      className=" img rounded"
                    />
            </Col>
            <Col className="mt-4">
              <div className="m-0 p-0 ">
                <h2>
                  <Link
                    to={`/experience/${item.title.rendered.replace(/\s/g, "_")}/${item.id}`}
                    className="mb-4 fs-3 d-flex content  text-black justify-content-center"
                    style={{ textDecoration: "none", marginBottom: "30px" }}
                  >
                    {item.title.rendered}
                  </Link>
                </h2>
                <p style={{fontSize:"14px"}} className="text-center"
                  dangerouslySetInnerHTML={{ __html: item.excerpt.rendered.substring(0,500) + ''}}
                />
              </div>
            </Col>
          </Row>
        </div>
      ))}
    </Slider> */}
          {content ? (
            <div >
              <Row style={{ height: "auto", }}>
                <Col md={5}>
                  <img
                    src={content.acf.images.first}
                    // width="100%"
                    // height="80px"
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "300px",
                    }}
                    className=" img rounded"
                  />
                </Col>
                <Col className="mt-4">
                  <div className="m-0 p-0 ">
                    <h2>
                      <Link
                        to={`/experience/${content.title.rendered.replace(/\s/g, "_")}/${content.id}`}
                        className="mb-4 fs-3 d-flex content  text-black justify-content-center"
                        style={{ textDecoration: "none", marginBottom: "30px" }}
                      >
                        {content.title.rendered}
                      </Link>
                    </h2>
                    <p style={{ fontSize: "14px" }} className="text-center"
                      dangerouslySetInnerHTML={{ __html: content.excerpt.rendered.substring(0, 500) + '' }}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          ) : null}

        </div>

      </div>) : (
        <div className="mt-4 " style={{ marginTop: "10%", width: "100%" }}> <div >
          <div style={{ width: "100%" }}>
            {/* <div className="p-2 lign-items-center "> */}
            <div
              className="bg-light mt-5"
              style={{
                width: "100%",
                borderRadius: "20px",
                height: "250px",
              }}
            >
              <Row >
                <Col md={6} className="bg-light">
                  <div className="p-4">
                    <ContentShimmer
                      size={{ height: 200, width: 300, }}
                      rounded="5%"
                    />
                  </div>

                </Col>
                <Col>
                  <div className="mt-5 px-4 d-flex justify-content-center">
                    <ContentShimmer
                      style={{
                        marginTop: "1rem", width: "200px", height: "10px",
                      }}
                      rounded={"10px"} />
                  </div>
                  <div className="mt-4 px-4">
                    <ContentShimmer
                      style={{
                        marginTop: "1rem", width: "w-100", height: "10px",
                      }}
                      rounded={"10px"} />
                    <ContentShimmer
                      style={{
                        marginTop: "1rem", width: "w-100", height: "10px",
                      }}
                      rounded={"10px"} />
                    <ContentShimmer
                      style={{
                        marginTop: "1rem", width: "w-100", height: "10px",
                      }}
                      rounded={"10px"} />
                    <ContentShimmer
                      style={{
                        marginTop: "1rem", width: "w-100", height: "10px",
                      }}
                      rounded={"10px"} />
                  </div>

                </Col>
              </Row>

            </div>
            {/* </div> */}
          </div>
        </div>

        </div>
      )}

    </>

  );
}

export default Tablet_experience_card;
