import { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../styles/blog.css";
import axios from "axios";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Button, Card, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./swiper_pagination_style_sif.css";
import { Pagination, Navigation, Mousewheel } from "swiper";
import Modal from "react-bootstrap/Modal";
import ReactPlayer from "react-player/youtube";
import "../../styles/blog.css";
import 'swiper/swiper-bundle.css';
import ContentShimmer from "react-content-shimmer";


interface dataType {
  id: id;
  title: title;
  content: content;
  acf: acf;
  better_featured_image: better_featured_image;
}
interface title {
  rendered: string;
}
interface id {
  id: number;
}
interface content {
  rendered: string;
}
interface acf {
  staff: string;
  video: string;
  staff_content: any;
}
interface better_featured_image {
  media_details: media_details;
}
interface media_details {
  sizes: sizes;
}
interface sizes {
  medium: medium;
}

interface medium {
  source_url: any;
}

function Staff_infocus_tablet_view() {
  const pagination = {
    clickable: true,
    dynamicBullets: true,
  };

  const [data, setData] = useState<dataType[]>([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);


  const fetch = async () => {
    try{
      const data = await axios 
      .get(`${process.env.REACT_APP_BASE_URL}/${t("staffs_in_focus")}`,
      {
        headers: {
          // "ngrok-skip-browser-warning": "69420",
 //         Authorization: `Bearer ${process.env.AUTH_TOKEN}`,
        },
      })
      .then((res) => {
        setData(res.data);
        
      });
      setLoading(true);
    }
    catch(e){
      console.log(e);
    }
  }

  useEffect(() => {
    fetch();
   }, []);

  const [vid, setVid] = useState("");
  const [show, setShow] = useState(false);
  // const [hide,setHide] = useState(false)

  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    console.log("clicked");
    var source = e.target.id;
    setVid(source);
    setShow(true);
  };

  const post = `<a href="#" className="bg-black text-decoration-none font-weight-bold">  read more</a>`;

  return (
  
    <>
    {loading ? (<div>
      <Container>
        <div className="fs-1 my-4 fw-semibold content">{t("focus")}</div>

        <Swiper
          slidesPerView={5}
          spaceBetween={5}
          centeredSlides={true}
          pagination={pagination}
          modules={[Pagination, Mousewheel]}
          direction={"horizontal"}
        // navigation={true}
        mousewheel={{  
          forceToAxis: true,
         }}
          className="mySwiper"
          style={{ marginLeft: "-600px", height: "auto", paddingBottom: "5%" }}
        >
          {data.map((item, key) => (
            <SwiperSlide key={key}>
              <Card
                className={
                  ((key + 1) % 2 == 0 ? `bg-image` : `bg-white`) +
                  " p-3 border-0"
                }
                style={{
                  width: "90%",
                  borderRadius: "20px ",
                  minHeight: "400px",
                }}
              >
                <Card.Header className="bg-transparent border-0">
                  <Card.Title className="d-flex  bg-transpparent" style={{height:"60px"}}>
                    <div className=" m-0 p-0" style={{height:"100%",width:"60px"}}>
                      <img
                        src={
                          item.better_featured_image.media_details.sizes.medium
                            .source_url
                        }
                        alt=""
                        // width="50rem"
                        // height="50rem"
                        style={{ objectFit: "cover", maxWidth: "60px", height:"100%" }}
                        className="img-thumbnail rounded-circle"
                      />
                    </div>
                    <div
                      className="px-2 mt-3 text-uppercase fw-semibold"
                      style={{ fontSize: "10px" }}
                    >
                      {item.acf.staff}
                    </div>
                  </Card.Title>
                </Card.Header>
                <Card.Body className="mb-2" style={{ height: "auto" }}>
                  {item?.acf?.staff_content?.length < 250 ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item?.acf?.staff_content,
                      }}
                      style={{ fontSize: "12px" }}
                    ></div>
                  ) : (
                    <div
                      style={{ fontSize: "12px" }}
                      dangerouslySetInnerHTML={{
                        __html:
                          item?.acf?.staff_content?.substring(0, 250) +
                          `<span class="collapse" id="viewdetails${item.id}">
                  ${item?.acf?.staff_content?.substring(250)}
                </span>
                <button style="font-size:12px" 
                class="btn p-0 m-0 btn-sm btn-link text-decoration-none text-muted" 
                   data-bs-toggle="collapse" 
                  data-bs-target="#viewdetails${item.id}">...read more  &raquo;
                </button>`,
                      }}
                    ></div>
                  )}

                  <div className="lc-block d-flex list-unstyled mt-auto ms-auto"></div>
                </Card.Body>
                <Card.Footer className="border-0 bg-transparent">
                  <div className="btn btn-link btn-sm " role="button">
                    <Button
                      className="btn-new text-muted"
                      variant="primary"
                      style={{
                        border: "2px solid silver",
                        borderRadius: "30px",
                        backgroundColor: "transparent",
                        color: "#358ED7",
                        fontWeight: "500",
                        fontSize: "12px",
                      }}
                      onClick={handleShow}
                      id={item.acf.video}
                    >
                      Watch Video
                      {/* </a> */}
                    </Button>
                  </div>
                </Card.Footer>
              </Card>
            </SwiperSlide>
          ))}
        </Swiper>
        <Modal
          show={show}
          onHide={() => setShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          style={{ backdropFilter: "blur(8px)" }}
        >
          <Modal.Header closeButton className="border-0 "></Modal.Header>
          <Modal.Body className="mx-auto mb-5">
            <ReactPlayer url={vid}  controls={true} />
          </Modal.Body>
        </Modal>
      </Container>
    </div>) : (<div>
      <Container>
        <div className="d-flex">
          <div>
                  <div className="d-flex">
                <div className="p-2 d-flex align-items-center">
                  <div className="bg-light" style={{ width: "300px", borderRadius: "20px", height: "400px" }}
                  >
                    <div className="p-2 mt-4 d-flex align-items-center ">
                      <ContentShimmer size={{ height: 60, width: 60 }} rounded="50%" />
                      <div className="px-3">
                        <ContentShimmer
                          style={{ marginBottom: "1rem" }}
                          size={{ height: 15, width: 150 }}
                        />
                        {/* <ContentShimmer size={{ height: 15, width: 100 }} /> */}
                      </div>
                    </div>
                    <div className="px-4">
                      <ContentShimmer
                        style={{ marginTop: "1rem" }}
                        rounded={"10px"}
                        size={{ height: 15, width: 250 }}
                      />
                      <ContentShimmer
                        style={{ marginTop: "1rem" }}
                        rounded={"10px"}
                        size={{ height: 15, width: 250 }}
                      />
                      <ContentShimmer
                        style={{ marginTop: "1rem" }}
                        rounded={"10px"}
                        size={{ height: 15, width: 250 }}
                      />
                      <ContentShimmer
                        style={{ marginTop: "1rem" }}
                        rounded={"10px"}
                        size={{ height: 15, width: 250 }}
                      />
                      <ContentShimmer
                        style={{ marginTop: "1rem" }}
                        rounded={"10px"}
                        size={{ height: 15, width: 250 }}
                      />
                    </div>
                    <div className="px-4 mt-5">
                      <ContentShimmer
                        style={{ marginTop: "1rem" }}
                        rounded={"50px"}
                        size={{ height: 45, width: 100 }}
                      />
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div>
                  <div className="d-flex">
                <div className="p-2 d-flex align-items-center">
                  <div className="bg-light" style={{ width: "300px", borderRadius: "20px", height: "400px" }}
                  >
                    <div className="p-2 mt-4 d-flex align-items-center ">
                      <ContentShimmer size={{ height: 60, width: 60 }} rounded="50%" />
                      <div className="px-3">
                        <ContentShimmer
                          style={{ marginBottom: "1rem" }}
                          size={{ height: 15, width: 150 }}
                        />
                        {/* <ContentShimmer size={{ height: 15, width: 100 }} /> */}
                      </div>
                    </div>
                    <div className="px-4">
                      <ContentShimmer
                        style={{ marginTop: "1rem" }}
                        rounded={"10px"}
                        size={{ height: 15, width: 250 }}
                      />
                      <ContentShimmer
                        style={{ marginTop: "1rem" }}
                        rounded={"10px"}
                        size={{ height: 15, width: 250 }}
                      />
                      <ContentShimmer
                        style={{ marginTop: "1rem" }}
                        rounded={"10px"}
                        size={{ height: 15, width: 250 }}
                      />
                      <ContentShimmer
                        style={{ marginTop: "1rem" }}
                        rounded={"10px"}
                        size={{ height: 15, width: 250 }}
                      />
                      <ContentShimmer
                        style={{ marginTop: "1rem" }}
                        rounded={"10px"}
                        size={{ height: 15, width: 250 }}
                      />
                    </div>
                    <div className="px-4 mt-5">
                      <ContentShimmer
                        style={{ marginTop: "1rem" }}
                        rounded={"50px"}
                        size={{ height: 35, width: 100 }}
                      />
                    </div>
                  </div>
                </div>
              </div>
          </div>

          <div>
                  <div className="d-flex">
                <div className="p-2 d-flex align-items-center">
                  <div className="bg-light" style={{ width: "300px", borderRadius: "20px", height: "400px" }}
                  >
                    <div className="p-2 mt-4 d-flex align-items-center ">
                      <ContentShimmer size={{ height: 60, width: 60 }} rounded="50%" />
                      <div className="px-3">
                        <ContentShimmer
                          style={{ marginBottom: "1rem" }}
                          size={{ height: 15, width: 150 }}
                        />
                        {/* <ContentShimmer size={{ height: 15, width: 100 }} /> */}
                      </div>
                    </div>
                    <div className="px-4">
                      <ContentShimmer
                        style={{ marginTop: "1rem" }}
                        rounded={"10px"}
                        size={{ height: 15, width: 250 }}
                      />
                      <ContentShimmer
                        style={{ marginTop: "1rem" }}
                        rounded={"10px"}
                        size={{ height: 15, width: 250 }}
                      />
                      <ContentShimmer
                        style={{ marginTop: "1rem" }}
                        rounded={"10px"}
                        size={{ height: 15, width: 250 }}
                      />
                      <ContentShimmer
                        style={{ marginTop: "1rem" }}
                        rounded={"10px"}
                        size={{ height: 15, width: 250 }}
                      />
                      <ContentShimmer
                        style={{ marginTop: "1rem" }}
                        rounded={"10px"}
                        size={{ height: 15, width: 250 }}
                      />
                    </div>
                    <div className="px-4 mt-5">
                      <ContentShimmer
                        style={{ marginTop: "1rem" }}
                        rounded={"50px"}
                        size={{ height: 35, width: 100 }}
                      />
                    </div>
                  </div>
                </div>
              </div>
          </div>

          
        </div>
      
      </Container>
    </div>)}
     
    </>
  );
}
export default Staff_infocus_tablet_view;
