

import React, { useState } from "react";
import { Form, Button, Modal,Spinner } from "react-bootstrap";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";

  const CareerModal = (props) => {
    const { t } = useTranslation();
  return (
    <div>
      <div className="position-absolute top-50 left-50 translate-end">
      <Toaster />
      </div>
      
      <>
        <Button className="btn-apply" variant="primary" >
          <a href="https://forms.office.com/r/9X3d5a4hxC" className="text-decoration-none text-white">{t("apply")}</a> 
        </Button>

      
      </>
    </div>
  );
}

export default CareerModal;