// import "./virtualcard.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { createMedia } from "@artsy/fresnel";
import LargeScreenLatestNews from "./large_screen_latest_news";
import DesktopLatestNews from "./desktop_view_latest_news";
import TabletLatestNews from "./tablet_view_latest_news";
import MobileLatestNews from "./mobile_view_latest_news";


function LatestNewsIndex() {
  const { MediaContextProvider, Media } = createMedia({
    breakpoints: {

      xs: 0,
      sm: 800,
      md: 1200,
      lg: 1400,
      xl: 1700,
    },
  });

  return (
    <div className="mt-3 ">
      <MediaContextProvider>
        <Media at="xs">
          <MobileLatestNews/>
        </Media>
        <Media at="sm">
          <TabletLatestNews/>
        </Media>
        <Media at="md">
          <DesktopLatestNews/>
        </Media>
        <Media greaterThanOrEqual="lg">
          <LargeScreenLatestNews />
        </Media>
      </MediaContextProvider>
    </div>
  );
}

export default LatestNewsIndex;
