import React, { useState } from "react";
import { Form, Button, Modal, Spinner } from "react-bootstrap";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";

const UploadCV = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="position-absolute top-50 left-50 translate-end">
        <Toaster />
      </div>

      <>
        <Button
          className="px-4 py-3 border-0 fw-semibold"
          style={{
            borderRadius: "30px",
            fontSize: "15px",
            background: "#F09020",
          }}
          variant="primary"
          // onClick={handleShow}
        >
          <a href="https://forms.office.com/r/9X3d5a4hxC" className="text-decoration-none text-white"> {t("uploadCV")}</a>
        </Button>

        
      </>
    </div>
  );
};

export default UploadCV;
