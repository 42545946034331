import React, { useEffect, useState } from "react";
import Logo from "../../../core/resources/assets/logos/logo.png";
import "../../styles/Navbars.css";
import { NavLink } from "react-router-dom";
import { Container, Navbar, Col, Row, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import NavbarOffCanvas from "./navbarOffCanvas";
import { useLocation } from "react-router-dom";
import { Logo1 , Logo2} from "../../../core/resources/assets";

const options = [

  {
    name: 'Disable backdrop',
    scroll: false,
    backdrop: false,
    placement: 'end'
  },
];

function Navbar_mobile_view() {
  const { t } = useTranslation();

  const [isShown, setIsShown] = useState(false);
  const path = useLocation()
  const [activeLogo,setActiveLogo] = useState(Logo2)
  useEffect(()=>{
    if(path.pathname === "/why-nsano"){
      setActiveLogo(Logo1)
    }
  },[])


  return (
    <Container fluid className="px-4 mb-3">

      <div className="d-flex justify-content-between">
        <div>
          <NavLink to="/" className="nav-link mt-4 ">
            <img
              src={activeLogo}
              style={{height:"30px", width:"90px"}}
              className="d-inline-block align-top"
              alt=""
            />
          </NavLink>
        </div>

        <div>
          {options.map((props, idx) => (
            <NavbarOffCanvas key={idx} {...props} />
          ))}
        </div>
      </div>

    </Container>
  );
}

export default Navbar_mobile_view;
