import React from "react";
import { Card, Carousel, CarouselItem, Col, Row } from "react-bootstrap";
import cardImg1 from "../../../core/resources/assets/images/payment.png";
import cardImg2 from "../../../core/resources/assets/images/Parcels_Icon.png";
import cardImg3 from "../../../core/resources/assets/images/Quality_Icon.png";
import cardImg4 from '../../../core/resources/assets/images/customer.png';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CiMoneyCheck1 } from "react-icons/ci";

function Tablet_virtualcard_view() {
  const { t } = useTranslation();

  return (
    <div>
      <Carousel fade touch controls={false} className="" style={{height:"360px",marginTop:"-18px"}}>
        <CarouselItem >
          <Row className="mb-3 px-2 gy-4">
            <Col md={12} >
            <Link
            to="/ecommerce" className="text-decoration-none">
              <Card
                className="border border-0"
                style={{
                  height: "auto",
                  background: "#005aee",
                  borderRadius: "20px",
                }}
              >
                <Row style={{height:"150px"}}>
                  <Col md={4} className="mt-5">
                  <CiMoneyCheck1 className="text-white mx-5" style={{ fontSize:"45px",fontWeight:"lighter"}}/>
                  </Col>
                  <Col className="mt-4">
                    <Card.Body
                      className="text-white mt-3 fw-semibold "
                      style={{ fontSize: "18px" }}
                    >
                       {t("e-commerce_card_title")}
                      <p className="mb-4" style={{ fontSize: "11px" }}>
                      {/* {t("e-commerce_card_body")} */}
                      </p>
                    </Card.Body>
                  </Col>
                </Row>
              </Card>
              </Link>
            </Col>
            <Col md={12} >
            <Link
            to="/toolkit" className="text-decoration-none">
              <Card
                className="border border-0"
                style={{
                  height: "auto",
                  background: "#ffa767",
                  borderRadius: "20px",
                }}
              >
                <Row style={{height:"150px"}}>
                  <Col className="mt-5" >
                    <Card.Body
                      className="text-white mx-3 fw-semibold"
                      style={{ fontSize: "18px" }}
                    >
                      {t("toolkit_card_title")}
                      <p style={{ fontSize: "11px" }}>
                      {/* {t("toolkit_card_body")}  */}
                      </p>
                    </Card.Body>
                  </Col>

                  <Col md={4} className=" mt-5">
                    <Card.Img
                      variant="right"
                      src={cardImg2}
                      className="mx-3  "
                      style={{
                        objectFit: "cover",
                        width: "50%",
                        height: "auto",
                      }}
                    />
                  </Col>
                </Row>
              </Card>
              </Link>
            </Col>
          </Row>
        </CarouselItem>
        <CarouselItem>
          <Row className="px-2 gy-4">
            
            <Col md={12}>
            <Link
            to="/quality_check" className="text-decoration-none">
              <Card
                className="border border-0"
                style={{
                  height: "auto",
                  background: "#0aacdc",
                  borderRadius: "20px",
                }}
              >
                <Row style={{height:"150px"}}>
                  <Col md={4}  className="mt-4">
                    <Card.Img
                      variant="left"
                      src={cardImg3}
                      className="mx-5 mt-3 "
                      style={{
                        objectFit: "cover",
                        width: "50%",
                        height: "auto",
                      }}
                    />
                  </Col>
                  <Col className="mt-4">
                    <Card.Body
                      className="text-white mt-4 fw-semibold"
                      style={{ fontSize: "18px" }}
                    >
                      {t("quality_card_title")}
                      <p style={{ fontSize: "11px" }}>
                      {/* {t("quality_card_body")} */}
                      </p>
                    </Card.Body>
                  </Col>
                </Row>
              </Card>
              </Link>
            </Col>
            <Col md={12}>
            <Link
            to="/customer_service" className="text-decoration-none">
              <Card
                className="border border-0"
                style={{
                  height: "auto",
                  background: "#597aef",
                  borderRadius: "30px",
                }}
              >
                <Row style={{height:"150px"}}>
                  <Col className="mt-4">
                    <Card.Body
                      className="text-white mx-2  fw-semibold"
                      style={{ fontSize: "18px" }}
                    >
                      {t("customer_card_title")}
                      <p style={{ fontSize: "11px" }}>
                      {/* {t("customer_card_body")}  */}
                      </p>
                    </Card.Body>
                  </Col>
                  <Col md={4} className="mt-4">
                    <Card.Img
                      variant="right"
                      src={cardImg4}
                      className="mr-3 mt-3 "
                      style={{
                        objectFit: "cover",
                        width: "50%",
                        height: "auto",
                      }}
                    />
                  </Col>
                </Row>
              </Card>
              </Link>
            </Col>
          </Row>
        </CarouselItem>
      </Carousel>
    </div>
  );
}

export default Tablet_virtualcard_view;
