import React, { useEffect, useState } from "react";
import Logo from "../../../core/resources/assets/logos/logo.png";
import "../../styles/Navbars.css";
import { NavLink } from "react-router-dom";
import { Container, Navbar, Col, Row, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Logo1 , Logo2} from "../../../core/resources/assets";

function Navbar_desktop_view() {
  const { t } = useTranslation();
  const path = useLocation()
  const [activeLogo, setActiveLogo] = useState(Logo2)
  console.log("path-name", path.pathname)
  // const [ourProductPage, setOurProductPage] = useState("our-products");
const ourProductPage = "/our-products"
  useEffect(() => {
    if (path.pathname === "/why-nsano") {
      setActiveLogo(Logo1)
    }
    if (path.pathname == "our-products") {
      // setOurProductPage(true)
    }
  }, [ourProductPage])
  return (
    <div>
      <Navbar bg="transparent" expand="lg">
        <Container className="mt-4">
          <Navbar.Brand href="/" className="">
            <img
              src={activeLogo}
              style={{height:"30px", width:"90px"}}
              className="d-inline-block align-top"
              alt=""
            />
          </Navbar.Brand>
          <button
            className="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#myNavbar1"
            aria-controls="myNavbar1"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <Navbar.Collapse className="collapse navfont" id="myNavbar1">
            <div
              lc-helper="shortcode"
              className="live-shortcode me-auto ms-auto"
            >
              {" "}
              <ul
                id="menu-menu-1"
                className="navbar-nav me-auto mb-2 mb-md-0 navbar-nav "
              >
                <li className="menu-item menu-item-type-custom menu-item-object-custom nav-item nav-item-32739 px-4">
                  <NavLink to="/why-nsano" className="nav-link">
                    {t("Why_Nsano")}
                  </NavLink>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-home nav-item nav-item-32738 px-4 dropdown position-static">
                  <NavLink
                    className="nav-link dropdown-toggle"
                    to="/our-products"
                    id="navbarDropdown"
                    role="button"
                    data-mdb-toggle="dropdown"
                    aria-expanded="false"
                    style={{ width: "auto" }}
                  >
                    {t("Our_Products")}
                  </NavLink>

                  {path.pathname !== ourProductPage && (
                    <div
                      className="dropdown-menu w-20  mt-2"
                      aria-labelledby="navbarDropdown"
                      style={{
                        borderRadius: "20px",
                        border: "none",
                        //   width:"100px"
                        marginLeft: "-1%"
                      }}
                    >
                      <Card
                        style={{
                          background: "#f2f2f2",
                          width: "450px",
                          border: "none",
                          borderRadius: "20px",
                          // marginLeft:"-5% !important",
                        }}
                      >
                        <Card.Body>
                          <Row className="mb-4">
                            <Col className="bg-transparent" md={6} style={{ fontSize: "12px" }}>
                              <NavLink
                                to="/our-products"
                                className="list-group-item bg-transparent  list-group-item-action border-0"
                              >
                                <div className="fw-bold ">{t("Third_Party_Payment_Processing")}</div>

                                <span style={{ fontSize: "0.7rem" }} className=" text-muted">
                                  {t("Third_Party_Payment_Processing_content")}
                                </span>
                              </NavLink>
                            </Col>
                            <Col className="bg-transparent" md={6} style={{ fontSize: "12px" }}>
                              <NavLink
                                to="/our-products"
                                className="list-group-item bg-transparent list-group-item-action border-0"
                              >
                                <div className="fw-bold ">{t("Mobile_Money_Aggregation")}</div>

                                <span style={{ fontSize: "0.7rem" }} className=" text-muted">
                                  {t("Mobile_Money_Aggregation_content")}
                                </span>
                              </NavLink>
                            </Col>
                          </Row>
                          <Row>
                            <Col className="bg-transparent" md={6} style={{ fontSize: "12px" }}>
                              <NavLink
                                to="/our-products"
                                className="list-group-item bg-transparent list-group-item-action border-0"
                              >
                                <div className="fw-bold ">{t("Bulk_Payment_Solutions")}</div>

                                <span style={{ fontSize: "0.7rem" }} className=" text-muted">
                                  {t("Bulk_Payment_Solutions_content")}
                                </span>
                              </NavLink>
                            </Col>
                            <Col className="bg-transparent" md={6} style={{ fontSize: "12px" }}>
                              <NavLink
                                to="/our-products"
                                className="list-group-item bg-transparent list-group-item-action border-0"
                              >
                                <div className="fw-bold ">{t("Remittance")}</div>

                                <span style={{ fontSize: "0.7rem" }} className=" text-muted">
                                  {t("Remittance_content")}
                                </span>
                              </NavLink>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>

                    </div>
                  )}


                  {/* <div
                    className="dropdown-menu w-20  mt-2"
                    aria-labelledby="navbarDropdown"
                    style={{
                      borderRadius: "20px",
                      border: "none",
                      //   width:"100px"
                      marginLeft:"-1%"
                    }}
                  >
                    <Card
                      style={{
                        background: "#f2f2f2",
                        width: "600px",
                        border: "none",
                        borderRadius: "20px",
                        marginTop: "-2%",
                        marginLeft:"-25% !important",
                      }}
                    >
                      <Card.Body className="px-2">
                        <Row className="mb-4 px-3">
                          <Col className="bg-transparent" md={6} style={{ fontSize: "12px" }}>
                            <NavLink
                              to="/our-products"
                              className="list-group-item bg-transparent list-group-item-action border-0"
                            >
                              <div className="fw-semibold mb-2">
                                {" "}
                                {t("Third_Party_Payment_Processing")}
                              </div>

                              <div style={{ fontSize: "0.7rem" }}  className=" text-muted">
                                {t("Third_Party_Payment_Processing_content")}
                              </div>
                            </NavLink>
                          </Col>
                          <Col className="bg-transparent" md={6} style={{ fontSize: "12px" }}>
                            <NavLink
                              to="/our-products"
                              className="list-group-item bg-transparent list-group-item-action border-0"
                            >
                              <div className="fw-semibold mb-2">
                                {t("Mobile_Money_Aggregation")}
                              </div>

                              <div style={{ fontSize: "0.7rem" }}  className=" text-muted">
                                {t("Mobile_Money_Aggregation_content")}
                              </div>
                            </NavLink>
                          </Col>
                        </Row>
                        <Row className="px-3">
                          <Col className="bg-transparent" md={6} style={{ fontSize: "12px" }}>
                            <NavLink
                              to="/our-products"
                              className="list-group-item bg-transparent list-group-item-action border-0"
                            >
                              <div className="fw-semibold mb-2" >
                                {t("Bulk Payment Solutions")}
                              </div>

                              <div style={{ fontSize: "0.7rem" }} className=" text-muted">
                                {t("Bulk_Payment_Solutions_content")}
                              </div>
                            </NavLink>
                          </Col>
                          <Col className="bg-transparent" md={6} style={{ fontSize: "12px" }}>
                            <NavLink
                              to="/our-products"
                              className="list-group-item bg-transparent list-group-item-action border-0"
                            >
                              <div className="fw-semibold mb-2">
                                {t("Remittance")}
                              </div>

                              <div style={{ fontSize: "0.7rem" }}  className=" text-muted">
                                {t("Remittance_content")}
                              </div>
                            </NavLink>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </div> */}
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-home nav-item nav-item-32738 px-4">
                  <NavLink to="/our-people" className="nav-link ">
                    {t("Our_People")}
                  </NavLink>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-home nav-item nav-item-32738 px-4">
                  <NavLink to="/blog" className="nav-link ">
                    {t("Blog")}
                  </NavLink>
                </li>
                <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-home nav-item nav-item-32738 px-4">
                  <NavLink to="/careers" className="nav-link">
                    {t("Careers")}
                  </NavLink>
                </li>
                {/* <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-home nav-item nav-item-32738 px-2">
                  <Link to="/developers/sign-up" className="nav-link ">
                    Developers
                  </Link>
                </li> */}
              </ul>{" "}
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Navbar_desktop_view;
