import { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../styles/blog.css";
import axios from "axios";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Pagination, Navigation, Mousewheel } from "swiper";
import "./swiper_pagination_style_article.css";
import '../../styles/blog.css';
import 'swiper/swiper-bundle.css';
import ContentShimmer from "react-content-shimmer";


interface dataType {
  id: number;
  title: title;
  excerpt: excerpt;
  acf: acf;
  better_featured_image: better_featured_image;
}
interface title {
  rendered: string;
}
interface excerpt {
  rendered: string;
}
interface acf {
  author: string;
}

interface better_featured_image {
  media_details: media_details;
}
interface media_details {
  sizes: sizes;
}
interface sizes {
  thumbnail: thumbnail;
}

interface thumbnail {
  source_url: any;
}

function Article_Xtrasmall_screen() {
  const [data, setData] = useState<dataType[]>([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const pagination = {
    clickable: true,
    dynamicBullets: true,
  };


  const fetch = async () => {
    try {
      const data = await axios
        .get(`${process.env.REACT_APP_BASE_URL}/${t("articles_from_staffs")}`, {
          headers: {
            // "ngrok-skip-browser-warning": "69420",
            //         Authorization: `Bearer ${process.env.AUTH_TOKEN}`,
          },
        })
        .then((res) => {
          setData(res.data);
        });
      setLoading(true);
    } catch (e) {
      console.log(e);
    }
  };
  
  useEffect(() => {
    fetch();
  }, []);

  const colors = [ "#FF4967", "#0072CA"];


  return (
    <>
      {loading ? (<div><Container>
        <div className="fs-1 my-4 text-center fw-semibold content">{t("article")}</div>

        <Swiper
          slidesPerView={1}
          spaceBetween={5}
          // centeredSlides={true}
          pagination={pagination}
          modules={[Pagination, Navigation, Mousewheel]}
          direction={"horizontal"}
          // navigation={true}
          mousewheel={{  
            forceToAxis: true,
           }}
          className="mySwiper"
          id="swiperPageSmallScreen"
          style={{ paddingBottom: "10%" }}
        >
          {data.map((item, index) => (
            <SwiperSlide key={item.id}>
              <Card className="border-0 mt-5" style={{borderRadius:"20px"}}>
                <img
                  src={
                    item.better_featured_image.media_details.sizes.thumbnail
                      .source_url
                  }
                  alt=""
                  className="d-flex justify-content-center postion-absolute mb-4"
                  style={{ objectFit: "cover", width: "30%", marginLeft: "35%" , marginRight: "25%" , marginTop: "-10%" ,border:"7px solid #f5f5f5", borderRadius:"20px"}}
                />
                <Card.Body>
                <Link
                        className="text-decoration-none text-black text-center"
                        to={`/article/${item.title.rendered.replace(/\s/g, "_")}/${item.id}`}
                      >
                        <h6 className="px-2 fw-semibold text-uppercase" style={{ color: colors[index % colors.length] , fontSize:"10px",letterSpacing:"1px"}}>{item.title.rendered}</h6>
                      </Link>
                      <Link
                        className="text-decoration-none text-muted text-center"
                        to={`/article/${item.title.rendered.replace(/\s/g, "_")}/${item.id}`}
                      >
                      <p
                        className="px-2 my-0 text-mutedtext-center"
                        style={{ fontSize: "0.8rem" }}
                        dangerouslySetInnerHTML={{
                          __html: item.excerpt.rendered,
                        }}
                      />
                      </Link>
                </Card.Body>
              </Card>
            
            </SwiperSlide>
          ))}
        </Swiper>
      </Container></div>) : (
        <div className="mt-4 " style={{marginTop:"10%",width:"100%"}}> <div >
                <div  style={{  width: "100%"}}>
                  <div className="p-2 lign-items-center">
                    <div
                      className="bg-light mt-5"
                      style={{
                        width: "100%",
                        borderRadius: "20px",
                        height: "250px",
                      }}
                    >
                      <div className="p-2 mt-4 ">
                        <div className="px-4 d-flex justify-content-center" style={{marginTop: "-17%", }}>
                          <ContentShimmer
                            size={{ height: 80, width: 80, }}
                            rounded="5%"
                          />
                        </div>
                        <div className="px-3 mt-3">
                        <div className="mt-4">
                            <ContentShimmer
                              style={{
                                marginTop: "1rem",
                                width: "w-100",
                                height: "10px",
                              }}
                              rounded={"10px"}

                              // size={{ height: 15, width: 300 }}
                            />
                          </div>
                          <div className="mt-2">
                            <ContentShimmer
                              style={{
                                marginTop: "1rem",
                                width: "w-100",
                                height: "10px",
                              }}
                              rounded={"10px"}

                              // size={{ height: 15, width: 300 }}
                            />
                          </div>
                          <div className="mt-2">
                            <ContentShimmer
                              style={{
                                marginTop: "1rem",
                                width: "w-100",
                                height: "10px",
                              }}
                              rounded={"10px"}

                              // size={{ height: 15, width: 300 }}
                            />
                          </div>
                          {/* <ContentShimmer size={{ height: 15, width: 100 }} /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
        
      )}
      

     
          
    </>
  );
}
export default Article_Xtrasmall_screen;
