import { useState, useEffect } from "react";
import Navbars from "../navbar/Navbars";
import axios from "axios";
import { useParams } from "react-router-dom";
import * as ReactBootstrap from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import ContentShimmer from "react-content-shimmer";
import { Container } from "react-bootstrap";



interface dataType {
  id?: number;
  title?: title;
  content?: content;
  acf?: acf;
  better_featured_image?: better_featured_image;
  
}
interface title {
  rendered: string;
}


interface content {
  rendered: string;
}

interface acf {
  date: any;
  article_content: string;
  author:string;
}

interface better_featured_image {
  source_url: any;
}


function SingleArticle() {

 
  const { t } = useTranslation();
  const [articleData, setArticleData] = useState<dataType>();
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = useParams();

  const fetchArticleData = async () => {
    try{
      const { data } = await axios.get(`${process.env.REACT_APP_BASE_URL}/${t("articles_from_staffs")}/${id}`, {
        headers: {
          // "ngrok-skip-browser-warning": "69420",
          // Authorization: `Bearer ${process.env.AUTH_TOKEN}`,
        },
      });
      setArticleData(data);
      setLoading(true);
    }
    catch(e){
      console.log(e);
    }
  }
  
  useEffect(() => {
    fetchArticleData();
  });

  return (
    <div>
      <Navbars />
      {loading ? (
        <>
        {" "}
        
        <div className="container py-5 px-5">
          <div className="row mb-3">
            <div className="col-lg-8 mx-auto">
              <div className="lc-block mb-4">
                <h3 className="fw-semibold display-2 animate__animated animate__fadeInRight animate__delay-1s">
                  {articleData && articleData.title?.rendered}
                  {/* {JSON.stringify(newsData!.title!.rendered)} */}
                </h3>
              </div>
              <img
                src={
                  articleData && articleData.better_featured_image?.source_url
                }
                className="d-block w-100 rounded-4 animate__animated animate__fadeInRight animate__delay-2s"
                alt="..."
               style={{width:"100%", objectFit:"cover" , height:"600px"}}
              />
              <div>
              <div className="my-3 text-end fw-semibold text-muted animate__animated animate__fadeIn animate__delay-4s" dangerouslySetInnerHTML={{
                        __html:`${articleData && articleData.acf?.author}`
                      }}/>
              </div>
             
              <div className="lc-block ">
                <p className="lead mt-3 fs-6 justify fw-normal animate__animated animate__fadeInRight animate__delay-3s" dangerouslySetInnerHTML={{
                        __html:`${articleData && articleData.content?.rendered}`
                      }}
                      style={{fontFamily:"Times New Roman"}}/>
                  {" "}
                  {/* {articleData && articleData.acf?.article_content} */}
                {/* </p> */}
                <span className="fs-6 text-muted">
                  {" "}
                  {articleData && articleData.acf?.date}
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer onLanguageChange={(v) => fetch()}/> */}
      </>
      ) : (
        <>
       <div
              style={{ width: "100%", height: "100%", borderRadius: "20px" }}
            >
              {" "}
              <div>
                <div style={{ width: "100%" }}>
                  {/* <div className="p-2 lign-items-center "> */}
                  <div
                    style={{
                      width: "100%",
                      borderRadius: "",
                      height: "100vh",
                    }}
                  >
                    <Container>
                      <div className="px-5 py-5">
                        <div className="row mb-3">
                          <div className="col-lg-8 mx-auto">
                            <div className="d-inline">
                              <div className="px-2 mt-3">
                                <ContentShimmer
                                  style={{
                                    marginTop: "1rem",
                                    width: "40%",
                                    height: "25px",
                                  }}
                                  rounded={"10px"}
                                />
                                <ContentShimmer
                                  style={{
                                    marginTop: "1rem",
                                    width: "60%",
                                    height: "25px",
                                  }}
                                  rounded={"10px"}
                                />
                                <ContentShimmer
                                  style={{
                                    marginTop: "1rem",
                                    width: "w-100",
                                    height: "25px",
                                  }}
                                  rounded={"10px"}
                                />
                              </div>
                              <div className="px-2 mt-3">
                                <div>
                                  <ContentShimmer
                                    style={{ width: "100%", height: "400px" }}
                                    rounded="5%"
                                  />
                                </div>
                              </div>
                              <div className="px-2 mt-3">
                                <ContentShimmer
                                  style={{
                                    marginTop: "1rem",
                                    width: "w-100",
                                    height: "10px",
                                  }}
                                  rounded={"10px"}
                                />
                                <ContentShimmer
                                  style={{
                                    marginTop: "1rem",
                                    width: "w-100",
                                    height: "10px",
                                  }}
                                  rounded={"10px"}
                                />
                                <ContentShimmer
                                  style={{
                                    marginTop: "1rem",
                                    width: "w-100",
                                    height: "10px",
                                  }}
                                  rounded={"10px"}
                                />
                                <ContentShimmer
                                  style={{
                                    marginTop: "1rem",
                                    width: "w-100",
                                    height: "10px",
                                  }}
                                  rounded={"10px"}
                                />
                                <ContentShimmer
                                  style={{
                                    marginTop: "1rem",
                                    width: "w-100",
                                    height: "10px",
                                  }}
                                  rounded={"10px"}
                                />
                                <ContentShimmer
                                  style={{
                                    marginTop: "1rem",
                                    width: "w-100",
                                    height: "10px",
                                  }}
                                  rounded={"10px"}
                                />
                                <ContentShimmer
                                  style={{
                                    marginTop: "1rem",
                                    width: "w-100",
                                    height: "10px",
                                  }}
                                  rounded={"10px"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Container>
                  </div>

                  <div className=" h-100">
                    <div className="position-absolute top-50 start-50 translate-middle">
                      <div className="">
                        <ReactBootstrap.Spinner animation="border" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </>
      )}
    </div>
  );
}

export default SingleArticle;
