// import "./virtualcard.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { createMedia } from "@artsy/fresnel";
import Article_desktop_view from "./article_desktop_view";
import Article_mobile_view from "./article_mobile_view";
import Article_tablet_view from "./article_tablet_view";
import Article_small_screen from "./aricle_small_screen";
import Article_large_screen_view from "./article_large_screen";
import Article_Xtrasmall_screen from "./article_extra_small_view";

function StaffInFocus() {
  const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
      xxs:0,
      xs: 450,
      sm: 600,
      md: 798,
      lg: 1403,
      xl: 1765,
    },
  });

  return (
    <div className="mt-3 ">
      <MediaContextProvider>
      <Media at="xxs">
          <Article_Xtrasmall_screen />
        </Media>
        <Media at="xs">
          <Article_small_screen />
        </Media>
        <Media at="sm">
          <Article_mobile_view />
        </Media>
        <Media at="md">
          <Article_tablet_view />
        </Media>
        <Media at="lg">
          <Article_desktop_view />
        </Media>
        <Media greaterThanOrEqual="xl">
          <Article_large_screen_view />
        </Media>
      </MediaContextProvider>
    </div>
  );
}

export default StaffInFocus;
