import Navbars from "../components/navbar/Navbars";
import "../styles/ourpeople.css";
import {
  Kofi,
  Christian,
  Amey,
  Linda,
  Dorothy,
  Priscilla,
  Solace,
  Akosua,
} from "../../core/resources/assets";
import { BsLinkedin } from "react-icons/bs";
import { Container, Row, Col, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";

import CircleGhFlag from "../../core/resources/assets/images/circlegh.png";
import CircleZmFlag from "../../core/resources/assets/images/circlezambia.png";
import CircleCtFlag from "../../core/resources/assets/images/circlecote.png";
import CircleSlFlag from "../../core/resources/assets/images/circlesierre.png";
import CircleUgFlag from "../../core/resources/assets/images/circleuganda.png";
import CircleRwFlag from "../../core/resources/assets/images/circlerwanda.png";

import Logo1 from "../../core/resources/assets/logos/msecb.png";
import Logo2 from "../../core/resources/assets/logos/bkgh.png";
import Logo3 from "../../core/resources/assets/logos/bkzm.png";
import Logo4 from "../../core/resources/assets/logos/PCIDSS.png";
import { RiKeynoteFill } from "react-icons/ri";
import { CountriesForFooter } from "../components/countries_for_footer";

function OurPEOPLE() {
  const { t } = useTranslation();

  const Execs = [
    { 
     name: "Kofi Owusu-Nhyira",
     position: `${t("Director")}`, 
     linkedin: "https://www.linkedin.com/in/kofiowusu-nhyira/",
    image:Kofi },
    {
      name:"Solace Kidisil",
      position:`${t("Group_Chief_of_Operations")}`,
      linkedin:"https://www.linkedin.com/in/solace-kidisil-1a539338",
      image:Solace
    },
    {
      name:"Christian Glouin",
      position:`${t("Chief_Technology_Officer")}`,
      linkedin:"https://www.linkedin.com/in/christian-glouin",
      image:Christian
    },
    {
      name:"Priscilla Hazel",
      position:`${t("Group_Head,_Commercial")}`,
      linkedin:"https://www.linkedin.com/in/priscahazel",
      image:Priscilla
    },
    {
      name:"Frederick Kofi Amey",
      position:`${t("Group_Head,_Finance")}`,
      linkedin:"https://www.linkedin.com/in/frederick-kofi-amey-9457499a",
      image:Amey
    },
    {
      name:"Linda Otoo",
      position:`${t("Group_Head,_Risk_&_Compliance")}`,
      linkedin:"https://www.linkedin.com/in/linda-otoo-5a912157",
      image:Linda
    },
    {
      name:"Dorothy Asante",
      position:`${t("Group_Head,_Human_Capital_&_Administration")}`,
      linkedin:"https://www.linkedin.com/in/dorothy-asante-shrm-scp-24017b52",
      image:Dorothy
    },
    {
      name:"Akosua Yeboah",
      position:`${t("Group_Head_Product_Development_Projects")}`,
      linkedin:"https://www.linkedin.com/in/akosua-yeboah-652b99275/",
      image:Akosua
    },
    ];
  return (
    <div>
      <Navbars />
      <div className="py-5 team3 bg-white animate__animated animate__fadeInRight">
        <Container>
          <Row className="justify-content-center mb-4">
            <Col md={7} className="text-center">
              <h3 className="mb-3 fw-bold"> {t("Our_Leadership_Team")}</h3>
              <h6 className="subtitle font-weight-normal">
                {t("Our_Leadership_Team_content")}
              </h6>
            </Col>
          </Row>
          <Row>
            {Execs.map((data,key)=>(
              <Col lg={4} className="mb-4">
              <Row>
                <Col md={12} className="d-flex justify-content-center">
                  <img
                    src={data.image}
                    alt="wrapkit"
                    style={{
                      borderRadius: "60px",
                      height: "510px",
                      width: "100%",
                      objectFit: "cover",
                      objectPosition: "top",
                    }}
                    className="img-fluid opacity-100 img-action"
                  />
                </Col>
                <Col md={12} className="px-5">
                  <div className="d-flex align-items-center justify-content-between justify-content-md-around justify-content-lg-between justify-content-md-around justify-content-lg-between">
                    <div>
                      <h6
                        className="mt-4 fw-light"
                        style={{ fontSize: "15px" }}
                      >
                        {" "}
                        {data.position}
                      </h6>
                      <h5 className="fw-semibold">{data.name}</h5>
                    </div>
                    <a href={data.linkedin}>
                      <BsLinkedin className="linkedin" size={30} />
                    </a>
                  </div>
                </Col>
              </Row>
            </Col>
                
            ))}
          </Row>
        </Container>
      </div>
      <Footer>
        <div className="">
          <div className=" btn-footer-regulators ">
            <div className="d-flex items-align-center">
              <div className="">
                <Image
                  src={Logo1}
                  className=""
                  style={{
                    height: "30px",
                    width: "30px",
                    objectFit: "contain",
                  }}
                />
              </div>
              <div>
                <Image
                  src={Logo2}
                  className=""
                  style={{
                    height: "30px",
                    width: "50px",
                    objectFit: "contain",
                  }}
                />
              </div>
              <div className="">
                <Image
                  src={Logo3}
                  className=""
                  style={{
                    height: "30px",
                    width: "40px",
                    objectFit: "contain",
                  }}
                />
              </div>
              <div className="">
                <Image
                  src={Logo4}
                  className=""
                  style={{
                    height: "30px",
                    width: "50px",
                    objectFit: "contain",
                  }}
                />
              </div>
            </div>
          </div>
        </div>

       <CountriesForFooter/>
      </Footer>
    </div>
  );
}

export default OurPEOPLE;
