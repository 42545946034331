import React from "react";
import Navbars from "../components/navbar/Navbars";
import { Image } from "react-bootstrap";
import { Container } from "react-bootstrap";
import CircleGhFlag from "../../core/resources/assets/images/circlegh.png";
import CircleZmFlag from "../../core/resources/assets/images/circlezambia.png";
import CircleCtFlag from "../../core/resources/assets/images/circlecote.png";
import CircleSlFlag from "../../core/resources/assets/images/circlesierre.png";
import CircleUgFlag from "../../core/resources/assets/images/circleuganda.png";
import CircleRwFlag from "../../core/resources/assets/images/circlerwanda.png";

import Logo1 from "../../core/resources/assets/logos/msecb.png";
import Logo2 from "../../core/resources/assets/logos/bkgh.png";
import Logo3 from "../../core/resources/assets/logos/bkzm.png";
import Logo4 from "../../core/resources/assets/logos/PCIDSS.png";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";
import { CountriesForFooter } from "../components/countries_for_footer";

export const Privacy = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Navbars />
      <Container>
        <div className="text-center mt-5 fs-1 fw-bold">{t("privacy")}</div>
        <div className="mt-5">
          <p className="fs-5 fw-normal">
          {t("privacy-sub-header-1")}
          </p>
          <p className="fs-5 mt-5">
          {t("privacy-sub-header1-description")}
          </p>
          <p className="fs-5 mt-5 fw-bold">
          {t("privacy-sub-header1-list-point-header")}
          </p>
          <p className="fs-5 mt-2 ">
          {t("privacy-sub-header1-list-point-sub")}
          </p>
          <ul className="list-border-left">
            <li className="my-2 mx-5 fs-6">
              {" "}
              {t("privacy-sub-header1-list-point1")}
            </li>
            <li className="my-2 mx-5 fs-6"> {t("privacy-sub-header1-list-point2")} </li>
            <li className="my-2 mx-5 fs-6"> {t("privacy-sub-header1-list-point3")}</li>
            <li className="my-2 mx-5 fs-6">
            {t("privacy-sub-header1-list-point4")}
            </li>
            <li className="my-2 mx-5 fs-6">{t("privacy-sub-header1-list-point5")}</li>
            <li className="my-2 mx-5 fs-6">{t("privacy-sub-header1-list-point6")}</li>
            <li className="my-2 mx-5 fs-6">{t("privacy-sub-header1-list-point7")}</li>
          </ul>
        </div>

        <div className="fs-5 my-5">
          <p>
          {t("privacy-sub-header1-body")}
           <p className="mt-3">{t("privacy-sub-header1-body2")}</p>
          </p>
          <p className="my-5 fw-bold">{t("privacy-sub-header2")}</p>
          <p>
             {t("privacy-sub-header2-description")}
             <p className="mt-3"> {t("privacy-sub-header2-description2")}</p>
          </p>
          <p className="my-5 fw-bold fw">{t("privacy-sub-header3")}</p>
          <p>
            <ul className="list-border-left">
              <li className="my-3 mx-5 fs-6">
              {t("privacy-sub-header3-list1")}
              </li>
              <li className="my-3 mx-5 fs-6">
              {t("privacy-sub-header3-list2")}
              </li>
              <li className="my-3 mx-5 fs-6">
              {t("privacy-sub-header3-list3")}
              </li>
            
            </ul>
          </p>
        </div>

        <div className="fs-5">
          <p className="my-5 fw-bold">{t("privacy-sub-header4")}</p>
          <p>
          {t("privacy-sub-header4-description1")}
          </p>
          <p>
          {t("privacy-sub-header4-description2")}
          </p>
          <p>
          {t("privacy-sub-header4-description3")}
          </p>
          <p>
          {t("privacy-sub-header4-description4")}
          </p>
          <p className="my-5 fw-bold">{t("privacy-sub-header5")}</p>
          <p>
          {t("privacy-sub-header5-description")}
          </p>
          <p className="my-5 fw-bold">{t("privacy-sub-header6")}</p>
          <p>
          {t("privacy-sub-header6-description")}
          </p>
          <p className="fw-bold">
            {" "}
            {t("privacy-sub-header6-info1")} 
            {/* <strong className="emphasis"> {t("linda-otoo")}</strong>{" "} */}
          </p>
          <p>
            {" "}
            {t("privacy-sub-header6-info2")}
           <a href="mailto:risk@nsano.com" className="text-decoration-none">
           <strong className="emphasis"> {t("risk-email")}</strong>
           </a>
          </p>
        </div>
      </Container>
      <Footer >
        <div className="">
          <div className=" btn-footer-regulators ">
            <div className="d-flex items-align-center">
              <div className="">
                <Image
                  src={Logo1}
                  className=""
                  style={{
                    height: "30px",
                    width: "30px",
                    objectFit: "contain",
                  }}
                />
              </div>
              <div>
                <Image
                  src={Logo2}
                  className=""
                  style={{
                    height: "30px",
                    width: "50px",
                    objectFit: "contain",
                  }}
                />
              </div>
              <div className="">
                <Image
                  src={Logo3}
                  className=""
                  style={{
                    height: "30px",
                    width: "40px",
                    objectFit: "contain",
                  }}
                />
              </div>
              <div className="">
                <Image
                  src={Logo4}
                  className=""
                  style={{
                    height: "30px",
                    width: "50px",
                    objectFit: "contain",
                  }}
                />
              </div>
            </div>
          </div>
        </div>

       <CountriesForFooter/>
      </Footer>
    </div>
  );
};
