import React, { useState } from "react";
import {
  Ghana,
  Zambia,
  Mali,
  Malawi,
  Cameroon,
  Zimbabwe,
  Gambia,
  Nigeria,
  Togo,
  Tanzania,
  Benin,
  Burkina,
  Sierre,
  Rwanda,
  Senegal,
  Liberia,
  Kenya,
  Cote,
  UK,
  Uganda,
  Gabon,
  Guinea,
  Botswana,
  Chad,
  Burundi,
  DRC,
  RCA,
  SouthSudan,
  SouthAfrica,
  Somalia,
  Morocco,
  Niger,
  EquatorialGuinea,
  Egypt
} from "../../core/resources/assets";

export const CountriesForFooter = () => {
  const [displayCountries, setDisplayCountries] = useState(false)

  const handleDisplay = () => {
    setDisplayCountries(!displayCountries)
  }
  const countryData = [
    // {
    //   flag: Burkina,
    //   name: 'Burkina Faso'
    // },
    // {
    //   flag: Cameroon,
    //   name: 'Cameroon'
    // },
 
    // {
    //   flag: Gabon,
    //   name: 'Gabon'
    // },
    {
      flag: Ghana,
      name: 'Ghana'
    },
    {
      flag: Cote,
      name: `Côte d'Ivoire`
    },
    // {
    //   flag: Mali,
    //   name: 'Mali'
    // },
    // {
    //   flag: Nigeria,
    //   name: 'Nigeria'
    // },
    {
      flag: Rwanda,
      name: 'Rwanda'
    },
    {
      flag: Sierre,
      name: 'Sierre Leone'
    },
    // {
    //   flag: Tanzania,
    //   name: 'Tanzania'
    // },
    // {
    //   flag: Togo,
    //   name: 'Togo'
    // },
    // {

    //   flag: Uganda,
    //   name: 'Uganda'
    // },
     {
      flag: Zambia,
      name: 'Zambia'
    },
    {
      flag: UK,
      name: 'United Kingdom'
    },
    // {
    //   flag: Zambia,
    //   name: 'Zambia'
    // },
    // {
    //   flag: Malawi,
    //   name: 'Malawi'
    // },
    // {
    //   flag: Senegal,
    //   name: 'Senegal'
    // },
    // {
    //   flag: Zimbabwe,
    //   name: 'Zimbabwe'
    // },
    // {
    //   flag: Liberia,
    //   name: 'Liberia'
    // },
    // {
    //   flag: Gambia,
    //   name: 'Gambia'
    // },
    // {
    //   flag: Benin,
    //   name: 'Benin'
    // },
    // {
    //   flag: Guinea,
    //   name: 'Guinea'
    // },
    // {
    //   flag: Kenya,
    //   name: 'Kenya'
    // },
    // {
    //   flag: Botswana,
    //   name: 'Botswana'
    // },
    // {
    //   flag: Chad,
    //   name: 'Chad'
    // },
    // {
    //   flag: Burundi,
    //   name: 'Burundi'
    // },

    // {
    //   flag: DRC,
    //   name: 'DRC'
    // },


    // {
    //   flag: RCA,
    //   name: 'RCA'
    // },

    // {
    //   flag: SouthSudan,
    //   name: 'South Sudan'
    // },

    // {
    //   flag: SouthAfrica,
    //   name: 'South Africa'
    // },

    // {
    //   flag: Somalia,
    //   name: 'Somalia'
    // },
    // {
    //   flag: Morocco,
    //   name: 'Morocco'
    // },
    // {
    //   flag: Niger,
    //   name: 'Niger'
    // },
    // {
    //   flag: EquatorialGuinea,
    //   name: 'Equatorial Guinea'
    // },
    // {
    //   flag: Egypt,
    //   name: 'Egypt'
    // },



  ]
  return (
    <div className="dropdown border-none">
      <button
        className="btn btn-footer-country dropdown-toggle"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      // onClick={handleDisplay}
      >
        <img src={Ghana} className="mx-2" style={{ height: "40px", }} alt="Ghana" />
        Ghana
      </button>


      <div
        className="dropdown-menu "
        aria-labelledby="dropdownMenuButton1"
        style={{ fontSize: "10px", width: "400px" }}
      >

        <div className=" d-md-flex d-sm-inline row">
          {countryData.map((data, key) => (
            <div key={key} className="col-3 d-flex justify-content-center">
              <a className="dropdown-item text-center" href="/countries">
                <img src={data.flag} style={{ height: "30px", width: "30px", objectFit: "cover", }} alt="Ghana" />
                <div className="text-center">{data.name}</div>
              </a>
            </div>
          ))}
        </div>
      </div>

    </div>
  );
};
