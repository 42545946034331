// import styles from "./PostDetailsWithSidebar.module.scss";
import { Link } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { createMedia } from "@artsy/fresnel";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import '../../styles/blog.css';
import ContentShimmer from "react-content-shimmer";




interface dataType {
  id: id;
  title: title;
  excerpt: excerpt;
  acf: acf;
}
interface id {
  id: number;
}

interface title {
  rendered: string;
}

interface excerpt {
  rendered: string;
  protected: boolean;
}

interface acf {
  images: images;
}

interface images {
  first: any;
  second: number;
  third: number;
  fourth: number;
}

function Mobile_experience_card() {
  var settings = {
    dots: true,
    infinite: true,
    arrows: false,
    // className: 'notes-slider',
    autoplay: true,
    autoplaySpeed: 7000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const [data, setData] = useState<dataType[]>([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [content, setContent] = useState<dataType>();

  const fetch = async () => {
    try {
      const data = await axios
        .get(`${process.env.REACT_APP_BASE_URL}/${t("experience")}`, {
          headers: {
            // "ngrok-skip-browser-warning": "69420",
            //         Authorization: `Bearer ${process.env.AUTH_TOKEN}`,
          },
        })
        .then((res) => {
          setData(res.data);
          if (res.data[0] != undefined)
            setContent(res.data[0])
        });
      setLoading(true);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetch();
  }, []);


  return (
    <>
      {loading ? (<div>
        {/* <Slider {...settings} className=" rounded p-0 m-0">
      {data.map((item, key) => (
       
      ))}
    </Slider> */}
        {content ? (
          <div  >
            <Card className="border-0" style={{ borderRadius: "20px" }}>
              <Card.Img src={content.acf.images.first} variant="top" style={{
                objectFit: "cover",
                width: "100%",
                height: "300px",
                borderRadius: "20px"
              }}>
              </Card.Img>
              <Card.Body>
                <div className="m-0 p-0 ">
                  <h2>
                    <Link
                      to={`/experience/${content.title.rendered.replace(/\s/g, "_")}/${content.id}`}
                      className="mb-4 fs-3 d-flex content text-black justify-content-center"
                      style={{ textDecoration: "none", marginBottom: "30px" }}
                    >
                      {content.title.rendered}
                    </Link>
                  </h2>
                  <p style={{ fontSize: "14px" }} className="text-center"
                    dangerouslySetInnerHTML={{ __html: content.excerpt.rendered }}
                  />
                </div>

              </Card.Body>

            </Card>
          </div>
        ) : null}
      </div>) : (
        <div className="mt-4 bg-light" style={{ marginTop: "10%", width: "100%", height: "500px", borderRadius: "20px" }}> <div >
          <div style={{ width: "100%" }}>
            {/* <div className="p-2 lign-items-center "> */}
            <div

              style={{
                width: "100%",
                borderRadius: "20px",
                height: "350px",
              }}
            >
              <Row >
                <Col md={6}>
                  <div className="">
                    <ContentShimmer
                      style={{ width: "100%", height: "300px" }}
                      rounded="5%"
                    />
                  </div>

                  <div className="mt-5 px-4 d-flex justify-content-center">
                    <ContentShimmer
                      style={{
                        marginTop: "1rem", width: "200px", height: "10px",
                      }}
                      rounded={"10px"} />
                  </div>
                  <div className="mt-4 px-4">
                    <ContentShimmer
                      style={{
                        marginTop: "1rem", width: "w-100", height: "10px",
                      }}
                      rounded={"10px"} />
                    <ContentShimmer
                      style={{
                        marginTop: "1rem", width: "w-100", height: "10px",
                      }}
                      rounded={"10px"} />
                    <ContentShimmer
                      style={{
                        marginTop: "1rem", width: "w-100", height: "10px",
                      }}
                      rounded={"10px"} />
                    <ContentShimmer
                      style={{
                        marginTop: "1rem", width: "w-100", height: "10px",
                      }}
                      rounded={"10px"} />
                  </div>
                </Col>
              </Row>
            </div>
            {/* </div> */}
          </div>
        </div>

        </div>
      )}


    </>

  );
}

export default Mobile_experience_card;
