import React from "react";
import Navbars from "../components/navbar/Navbars";
import "../styles/learnMore.css";
import { Container, Row, Col } from "react-bootstrap";
import {
  Ball2,
  Ball3,
  BettingBgg,
  BettingBusiness,
  Boost1,
  Boost2,
  Boost3,
  Boost4,
  CostIcon,
  Football,
  IntegrateNsanoApi,
  PayoutIcon,
  WomanPlayingFootball,
} from "../../core/resources/assets";

const LearnMore = () => {
  return (
    <div>
      {" "}
      <Navbars />
      <img src={BettingBgg} alt="" className="img-fluid w-100 h-100" />
      {/*Grow your Betting business */}
      <section className="pt-lg-8 pt-6 py-xxl-10">
        <Container>
          <Row className="d-flex align-items-center my-4">
            <Col lg={6} xxl={6}>
              <div>
                <h1 className="mb-3 display-5 fw-bold headerFont">
                  Grow your Betting Business with{" "}
                  <span className="text-bottom-line growth">Nsano</span>
                </h1>
                <p className="mb-4 fs-6">
                  Make payouts to your customers for free when you integrate
                  with Nsano’s Payment APIs.
                </p>

                <a
                  href="mailto:sales@nsano.com"
                  className="btn btn-primary btn-heroCS  fs-6 px-5"
                >
                  <span>Getting Started</span>
                </a>
              </div>
            </Col>

            <Col lg={6} xxl={5} className="offset-xxl-1">
              <div className="text-center d-none d-lg-block">
                <img
                  src={BettingBusiness}
                  alt="yoga-coach"
                  className="img-fluid"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* Football at the right side */}
      <div
        className="position-relative d-none d-lg-block "
        style={{ height: "300px;" }}
      >
        <img
          src={Football}
          alt=""
          className="position-absolute"
          style={{ right: "0px", top: "-200px" }}
        />
      </div>
      {/* How it works */}
      <div className="how-it-works-bg pb-lg-5">
        <Container className="content-space-1 pb-lg-5">
          <Row lg={6} className=" py-5">
            <h2 className="text-white mb-5 headerFont">How it Works</h2>
          </Row>
          <Row className="flex flex-wrap  py-5 gap-5 ">
            <Col className="mb-lg-5 glass m-3 rounded-3">
              <div className="card-body d-flex flex-column gap-4 text-center">
                <div>
                  <img
                    style={{ marginTop: "-100px" }}
                    className=""
                    src={IntegrateNsanoApi}
                    alt="yoga-coach"
                  />
                </div>

                <div>
                  <h1 className="mb-3 display-1  headerFont">01</h1>
                  <h4 className="mb-2 text-white headerFont">
                    Integrate with Nsano’s APIs 
                  </h4>
                  <p
                    className="text-white mb-5 fw-light"
                    style={{ fontSize: "14px" }}
                  >
                    Seamlessly connect your betting platform to Nsano’s trusted
                    and secure APIs for both collections and disbursements. 
                  </p>
                </div>
              </div>
            </Col>
            <Col className="mb-lg-5 glass m-3 rounded-3">
              <div className="card-body d-flex flex-column gap-4 text-center">
                <div>
                  <img
                    style={{ marginTop: "-100px" }}
                    className=""
                    src={PayoutIcon}
                    alt="yoga-coach"
                  />
                </div>

                <div>
                  <h1 className="mb-3 display-1  headerFont">02</h1>
                  <h4 className="mb-2 text-white headerFont">
                    Get Free Payouts 
                  </h4>
                  <p
                    className="text-white mb-5 fw-light"
                    style={{ fontSize: "14px" }}
                  >
                    Yes you heard right! Get free payouts when you process a
                    minimum of GHS 3 million in collections each month during
                    the promo period.
                  </p>
                </div>
              </div>
            </Col>
            <Col className="mb-lg-5 glass m-3 rounded-3">
              <div className="card-body d-flex flex-column gap-4 text-center">
                <div>
                  <img
                    style={{ marginTop: "-100px" }}
                    className=""
                    src={CostIcon}
                    alt="yoga-coach"
                  />
                </div>

                <div>
                  <h1 className="mb-3 display-1  headerFont">03</h1>
                  <h4 className="mb-2 text-white headerFont">Save Cost </h4>
                  <p
                    className="text-white mb-5 fw-light"
                    style={{ fontSize: "14px" }}
                  >
                    Watch your business grow as you enjoy free payouts and
                    greater customer satisfaction with fast, secure payments. 
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Football at the right side */}
      <div
        className="position-relative d-none d-lg-block "
        style={{ height: "300px;" }}
      >
        <img
          src={WomanPlayingFootball}
          alt=""
          className="position-absolute"
          style={{ right: "100px", top: "-200px", width: "300px" }}
        />
      </div>
      {/* Boost your betting Business */}
      <Container className="content-space-1 mx-auto">
        <Row lg={7} className=" py-5">
          <h2 className=" headerFont">Boost your Betting Business</h2>
        </Row>
        <Row className="flex flex-wrap flex-md-nowrap  gap-2 ">
          <Col className="rounded-3">
            <div className="">
              <img
                src={Boost4}
                alt=""
                className="card-img-top img-fluid w-100"
              />
            </div>
            <div
              className="card mt-4 card-lift "
              style={{ backgroundColor: "#FBE1AD" }}
            >
              <div className="card-body d-flex flex-column gap-4">
                <div className="d-flex flex-column gap-2">
                  <h6
                    style={{ fontSize: "17px" }}
                    className="mb-0 text-center  fw-semibold"
                  >
                    Enjoy zero fees on all payouts
                  </h6>
                  <h6
                    style={{ fontSize: "17px" }}
                    className="mb-0 text-center opacity-75 fw-semibold"
                  >
                    when you achieve the collection
                  </h6>
                  <h6
                    style={{ fontSize: "17px" }}
                    className="mb-0 text-center  fw-semibold opacity-50"
                  >
                    Target
                  </h6>
                </div>
              </div>
            </div>
          </Col>
          <Col className=" rounded-3">
            <div
              className="card  card-lift "
              style={{ backgroundColor: "#FBE1AD" }}
            >
              <div className="card-body d-flex flex-column gap-4">
                <div className="d-flex flex-column gap-2">
                  <h6
                    style={{ fontSize: "17px" }}
                    className="mb-0 text-center  fw-semibold"
                  >
                    Free payouts mean more money to
                  </h6>

                  <h6
                    style={{ fontSize: "17px" }}
                    className="mb-0 text-center  fw-semibold opacity-50"
                  >
                    Reinvest in your business. 
                  </h6>
                </div>
              </div>
            </div>
            <div className=" mt-4">
              <img
                src={Boost3}
                alt=""
                className="card-img-top img-fluid w-100"
              />
            </div>
          </Col>
          <Col className=" rounded-3">
            <div className="">
              <img
                src={Boost2}
                alt=""
                className="card-img-top img-fluid w-100"
              />
            </div>
            <div
              className="card mt-4 card-lift "
              style={{ backgroundColor: "#FBE1AD" }}
            >
              <div className="card-body d-flex flex-column gap-4">
                <div className="d-flex flex-column gap-2">
                  <h6
                    style={{ fontSize: "17px" }}
                    className="mb-0 text-center  fw-semibold"
                  >
                    Simple and fast setup with Nsano's
                  </h6>

                  <h6
                    style={{ fontSize: "17px" }}
                    className="mb-0 text-center  fw-semibold opacity-50"
                  >
                    cutting-edge API.
                  </h6>
                </div>
              </div>
            </div>
          </Col>
          <Col className=" rounded-3">
            <div
              className="card  card-lift "
              style={{ backgroundColor: "#FBE1AD" }}
            >
              <div className="card-body d-flex flex-column gap-4">
                <div className="d-flex flex-column gap-2">
                  <h6
                    style={{ fontSize: "17px" }}
                    className="mb-0 text-center  fw-semibold"
                  >
                    Unlock Higher Collection Volumes
                  </h6>
                  <h6
                    style={{ fontSize: "17px" }}
                    className="mb-0 text-center opacity-75 fw-semibold"
                  >
                    with an easy-to-use payment
                  </h6>
                  <h6
                    style={{ fontSize: "17px" }}
                    className="mb-0 text-center  fw-semibold opacity-50"
                  >
                    Gateway
                  </h6>
                </div>
              </div>
            </div>
            <div className=" mt-4">
              <img
                src={Boost1}
                alt=""
                className="card-img-top img-fluid w-100"
              />
            </div>
          </Col>
        </Row>
      </Container>
      {/* Terms and Condition */}
      <Container className="content-space-1  mx-auto">
        <Row lg={7} className=" py-5">
          <h2 className="mx-2 headerFont">Terms and Conditions</h2>
        </Row>
        <Row className="flex flex-column flex-md-row gap-2">
          <Col className="bg-light border-top border-5 borderColor  p-4 m-lg-3 ">
            <div className="card-body">
              <div>
                <h4 className="mb-4  headerFont">Eligibility</h4>
                <p className=" mb-3 fw-normal fs-6">
                  1. The Promo is open to licensed betting companies registered
                  in Ghana that have not previously used the Company’s payment
                  services or APIs.
                </p>
                <p className=" mb-3 fw-normal fs-6">
                  2. The Company reserves the right to verify the eligibility of
                  all Participants, including requesting for licenses and other
                  relevant documentation. 
                </p>
                <p className=" mb-3 fw-normal fs-6">
                  3. Only Participants that complete integration and commence
                  transactions during the Promo Period are eligible.
                </p>
              </div>
            </div>
          </Col>
          <Col className="bg-light border-top border-5 borderColor  p-4 m-lg-3 ">
            <div className="card-body">
              <div>
                <h4 className="mb-4  headerFont">Promo Period</h4>
                <p className=" mb-3 fw-normal fs-6">
                  1. The Promo is open to Participants who successfully sign up
                  to the Promo between 21st October and 31st December 2024
                </p>
                <p className=" mb-3 fw-normal fs-6">
                  2. The Promo shall run for a period of three (3) months
                  following successful sign-up by a Participant.  
                </p>
                <p className=" mb-3 fw-normal fs-6">
                  3. The final disbursement of all accumulated rewards under
                  this Promo shall be completed by 31st March 2025, regardless
                  of when the Participant began processing transactions or
                  qualified for the cashback during the Promotion Period.  
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="flex flex-column flex-md-row gap-2">
          <Col className="bg-light border-top border-5 borderColor  p-4 m-lg-3 ">
            <div className="card-body">
              <div>
                <h4 className="mb-4  headerFont">The Promo Offer</h4>
                <p className=" mb-3 fw-normal fs-6">
                  1. Participants must process at least three Million Cedis (GHS
                  3,000,000.00) (the Threshold) in a given month
                </p>
                <p className=" mb-3 fw-normal fs-6">
                  2. Participants that meet the collections threshold in each
                  month shall receive cashback on collections fees. This
                  cashback shall be paid at the end of the month after
                  settlements and reconciliations are made. 
                </p>
                <p className=" mb-3 fw-normal fs-6">
                  3. The free payouts shall cover all standard payout
                  transactions to beneficiaries during the eligible month.
                  E-levy charges shall still apply to all transactions as per
                  current national requirements.  E-levy fees are not covered by
                  the Company and shall be borne by the Participant.   
                </p>
                <p className=" mb-3 fw-normal fs-6">
                  4. Should a Partner not meet the collections threshold for any
                  given month, standard payout fees shall apply for that month’s
                  transactions. 
                </p>
              </div>
            </div>
          </Col>
          <Col className="bg-light border-top border-5 borderColor  p-4 m-lg-3 ">
            <div className="card-body">
              <div>
                <h4 className="mb-4  headerFont">General Provisions</h4>
                <p className=" mb-3 fw-normal fs-6">
                  1. Participants acknowledge that their use of the Company’s
                  APIs and services is subject to the standard API usage terms
                  and conditions.
                </p>
                <p className=" mb-3 fw-normal fs-6">
                  2. The Company reserves the right to modify, suspend, or
                  terminate the Promo at any time, with or without prior
                  notice. 
                </p>
                <p className=" mb-3 fw-normal fs-6">
                  3. Participants acknowledge that they have read, understood,
                  and agree to be bound by these Terms and Conditions. Failure
                  to adhere to these Terms and Conditions may result in
                  disqualification from the Promo. 
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      {/* Football at the right side hh */}
      <div className="position-relative d-none d-lg-flex justify-content-between align-items-end">
        <img
          src={Ball2}
          alt=""
          className=""
          style={{ width: "220px", height: "160px" }}
        />
        <img src={Ball3} alt="" className="" style={{ width: "300px" }} />
      </div>
    </div>
  );
};

export default LearnMore;
