import styles from "./PostDetailsWithSidebar.module.scss";
import { Link } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import { Card, Col, Row } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { createMedia } from "@artsy/fresnel"
import Slider from "react-slick";
import Desktop_experience_card from "../experience_card_device_view/desktop_experience_card";
import Tablet_experience_card from "../experience_card_device_view/tablet_experience_card";
import Mobile_experience_card from "../experience_card_device_view/mobile_experience_card";

interface dataType {
  id:id
  title: title;
  excerpt: excerpt;
  acf: acf;
}
interface id {
    id: number;
  }

interface title {
  rendered: string;
}

interface excerpt {
  rendered: string;
  protected: boolean;
}

interface acf {
  images: images;
}

interface images {
  first: any;
  second: number;
  third: number;
  fourth: number;
}

function Experience() {
  var settings = {
    dots: false,
    infinite: true,
    arrows: false,
    // className: 'notes-slider',
    autoplay: true,
    autoplaySpeed: 7000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };


  const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
      sm: 0,
      md:768,
      lg: 1403,
      xl: 2065,
    },
  })
 
  return (
    

    <MediaContextProvider>
    <Media at="sm">
      <Mobile_experience_card/>
    </Media>
    <Media at="md">
      <Tablet_experience_card/>
    </Media>
    <Media greaterThanOrEqual="lg">
      <Desktop_experience_card/>
    </Media>
  </MediaContextProvider>
  );
}

export default Experience;
