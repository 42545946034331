// import "./virtualcard.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { createMedia } from "@artsy/fresnel";
import Staff_infocus_desktop_view from "./staff_infocus_desktop_view";
import Staff_infocus_tablet_view from "./staff_infocus_tablet_view";
import Staff_infocus_mobile_view from "./staff_infocus_mobile_view";
import Staff_infocus_small_screen from "./staff_infocus_small_screen";
import Staff_infocus_xtrasmall_screen from "./staff_infocus_extra_small";

function StaffInFocus() {
  const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
      xxs:0,
      xs: 500,
      sm: 768,
      md: 992,
      lg: 1200,
      xl: 1400,
    },
  });

  return (
    <div className="mt-3 ">
      <MediaContextProvider>
      <Media at="xxs">
          <Staff_infocus_xtrasmall_screen />
        </Media>
        <Media at="xs">
          <Staff_infocus_small_screen />
        </Media>
        <Media at="sm">
          <Staff_infocus_mobile_view />
        </Media>
        <Media at="md">
          <Staff_infocus_tablet_view />
        </Media>
        <Media greaterThanOrEqual="lg">
          <Staff_infocus_desktop_view />
        </Media>
      </MediaContextProvider>
    </div>
  );
}

export default StaffInFocus;
