import { useState, useEffect } from "react";
import FooterWithCountry from "../components/FooterWithCountry";
import Navbars from "../components/navbar/Navbars";
import { Container, Row, Col ,Image} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Footer from "../components/Footer";
import CircleGhFlag from '../../core/resources/assets/images/circlegh.png'
import CircleZmFlag from '../../core/resources/assets/images/circlezambia.png'
import CircleCtFlag from '../../core/resources/assets/images/circlecote.png'
import CircleSlFlag from '../../core/resources/assets/images/circlesierre.png'
import CircleUgFlag from '../../core/resources/assets/images/circleuganda.png'
import CircleRwFlag from '../../core/resources/assets/images/circlerwanda.png'

import Logo1 from "../../core/resources/assets/logos/msecb.png";
import Logo2 from "../../core/resources/assets/logos/bkgh.png";
import Logo3 from "../../core/resources/assets/logos/bkzm.png";
import Logo4 from "../../core/resources/assets/logos/PCIDSS.png";
import { CountriesForFooter } from "../components/countries_for_footer";


interface dataType {
  title: title;
  acf: acf;
}
interface title {
  rendered: string;
}
interface acf {
  faq1: any;
  en: any;
  question: string;
}

function Faqs() {
  const { t } = useTranslation();

  const [faqs, setFaqs] = useState<dataType[]>([]);
 
 
  const fetchFaqs =()=>{
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/${t("faq_lang")}/`, {
        headers: {
          // "ngrok-skip-browser-warning": "69420",
 //         Authorization: `Bearer ${process.env.AUTH_TOKEN}`,
        },
      })
      .then((res) => {
        console.log(res);
        setFaqs(res.data);
      })

      .catch((error) => {
        // alert(error);
        console.log(error)
      });
  }
  useEffect(() => {
    console.log(t("faq"));
    fetchFaqs()
  }, []);

  return (
    <div>
      <Navbars />
      <Container className="my-5 ">
        <Row className="mb-2  justify-content-center">
          <Col md={9}>
            <div className="lc-block">
              <div className="rich-text">
                <h2 className="fw-bold my-5 animate__animated animate__fadeInUp  animate__delay-1s">{t("nsano_faqs")}</h2>
                {faqs.map((item, key) => (
                  <div key={key}>
                    <h2 className="fw-medium fs-4 my-5 animate__animated animate__fadeInUp animate__delay-2s">
                      {item.title.rendered}
                    </h2>
                    <table className="table table-bordered animate__animated animate__fadeInUp animate__delay-3s">
                      <thead>
                        <tr>
                          <th scope="col">{t("questions")}</th>
                          <th scope="col">{t("answers")}</th>
                        </tr>
                      </thead>
                      <tbody className="animate__animated animate__fadeInUp animate__delay-2s">
                        {Object.values(item.acf).map((faq, acfKey) => (
                          <tr key={acfKey}>
                            <td>{faq.question}</td>
                            <td>{faq.answer}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      <Footer onLanguageChange={(v) => fetchFaqs()} >
      
   
                  <div className="">
                    <div className=" btn-footer-regulators ">
                      <div className="d-flex items-align-center">
                      <div className=""><Image src={Logo1} className="" style={{height:"30px",width:"30px",objectFit:"contain"}}/></div> 
                      <div ><Image src={Logo2} className="" style={{height:"30px",width:"50px",objectFit:"contain"}}/></div> 
                      <div className=""><Image src={Logo3} className="" style={{height:"30px",width:"40px",objectFit:"contain"}}/></div> 
                      <div className=""><Image src={Logo4} className="" style={{height:"30px",width:"50px",objectFit:"contain"}}/></div> 
                    </div>
                </div>
              </div>

             <CountriesForFooter/>
    </Footer>
    </div>
  );
}

export default Faqs;
