import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import SingleArticle from "../../presentation/components/Articles/SingleArticle";
import MoreNews from "../../presentation/components/blog-sidebar/MoreNews";
import News from "../../presentation/components/blog-sidebar/News";
import SingleCarousel from "../../presentation/components/post-details-with-sidebar/singleCarousel";
import Single_Experience from "../../presentation/components/post-details-with-sidebar/single_experience";
import Customer_service from "../../presentation/components/virtual_cards_device_view/customer_service";
import Ecommerce from "../../presentation/components/virtual_cards_device_view/e-commerce";
import Quality_check from "../../presentation/components/virtual_cards_device_view/quality_check";
import Toolkit from "../../presentation/components/virtual_cards_device_view/toolkit";
import Blog from "../../presentation/pages/Blog";
import Careers from "../../presentation/pages/Careers";
import CountryInfo from "../../presentation/pages/Countries";
import Faq from "../../presentation/pages/Faqs";
import Home from "../../presentation/pages/Home";
import OurPEOPLE from "../../presentation/pages/OurPeople";
import OurPRODUCTS from "../../presentation/pages/OurProducts";
import WhyNSANO from "../../presentation/pages/WhyNsano";
import { Privacy } from "../../presentation/pages/privacy";
import LearnMore from "../../presentation/pages/LearnMore";

function Routing() {
  return (
    <div>
      <Routes>
        {/* Main Routes */}
        <Route path="/">
          <Route index element={<Navigate replace to="home" />} />
          <Route path="home" element={<Home />} />
          <Route path="why-nsano" element={<WhyNSANO />} />
          <Route path="our-products" element={<OurPRODUCTS />} />
          <Route path="our-people" element={<OurPEOPLE />} />
          <Route path="blog" element={<Blog />} />
          <Route path="countries" element={<CountryInfo />} />
          <Route path="careers" element={<Careers />} />
          <Route path="news/:id" element={<News />} />
          <Route path="experience/:title/:id" element={<Single_Experience />} />
          <Route path="blog/:title/:id" element={<SingleCarousel />} />
          <Route path="article/:title/:id" element={<SingleArticle />} />
          <Route path="more-news" element={<MoreNews />} />
          <Route path="ecommerce" element={<Ecommerce />} />
          <Route path="toolkit" element={<Toolkit />} />
          <Route path="quality_check" element={<Quality_check />} />
          <Route path="customer_service" element={<Customer_service />} />
          <Route path="faqs" element={<Faq />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="betting-biz-learn-more" element={<LearnMore />} />
        </Route>

        {/* Not found */}
        <Route
          path="*"
          element={
            <h1 className="text-center text-muted">
              You have hit the end of the road!
            </h1>
          }
        />
      </Routes>
    </div>
  );
}

export default Routing;
