import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import axios from "axios";
import { useTranslation } from "react-i18next";
import "../../styles/blog.css";
import { Col, Row } from "react-bootstrap";
import ContentShimmer from "react-content-shimmer";

interface dataType {
  id: id;
  title: title;
  content: content;
  better_featured_image: better_featured_image;
  excerpt: excerpt;
  // id: number;
}
interface id {
  id: number;
}

interface excerpt {
  rendered: string;
}

interface title {
  rendered: string;
}

interface content {
  rendered: string;
  protected: boolean;
}

interface better_featured_image {
  source_url: any;
}

function HeroCarousel() {
  const [data, setData] = useState<dataType[]>([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const fetch = async () => {
    try {
      const data = await axios
        .get(`${process.env.REACT_APP_BASE_URL}/${t("blog_carousel")}`, {
          headers: {
            // "ngrok-skip-browser-warning": "69420",
            //         Authorization: `Bearer ${process.env.AUTH_TOKEN}`,
          },
        })
        .then((res) => {
          setData(res.data);
        });
      setLoading(true);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetch();
   
    
  }, []);



  return (
    <>
      {loading ? (
        <Carousel fade touch controls={false}>
          {data.map((item, key) => (
            <Carousel.Item className="carousel" key={key}>
              <Link
                to={`/blog/${item.title.rendered.replace(/\s/g, "_")}/${item.id}`}
                className="text-decoration-none "
              >
                <img
                  className="d-block w-100 "
                  src={item.better_featured_image.source_url}
                  style={{
                    width: "800px",
                    height: "600px",
                    objectFit: "cover",
                    borderRadius: "30px",
                    background: "white",
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                    borderRadius:
                      "20px" /* Change the color and opacity as desired */,
                  }}
                ></div>
              </Link>
              <Carousel.Caption>
                <Link
                  to={`/blog/${item.title.rendered.replace(/\s/g, "_")}/${item.id}`}
                  className="text-decoration-none text-muted"
                >
                  <Row>
                    <Col md={12} lg={6} sm={12}>
                      <div
                        className="text-start"
                        style={{ marginLeft: "-15%", marginTop: "-20%" }}
                      >
                        <span className=" text-white text-start fw-semibold content fs-2">
                          {item.title.rendered.slice(0, 50) + "..."}
                        </span>
                        <div
                          className=" fw-light content-text text-white"
                          style={{ fontSize: "15px" }}
                          dangerouslySetInnerHTML={{
                            __html:
                              item.excerpt.rendered.substring(0, 100) +
                              "..." +
                              "read more",
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Link>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      ) : (
        <div style={{ width: "100%" }}>
          <Carousel>
            <Carousel.Item>
              <ContentShimmer
                style={{ width: "w-100", height: "600px" }}
                rounded="5%"
              />
            </Carousel.Item>
          </Carousel>
        </div>
      )}
    </>
  );
}

export default HeroCarousel;
