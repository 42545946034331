import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import cardImg1 from '../../../core/resources/assets/images/payment.png';
import cardImg2 from '../../../core/resources/assets/images/Parcels_Icon.png';
import cardImg3 from '../../../core/resources/assets/images/Quality_Icon.png';
import cardImg4 from '../../../core/resources/assets/images/customer.png';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CiMoneyCheck1 } from "react-icons/ci";

function Desktop_virtualcard_view() {
  const { t } = useTranslation();
  return (
    <div>
    <Row className="mb-4 px-2  gx-3" style={{marginTop:"-6px"}}>
      <Col md={6} > <Link
            to="/ecommerce" className="text-decoration-none">
        <Card className="border border-0" style={{height:"160px",background:"#005aee",borderRadius:"20px"}}>
          <Card.Header className="border border-0 ">
            <Card.Title className="text-end" >
            <CiMoneyCheck1 className="text-white mx-2" style={{ fontSize:"45px",fontWeight:"lighter"}}/>

            {/* <img src={cardImg1} className="mx-2 mt-2" style={{objectFit:"cover", width:"35px"}}/> */}
            </Card.Title>
            </Card.Header>
          <Card.Body className="text-white fw-semibold" style={{fontSize:"17px"}}>
            {t("e-commerce_card_title")}
            <p className="mb-4" style={{fontSize:"11px"}}>
            {/* {t("e-commerce_card_body")} */}
            </p>
            </Card.Body>
        </Card>
        </Link>
      </Col>
      <Col>
      <Link
            to="/toolkit" className="text-decoration-none">
        <Card className="border border-0" style={{height:"160px",background:"#ffa767",borderRadius:"20px"}}>
          <Card.Header className="border border-0">
          <Card.Title className="text-end" >
            <img src={cardImg2} className="mx-2 mt-2" style={{objectFit:"cover", width:"35px"}}/>
            </Card.Title>
          </Card.Header>
          <Card.Body className="text-white fw-semibold text-decoration-none" style={{fontSize:"17px"}}>
          {t("toolkit_card_title")}
            <p className="mb-4 "  style={{fontSize:"11px"}}>
            {/* {t("toolkit_card_body")} */}
            </p>
            </Card.Body>
        </Card>
        </Link>
      </Col>
    </Row>
    <Row className="px-2 gx-3">
      <Col md={6}>
      <Link
            to="/quality_check" className="text-decoration-none">
        <Card className="border border-0" style={{height:"160px",background:"#0aacdc",borderRadius:"20px"}}>
          <Card.Header className="border border-0">
          <Card.Title className="text-end" >
            <img src={cardImg3} className="mx-2 mt-2" style={{objectFit:"cover", width:"35px"}}/>
            </Card.Title>
          </Card.Header>
          <Card.Body className="text-white fw-semibold text-decoration-done" style={{fontSize:"17px"}}>
          {t("quality_card_title")}
            <p style={{fontSize:"11px"}}>
            {/* {t("quality_card_body")} */}
            </p>
            </Card.Body>
        </Card>
        </Link>
      </Col>
      <Col>
      <Link
            to="/customer_service" className="text-decoration-none">
        <Card className="border border-0" style={{height:"160px",background:"#597aef",borderRadius:"30px"}}>
          <Card.Header className="border border-0">
          <Card.Title className="text-end" >
            <img src={cardImg4} className="mx-2 mt-2" style={{objectFit:"cover", width:"35px"}}/>
            </Card.Title>
          </Card.Header>
          <Card.Body className="text-white fw-semibold text-decoration-none" style={{fontSize:"17px"}}>
          {t("customer_card_title")}
            <p style={{fontSize:"11px"}}>
            {/* {t("customer_card_body")} */}
            </p></Card.Body>
        </Card>
        </Link>
      </Col>
    </Row>
    </div>
    
  );
}

export default Desktop_virtualcard_view;
