import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Langswitcher from "./Translations/langswitcher";



export const FooterLinks = (props) => {
    const { t } = useTranslation();
    return(
        <div lc-helper="shortcode" className="live-shortcode mx-4 ">
        {" "}
        <ul
          id="menu-menu-1"
          className="nav-list-style footer-nav-for-mobile mb-2 mb-md-0 d-grid d-flex flex-wrap justify-content-center align-items-center but"
          
        >
          <li className="menu-item menu-item-type-custom menu-item-object-custom  nav-item-32739">
            <Link to="/faqs" className="nav-link btn-footer px-5">
              FAQs
            </Link>
          </li>
          <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-home nav-item nav-item-32738">
            <Link to="/why-nsano" className="nav-link btn-footer px-5">
            {t("Why_Nsano")}
            </Link>
          </li>
          {props.children}
          <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-home nav-item nav-item-32738">
            <a
              href="mailto:sales@nsano.com"
              className="nav-link btn-footer px-5"
            >
              {t("Contact_us")}
            </a>
          </li>
          
          <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-home nav-item nav-item-32738">
            <div className="nav-link ">
              <Langswitcher onLanguageChange={props.onLanguageChange}/>
            </div>
          </li>
          <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-home nav-item nav-item-32738">
          <Link to="/privacy" className="nav-link btn-footer">
          {t("privacy-footer")}
                  </Link>
                </li>
          
        </ul>{" "}
      </div>
    )
   
}