import React from "react";
import { HashRouter } from "react-router-dom";
import "./App.css";
import Routing from "./lib/core/AppRouting/Routing";

function App() {
  return (
    <React.Suspense fallback={""}>
      <div className="App">
        <Routing />
      </div>
    </React.Suspense>
  );
}

export default App;
