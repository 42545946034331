import React from "react";
import "../styles/footer.css";
import { Link } from "react-router-dom";
import Logo from "../../core/resources/assets/logos/logo.png";
import { useTranslation } from "react-i18next";
import Langswitcher from "./Translations/langswitcher";
import { FooterLinks } from "./footerLinks";
import {Form,Image,Button} from 'react-bootstrap';



function Footer(props) {
  const { t } = useTranslation();

  return (
    <div>
      <section className="">
        <div className="py-6 text-center mt-5" style={{marginLeft:"-5%"}}>
          <div className="col-md-12 navbar-expand">
            <FooterLinks>
              {props.children}
            </FooterLinks>
          </div>

          <div className="lc-block">
            <div>
              <p className="text-muted my-2"> {t("Copyright")} - Nsano 2022</p>
            </div>
            <img className="my-2" src={Logo} alt="" width="80px" />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
