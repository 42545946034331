import React, { useEffect, useState } from "react";
import Navbars from "../components/navbar/Navbars";
import "../styles/careers.css";
import Jobicon from "../../core/resources/assets/images/job-icon.png";
import Voyager from "../../core/resources/assets/videos/voyager.mp4";
import { Col, Container, Row ,Image} from "react-bootstrap";
import CareerModal from "../components/CareerModal/CareerModal";
import { useTranslation } from "react-i18next";
import { BsArrowRight } from "react-icons/bs";
import axios from "axios";
import { Career } from "../../core/resources/assets/";
import UploadCV from "../components/CareerModal/upload_cv";
import ReactPaginate from "react-paginate";
import * as ReactBootstrap from "react-bootstrap";
import Footer from "../components/Footer";

import CircleGhFlag from '../../core/resources/assets/images/circlegh.png'
import CircleZmFlag from '../../core/resources/assets/images/circlezambia.png'
import CircleCtFlag from '../../core/resources/assets/images/circlecote.png'
import CircleSlFlag from '../../core/resources/assets/images/circlesierre.png'
import CircleUgFlag from '../../core/resources/assets/images/circleuganda.png'
import CircleRwFlag from '../../core/resources/assets/images/circlerwanda.png'

import Logo1 from "../../core/resources/assets/logos/msecb.png";
import Logo2 from "../../core/resources/assets/logos/bkgh.png";
import Logo3 from "../../core/resources/assets/logos/bkzm.png";
import Logo4 from "../../core/resources/assets/logos/PCIDSS.png";
import { CountriesForFooter } from "../components/countries_for_footer";



interface dataType {
  id: id;
  acf: acf;
}

interface id {
  id: number;
}

interface acf {
  career_session: career_session;
}

interface career_session {
  department: string;
  job_info: string;
  start_date: string;
  end_date: string;
  location: string;
  position:string;
}

function Careers() {
  const { t } = useTranslation();

  const [data, setData] = useState<dataType[]>([]);
  const [pageCount, setpageCount] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  let limit = 5;

  const fetch = async () => {
    try {
      const data = await axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/${t(
            "career_session"
          )}/?per_page=${limit}&page=1`,
          {
            headers: {
              // "ngrok-skip-browser-warning": "69420",
              //         Authorization: `Bearer ${process.env.AUTH_TOKEN}`,
            },
          }
        )
        .then((res) => {
          const total = res.headers["x-wp-total"];
          let num = Number(total);
          setpageCount(Math.ceil(num / limit));
          setData(res.data);
        });
      setLoading(true);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchpage = async (currentPage) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/${t(
          "career_session"
        )}/?per_page=${limit}&page=${currentPage}`,
        {
          headers: {
            // "ngrok-skip-browser-warning": "69420",
            //         Authorization: `Bearer ${process.env.AUTH_TOKEN}`,
          },
        }
      );
      return res.data;
    } catch (e) {
      return null;
      console.log(e);
    }
  };

  const handlePageClick = async (data) => {
    console.log(data.selected);

    let currentPage = data.selected + 1;

    const showPage = await fetchpage(currentPage);

    if (showPage) {
      setData(showPage);
    }
    // scroll to the top
    //window.scrollTo(0, 0)
  };

  // if (data != []) {
  //   setVacancy(true)
  // }

  useEffect(() => {
    fetch();
  }, [limit]);

  return (
    <div>
      <Navbars />
      <div>
        {loading ? (
          <>
            {data.length > 0 ? (
              <>
                <Container className="animate__animated animate__fadeInRight">
                  <Row className="row mb-4 mt-5 ">
                    <Col lg={12} className="mx-auto text-center">
                      <div className="lc-block position-relative">
                        <video
                          className="container-fluid rounded-5"
                          src={Voyager}
                          autoPlay
                          loop
                          controls
                        />
                      </div>
                    </Col>
                  </Row>
                </Container>

                <section className="animate__animated animate__fadeInRight">
                  <Container>
                    <Row>
                      {data.map((item, key) => (
                        <>
                          <div className="header " key={key}>
                            <span className="career-header text-center fs-6 fw-light text-muted text-wrap">
                              {/* {`${t("vacancy")}`} */}
                            </span>
                          </div>

                          <Col md={10} className="offset-md-1 ">
                            <ul className="job-list">
                              <li className="job-preview">
                                <div className="d-flex flex-wrap flex-md-nowrap">
                                  <img
                                    className="me-md-5"
                                    src={Jobicon}
                                    width="100px"
                                    height="100px"
                                    alt=""
                                  />
                                  <div className="content float-left  w-100">
                                    <div className="d-sm-flex flex-row justify-content-between jobb">
                                      <div>
                                        <h4 className="job-title">
                                          {item.acf.career_session.department}
                                        </h4>
                                        {/* <h5 className="company" style={{fontSize:"15px",}}>MicroTech.Inc</h5> */}
                                        <h6
                                          className="text-muted"
                                          style={{ fontSize: "12px" }}
                                        >
                                          {" "}
                                          {`${t("position_vacancy")}`}: {item.acf.career_session.position}
                                        </h6>
                                        <h6
                                          className="text-muted"
                                          style={{ fontSize: "12px" }}
                                        >
                                          {" "}
                                          {item.acf.career_session.location}
                                        </h6>
                                      </div>
                                      <CareerModal
                                        title= {item.acf.career_session.department}
                                        value={item.acf.career_session.position}
                                      />
                                    </div>
                                    <p className="job-describtion fs-6">
                                      {item.acf.career_session.job_info}
                                    </p>
                                    <div className="d-flex justify-content-between">
                                      <div
                                        className="text-muted "
                                        style={{ fontSize: "12px" }}
                                      >
                                        <span>
                                          {item.acf.career_session.start_date} -{" "}
                                          {item.acf.career_session.end_date}
                                        </span>
                                      </div>
                                      <div
                                        className="text-muted"
                                        style={{ fontSize: "12px" }}
                                      >
                                        <span>{`${t("details")}`}</span>
                                        <br></br>
                                        <BsArrowRight
                                          style={{
                                            fontSize: "15px",
                                            marginLeft: "18px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </Col>
                        </>
                      ))}
                    </Row>

                    <ReactPaginate
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={
                        "pagination justify-content-center bgActive"
                      }
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link "}
                      previousClassName={"page-item text-muted "}
                      previousLinkClassName={"page-link text-muted"}
                      nextClassName={"page-item text-muted"}
                      nextLinkClassName={"page-link text-muted"}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </Container>
                </section>
               
              </>
            ) : (
              <>
                <Container style={{ height: "600px" }} className=" animate__animated animate__fadeInRight">
                  <h3 className="text-center fs-1 fw-semibold mt-5 px-5">
                    {`${t("no_vacancy")}`}
                  </h3>
                  <div className="d-flex justify-content-center">
                    <img
                      src={Career}
                      alt="wrapkit"
                      className="img-fluid opacity-100 img-action"
                    />
                  </div>
                  <div className="d-flex justify-content-center">
                    <UploadCV />
                    {/* <Button className="px-4 py-3 border-0 fw-semibold" style={{borderRadius:"30px",fontSize:"15px",background:"#F09020"}} >upload cv anyway</Button> */}
                  </div>
                </Container>
              </>
            )}
             <Footer onLanguageChange={(v) => fetch()}>

              <div className="">
                    <div className=" btn-footer-regulators ">
                      <div className="d-flex items-align-center">
                      <div className=""><Image src={Logo1} className="" style={{height:"30px",width:"30px",objectFit:"contain"}}/></div> 
                      <div ><Image src={Logo2} className="" style={{height:"30px",width:"50px",objectFit:"contain"}}/></div> 
                      <div className=""><Image src={Logo3} className="" style={{height:"30px",width:"40px",objectFit:"contain"}}/></div> 
                      <div className=""><Image src={Logo4} className="" style={{height:"30px",width:"50px",objectFit:"contain"}}/></div> 
                    </div>
                </div>
              </div>
           
<CountriesForFooter/>
   
                  
              </Footer>
          </>
        ) : (
          <>
            <div className=" h-100">
              <div className="position-absolute top-50 start-50 translate-middle">
                <div className="">
                  <ReactBootstrap.Spinner animation="border" />
                </div>
              </div>
            </div>
            {/* <Container style={{ height: "600px" }} className=" animate__animated animate__fadeInRight">
                  <h3 className="text-center fs-1 fw-semibold mt-5 px-5">
                    {`${t("no_vacancy")}`}
                  </h3>
                  <div className="d-flex justify-content-center">
                    <img
                      src={Career}
                      alt="wrapkit"
                      className="img-fluid opacity-100 img-action"
                    />
                  </div>
                  <div className="d-flex justify-content-center">
                    <UploadCV /> */}
                    {/* <Button className="px-4 py-3 border-0 fw-semibold" style={{borderRadius:"30px",fontSize:"15px",background:"#F09020"}} >upload cv anyway</Button> */}
                  {/* </div>
                </Container> */}
          </>
        )}
      </div>
      
    </div>
  );
}

export default Careers;
