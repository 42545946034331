import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import land50 from "./land50.json";
import land110 from "./land110.json";
import worldCountries from "../../components/globe/countries.geojson";

// Define the type for the country feature
interface CountryFeature {
 properties: {
    NAME: string;
    // Add other properties as needed
 };
 // Add other properties as needed
}

// Tooltip component
const Tooltip = ({ x, y, text }) => (
 <div style={{ position: 'absolute', left: x, top: y, backgroundColor: 'white', padding: '5px' }}>
    {text}
 </div>
);

export const D3Globe = () => {
 const canvasRef = useRef<HTMLCanvasElement>(null);
 const [tooltip, setTooltip] = useState({ x: 0, y: 0, text: '' });
 const width = 800;
 const height = 600;

 useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext("2d");

    if (!context) return;

    const projection = d3.geoOrthographic().translate([width / 2, height / 2]);
    const path = d3.geoPath().projection(projection).context(context);
    const sphere = { type: "Sphere" };

    const highlightedCountries = [
      "Ghana",
      "Zambia",
      "Mali",
      "Malawi",
      "Cameroon",
      "Zimbabwe",
      "Gambia",
      "Nigeria",
      "Togo",
      "Tanzania",
      "Benin",
      "Burkina Faso",
      "Sierra Leone",
      "Rwanda",
      "Senegal",
      "Liberia",
      "Kenya",
      "United Kingdom",
      "Côte d'Ivoire",
      "Uganda",
      "Gabon",
      "Guinea",
      "Central African Rep.",
      "Botswana",
      "Chad",
      "Burundi",
      "Congo",
      "S. Sudan",
      "South Africa",
      "Somaliland",
      "Morocco",
      "Niger",
      "Eq. Guinea",
      "Egypt"
     ]
     ;

    function renderLand(landData: any) {
      if (!context) return;
      context.clearRect(0, 0, width, height);

      context.save();
      context.filter = "blur(5px)";
      context.beginPath();
      path({ type: "Sphere" });
      context.fillStyle = "#f8f9fa";
      context.fill();
      context.restore();

      context.beginPath();
      path(landData);
      context.fillStyle = "#DBDBDB";
      context.fill();

      context.beginPath();
      path({ type: "Sphere" });
      context.strokeStyle = 'silver';
      context.stroke();
    }

    function handleDrag(event: d3.D3DragEvent<HTMLCanvasElement, any>) {
      const rotate = projection.rotate();
      const dx = event.dx / width;
      const dy = event.dy / height;
      const newRotate: [number, number] = [
        rotate[0] + dx * 360,
        rotate[1] - dy * 360,
      ];

      projection.rotate(newRotate);
      animate();
    }

    function renderCountries(countriesData: any) {
      if (!context) return;
      countriesData.features.forEach((feature: any) => {
        const countryName = feature.properties.NAME;
        context.beginPath();
        path(feature);
        if (highlightedCountries.includes(countryName)) {
          context.fillStyle = "#f09020";
          context.fill();
          context.strokeStyle = "white";
        } else {
          context.strokeStyle = "white";
        }
        context.lineWidth = 1;
        context.stroke();
      });
    }

    function animate() {
      renderLand(land50);
      d3.json(worldCountries).then((data) => {
        renderCountries(data);
      });
    }

    animate(); // Initial render

    const dragHandler = d3
      .drag<HTMLCanvasElement, any>()
      .on("drag", handleDrag);
    d3.select(canvas).call(dragHandler);

    // Add mouse move event listener
    d3.select(canvas).on("mousemove", (event) => {
      const [x, y] = d3.pointer(event);
      const country = findCountryAtPoint(x, y);
      if (country) {
        setTooltip({ x, y, text: country.properties.NAME });
      } else {
        setTooltip({ x: 0, y: 0, text: '' });
      }
    });

    // Add mouse out event listener to hide the tooltip
    d3.select(canvas).on("mouseout", () => {
      setTooltip({ x: 0, y: 0, text: '' });
    });

    // Function to find the country at a given point
    function findCountryAtPoint(x: number, y: number): CountryFeature | null {
      // Implement logic to find the country at the given point
      // This might involve using d3.geoContains or similar
      // For simplicity, this function is left as a placeholder
      return null;
    }

    return () => {
      // Clean-up logic if needed
    };
 }, []);

 return (
    <div>
      <canvas ref={canvasRef} width={width} height={height} className="canvas-for-globe" />
      {tooltip.text && <Tooltip x={tooltip.x} y={tooltip.y} text={tooltip.text} />}
    </div>
 );
};

// export const D3Globe = () => {
//   const canvasRef = useRef<HTMLCanvasElement>(null);
//   const width = 800;
//   const height = 600;

//   useEffect(() => {
//     const canvas = canvasRef.current;
//     const context = canvas?.getContext("2d"); // Use optional chaining

//     if (!context) return;

//     const projection = d3.geoOrthographic().translate([width / 2, height / 2]);
//     const path = d3.geoPath().projection(projection).context(context);
//     const sphere = { type: "Sphere" };

//     function renderLand(landData: any) {
//       if (!context) return;
//       context.clearRect(0, 0, width, height);
//       context.beginPath();
//       path(sphere);
//       context.fillStyle = "#fff";
//       context.fill();

//       context.beginPath();
//       path(landData);
//       context.fillStyle = "#ccc";
//       context.fill();

//       context.beginPath();
//       path(sphere);
//       context.strokeStyle = '#f0f0f0';
//       context.stroke();
  
//       // Pinpoint specific countries
//       const countries = {
//         "Ghana": [[-3.244, 5.024], [-1.060, 5.024], [-1.060, 11.111], [-3.244, 11.111], [-3.244, 5.024]],
//         "Cote d'Ivoire": [[-8.600, 4.360], [-2.490, 4.360], [-2.490, 10.740], [-8.600, 10.740], [-8.600, 4.360]],
//         "Uganda": [[29.585, -1.472], [35.009, -1.472], [35.009, 4.224], [29.585, 4.224], [29.585, -1.472]],
//         "Sierra Leone": [[-13.297, 6.762], [-10.265, 6.762], [-10.265, 10.0], [-13.297, 10.0], [-13.297, 6.762]],
//         "Zambia": [[21.999, -18.076], [33.705, -18.076], [33.705, -8.275], [21.999, -8.275], [21.999, -18.076]],
//         "Rwanda": [[28.850, -2.840], [30.900, -2.840], [30.900, -1.050], [28.850, -1.050], [28.850, -2.840]]
//       };
      
//     //   const dotRadius = 5; 
//       const pulseRadius =3; // Define pulse radius here
//       const pulseSpeed = 0.005;
//       let animationTime = 0.005;

//       function animatePulse() {
//         if (!context) return;
//         animationTime += pulseSpeed;

//         Object.values(countries).forEach((coordinates: any) => {
//           const avgLongitude = coordinates.reduce((sum, coord) => sum + coord[0], 0) / coordinates.length;
//           const avgLatitude = coordinates.reduce((sum, coord) => sum + coord[1], 0) / coordinates.length;
//           const [x, y] = projection([avgLongitude, avgLatitude]);
          
//           const scaleFactor = 1 + Math.sin(animationTime) * 0.2; // Adjust pulse effect here
//           const dotRadius = pulseRadius * scaleFactor;
//           const borderThickness = 2 + Math.sin(animationTime) * 1.5; // Adjust border thickness for pulsation

//           // Draw border with pulsating effect
//           context.beginPath();
//           context.arc(x, y, dotRadius + borderThickness, 0, Math.PI * 2);
//           context.strokeStyle = "#fff"; // Red color for the border
//           context.lineWidth = borderThickness; // Adjust border thickness
//           context.stroke();

//           // Draw filled circle
//           context.beginPath();
//           context.arc(x, y, dotRadius, 0, Math.PI * 2);
//           context.fillStyle = "#f79411"; // Red color for the country dots
//           context.fill();
//         });

//         setTimeout(animatePulse, 1000);
//       }

//       animatePulse();
//     }

//     function handleDrag(event: d3.D3DragEvent<HTMLCanvasElement, any>) {
//       const rotate = projection.rotate();
//       const dx = event.dx / width;
//       const dy = event.dy / height;
//       const newRotate: [number, number] = [
//         rotate[0] + dx * 360,
//         rotate[1] - dy * 360,
//       ];

//       projection.rotate(newRotate);
//       renderLand(land50);
//     }

//     renderLand(land50);

//     const dragHandler = d3
//       .drag<HTMLCanvasElement, any>()
//       .on("drag", handleDrag);
//     d3.select(canvas).call(dragHandler);

//     return () => {
//       // Clean-up logic if needed
//     };
//   }, []);

//   return <canvas ref={canvasRef} width={width} height={height} />;
// };



