import "./virtualcard.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { createMedia } from "@artsy/fresnel";
import Desktop_virtualcard_view from "../virtual_cards_device_view/desktop_view";
import Tablet_virtualcard_view from "../virtual_cards_device_view/tablet_view";
import Mobile_virtualcard_view from "../virtual_cards_device_view/mobile_view";
import Small_virtualcard_view from "../virtual_cards_device_view/small_view";

function VirtualReality() {
  const { MediaContextProvider, Media } = createMedia({
    breakpoints: {
      xs:0,
      sm: 400,
      md: 998,
      lg: 1403,
      xl: 2065,
    },
  });

  return (
    <div className="mt-3 ">
      <MediaContextProvider>
      <Media at="xs">
          <Small_virtualcard_view />
        </Media>
        <Media at="sm">
          <Mobile_virtualcard_view />
        </Media>
        <Media at="md">
          <Tablet_virtualcard_view />
        </Media>
        <Media greaterThanOrEqual="lg">
          <Desktop_virtualcard_view />
        </Media>
      </MediaContextProvider>
    </div>
  );
}

export default VirtualReality;
