import { useState, useEffect } from "react";
import Navbars from "../navbar/Navbars";
import axios from "axios";
import * as ReactBootstrap from "react-bootstrap";
import ReactPaginate from "react-paginate";
import Footer from "../Footer";
import { useTranslation } from "react-i18next";
import ContentShimmer from "react-content-shimmer";
import { Container } from "react-bootstrap";


interface dataType {
  id?: number;
  title?: title;
  content: content;
  date?: string;
  better_featured_image: better_featured_image;
}
interface title {
  rendered: string;
}

interface content {
  rendered: string;
}

interface better_featured_image {
  source_url: any;
}

function MoreNews() {
  const { t } = useTranslation();

  const [newsData, setNewsData] = useState<dataType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [pageCount, setpageCount] = useState(0);
  let limit = 4;

  const fetch = async () => {
    try {
      const data = await axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/${t(
            "latest_news"
          )}/?per_page=${limit}&page=1`,
          {
            headers: {
              // "ngrok-skip-browser-warning": "69420",
              //         Authorization: `Bearer ${process.env.AUTH_TOKEN}`,
            },
          }
        )
        .then((res) => {
          const total = res.headers["x-wp-total"];
          let num = Number(total);
          setpageCount(Math.ceil(num / limit));
          setNewsData(res.data);
          // console.log(res.headers['x-wp-total'])
        });
      setLoading(true);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchpage = async (currentPage) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/${t(
          "latest_news"
        )}/?per_page=${limit}&page=${currentPage}`,
        {
          headers: {
            // "ngrok-skip-browser-warning": "69420",
            //         Authorization: `Bearer ${process.env.AUTH_TOKEN}`,
          },
        }
      );
      return res.data;
    } catch (e) {
     console.log(e)
    }
  };

  const handlePageClick = async (data) => {
    console.log(data.selected);

    let currentPage = data.selected + 1;

    const showPage = await fetchpage(currentPage);

    if (showPage) {
      setNewsData(showPage);
    }
    // scroll to the top
    //window.scrollTo(0, 0)
  };

  useEffect(() => {
    fetch();
  });
  
  return (
    <>
      <div>
        <Navbars />
        {loading ? (
          <>
            {newsData.map((item, key) => (
              <div className="container py-5 px-5  animate__animated animate__fadeInRight">
                <div className="row mb-3">
                  <div className="col-lg-8 mx-auto">
                    <div className="lc-block mb-4">
                      <h3 className="fw-semibold display-2">
                        {item.title?.rendered}
                        {/* {JSON.stringify(newsData!.title!.rendered)} */}
                      </h3>
                    </div>
                    <img
                      width="100%"
                      height="450px"
                      style={{ objectFit: "cover" }}
                      src={item.better_featured_image.source_url}
                      className="d-block w-100 rounded-4"
                      alt="..."
                    />
                    <div className="lc-block">
                      <p
                        className="lead mt-3 fs-6 justify fw-normal"
                        dangerouslySetInnerHTML={{
                          __html: item.content.rendered,
                        }}
                        style={{fontFamily:"Times New Roman"}}
                      />

                      <span className="fs-6"> {item.date}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center bgActive"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link "}
              previousClassName={"page-item text-muted "}
              previousLinkClassName={"page-link text-muted"}
              nextClassName={"page-item text-muted"}
              nextLinkClassName={"page-link text-muted"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
            <Footer onLanguageChange={(v) => fetch()} />
          </>
        ) : (
          <>
            <div
              style={{ width: "100%", height: "100%", borderRadius: "20px" }}
            >
              {" "}
              <div>
                <div style={{ width: "100%" }}>
                  {/* <div className="p-2 lign-items-center "> */}
                  <div
                    style={{
                      width: "100%",
                      borderRadius: "",
                      height: "100vh",
                    }}
                  >
                    <Container>
                      <div className="px-5 py-5">
                        <div className="row mb-3">
                          <div className="col-lg-8 mx-auto">
                            <div className="d-inline">
                              <div className="px-2 mt-3">
                                <ContentShimmer
                                  style={{
                                    marginTop: "1rem",
                                    width: "40%",
                                    height: "25px",
                                  }}
                                  rounded={"10px"}
                                />
                                <ContentShimmer
                                  style={{
                                    marginTop: "1rem",
                                    width: "60%",
                                    height: "25px",
                                  }}
                                  rounded={"10px"}
                                />
                                <ContentShimmer
                                  style={{
                                    marginTop: "1rem",
                                    width: "w-100",
                                    height: "25px",
                                  }}
                                  rounded={"10px"}
                                />
                              </div>
                              <div className="px-2 mt-3">
                                <div>
                                  <ContentShimmer
                                    style={{ width: "100%", height: "400px" }}
                                    rounded="5%"
                                  />
                                </div>
                              </div>
                              <div className="px-2 mt-3">
                                <ContentShimmer
                                  style={{
                                    marginTop: "1rem",
                                    width: "w-100",
                                    height: "10px",
                                  }}
                                  rounded={"10px"}
                                />
                                <ContentShimmer
                                  style={{
                                    marginTop: "1rem",
                                    width: "w-100",
                                    height: "10px",
                                  }}
                                  rounded={"10px"}
                                />
                                <ContentShimmer
                                  style={{
                                    marginTop: "1rem",
                                    width: "w-100",
                                    height: "10px",
                                  }}
                                  rounded={"10px"}
                                />
                                <ContentShimmer
                                  style={{
                                    marginTop: "1rem",
                                    width: "w-100",
                                    height: "10px",
                                  }}
                                  rounded={"10px"}
                                />
                                <ContentShimmer
                                  style={{
                                    marginTop: "1rem",
                                    width: "w-100",
                                    height: "10px",
                                  }}
                                  rounded={"10px"}
                                />
                                <ContentShimmer
                                  style={{
                                    marginTop: "1rem",
                                    width: "w-100",
                                    height: "10px",
                                  }}
                                  rounded={"10px"}
                                />
                                <ContentShimmer
                                  style={{
                                    marginTop: "1rem",
                                    width: "w-100",
                                    height: "10px",
                                  }}
                                  rounded={"10px"}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Container>
                  </div>

                  <div className=" h-100">
                    <div className="position-absolute top-50 start-50 translate-middle">
                      <div className="">
                        <ReactBootstrap.Spinner animation="border" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default MoreNews;
