import { useState, useEffect } from "react";
import Navbars from "../navbar/Navbars";
import axios from "axios";
import { useParams } from "react-router-dom";
import * as ReactBootstrap from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import ContentShimmer from "react-content-shimmer";

interface dataType {
  id?: number;
  title?: title;
  content?: content;
  acf?: acf;
  // date?: string;
  better_featured_image: better_featured_image;
}
interface title {
  rendered: string;
}
interface acf {
  date: any;
  news_content: string;
}

interface content {
  rendered: string;
}

interface better_featured_image {
  source_url: any;
}

function News() {
  const { t } = useTranslation();
  const [newsData, setNewsData] = useState<dataType>();
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = useParams();

  const fetch = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/${t("latest_news")}/${id}`,
        {
          headers: {
            // "ngrok-skip-browser-warning": "69420",
            //         Authorization: `Bearer ${process.env.AUTH_TOKEN}`,
          },
        }
      );
      setNewsData(data);
      setLoading(true);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetch();
  });

  return (
    <div>
      <Navbars />
      {loading ? (
        <>
          <div className="container py-5 px-5">
            <div className="row mb-3">
              <div className="col-lg-8 mx-auto">
                <div className="lc-block mb-4">
                  <h3 className="fw-semibold display-2 animate__animated animate__fadeInRight animate__delay-1s">
                    {newsData && newsData.title?.rendered}
                    {/* {JSON.stringify(newsData!.title!.rendered)} */}
                  </h3>
                </div>
                <img
                  src={newsData && newsData.better_featured_image.source_url}
                  //  width="800px"
                  //  height="450px"
                  className="d-block w-100 rounded-4 animate__animated animate__fadeInRight animate__delay-2s"
                  alt="..."
                  style={{ objectFit: "cover", width: "100%", height: "450px" }}
                />
                <div className="lc-block animate__animated animate__fadeInRight animate__delay-3s">
                  <p
                    className="lead mt-3 justify fs-6 "
                    dangerouslySetInnerHTML={{
                      __html: `${newsData && newsData.content?.rendered}`,
                    }}
                    style={{fontFamily:"Times New Roman"}}
                  />

                  {/* {" "}
                   {newsData && newsData.acf?.news_content}
                 </p> */}
                  <span className="fs-6 text-muted">
                    {" "}
                    {newsData && newsData.acf?.date}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* <Footer onLanguageChange={(v) => fetch()}/> */}
        </>
      ) : (
        <>
          <div style={{ width: "100%", height: "100%", borderRadius: "20px" }}>
            {" "}
            <div>
              <div style={{ width: "100%" }}>
                {/* <div className="p-2 lign-items-center "> */}
                <div
                  style={{
                    width: "100%",
                    borderRadius: "",
                    height: "100vh",
                  }}
                >
                  <Container>
                    <div className="px-5 py-5">
                      <div className="row mb-3">
                        <div className="col-lg-8 mx-auto">
                          <div className="d-inline">
                            <div className="px-2 mt-3">
                              <ContentShimmer
                                style={{
                                  marginTop: "1rem",
                                  width: "40%",
                                  height: "25px",
                                }}
                                rounded={"10px"}
                              />
                              <ContentShimmer
                                style={{
                                  marginTop: "1rem",
                                  width: "60%",
                                  height: "25px",
                                }}
                                rounded={"10px"}
                              />
                              <ContentShimmer
                                style={{
                                  marginTop: "1rem",
                                  width: "w-100",
                                  height: "25px",
                                }}
                                rounded={"10px"}
                              />
                            </div>
                            <div className="px-2 mt-3">
                              <div>
                                <ContentShimmer
                                  style={{ width: "100%", height: "400px" }}
                                  rounded="5%"
                                />
                              </div>
                            </div>
                            <div className="px-2 mt-3">
                              <ContentShimmer
                                style={{
                                  marginTop: "1rem",
                                  width: "w-100",
                                  height: "10px",
                                }}
                                rounded={"10px"}
                              />
                              <ContentShimmer
                                style={{
                                  marginTop: "1rem",
                                  width: "w-100",
                                  height: "10px",
                                }}
                                rounded={"10px"}
                              />
                              <ContentShimmer
                                style={{
                                  marginTop: "1rem",
                                  width: "w-100",
                                  height: "10px",
                                }}
                                rounded={"10px"}
                              />
                              <ContentShimmer
                                style={{
                                  marginTop: "1rem",
                                  width: "w-100",
                                  height: "10px",
                                }}
                                rounded={"10px"}
                              />
                              <ContentShimmer
                                style={{
                                  marginTop: "1rem",
                                  width: "w-100",
                                  height: "10px",
                                }}
                                rounded={"10px"}
                              />
                              <ContentShimmer
                                style={{
                                  marginTop: "1rem",
                                  width: "w-100",
                                  height: "10px",
                                }}
                                rounded={"10px"}
                              />
                              <ContentShimmer
                                style={{
                                  marginTop: "1rem",
                                  width: "w-100",
                                  height: "10px",
                                }}
                                rounded={"10px"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>

                <div className=" h-100">
                  <div className="position-absolute top-50 start-50 translate-middle">
                    <div className="">
                      <ReactBootstrap.Spinner animation="border" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default News;
