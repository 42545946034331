import Navbars from "../components/navbar/Navbars";
import "../styles/ourproduct.css";
import { Mail } from "../../core/resources/assets/index";
import { Col, Container, Row, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";

import CircleGhFlag from "../../core/resources/assets/images/circlegh.png";
import CircleZmFlag from "../../core/resources/assets/images/circlezambia.png";
import CircleCtFlag from "../../core/resources/assets/images/circlecote.png";
import CircleSlFlag from "../../core/resources/assets/images/circlesierre.png";
import CircleUgFlag from "../../core/resources/assets/images/circleuganda.png";
import CircleRwFlag from "../../core/resources/assets/images/circlerwanda.png";

import Logo1 from "../../core/resources/assets/logos/msecb.png";
import Logo2 from "../../core/resources/assets/logos/bkgh.png";
import Logo3 from "../../core/resources/assets/logos/bkzm.png";
import Logo4 from "../../core/resources/assets/logos/PCIDSS.png";
import { useState } from "react";
import { CountriesForFooter } from "../components/countries_for_footer";


function OurPRODUCTS() {
  const { t } = useTranslation();

  const [isThirdPartyHovered, setIsThirdPartyHovered] = useState(false);

  const handleThirdPartyHover = () => {
    setIsThirdPartyHovered(true);
  };

  const handleThirdPartyLeave = () => {
    setIsThirdPartyHovered(false);
  };


  return (
    <div>
      <Navbars />
      <Container>
        <Row className="mb-4 justify-content-center  mt-5">
          <Col md={9} lg={6}>
            <div className="lc-block text-center">
              <div>
                <p className="lead fw-bold fs-3">
                  {t("Expanding_businesses_into multiple_African_Markets!")}
                  <br></br>
                  <span className="lead fw-bold fs-3" >
                    {t("African_Markets!")}
                  </span>
                </p>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="gx-5 px-4">
          <Col className="mb-4 mx-xs-5 p-4 anim third-party"  >
            <Row>
              <Col md={12} lg={12} xl={3}>
                <div className="lc-block mt-4">
                  <div style={{ height: "auto" }}>
                    <h4 className="fw-bold"> {t("Third_Party_Payment_Processing")}{" "}</h4>
                    <p
                      className="text-mute fw-normal"
                      style={{ fontSize: "0.8rem" }}
                    >
                      {t("Third_Party_Payment_Processing_content")}
                    </p>
                  </div>

                </div>
              </Col>
              <Col md={12} lg={12} xl={9} >
                <Row className={`py-2 px-md-4 px-sm-0 row-border `}>
                  <Col md={4} xl={4} className=" py-md-4 p-sm-0">
                    <div className="lc-block">
                      <div >
                        <h5 className="fs-6 fw-semibold">
                          {" "}
                          {t("Issuer_Processor")}{" "}
                        </h5>
                        <p className="text-muted" style={{ fontSize: "0.8rem" }}>
                          {t("Issuer_Processor_content")}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col md={4} xl={4} className=" p-md-4 p-sm-0">
                    <div className="lc-block">
                      <div>
                        <h5 className="fs-6 fw-semibold">
                          {" "}
                          {t("Acquirer_Processor")}
                        </h5>
                        <p className="text-muted" style={{ fontSize: "0.8rem" }}>
                          {t("Acquirer_Processor_content")}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col md={4} xl={4} className="p-md-4 p-sm-0">
                    <div className="lc-block">
                      <div>
                        <h5 className="fs-6 fw-semibold"> {t("Terminals")}</h5>
                        <p
                          className="text-muted fw-normal"
                          style={{ fontSize: "0.8rem" }}
                        >
                          {t("Terminals_content")}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <a href="mailto:sales@nsano.com">
              <img className="mail" src={Mail} alt="" />
              <button className="btn-product ">
                {t("Contact_Sales")}
              </button>
            </a>
          </Col>

        </Row>
        <Row className="gx-5 px-4">

          <Col md={6} xl={4} className="col-12 mb-4 p-4 anim ">
            <div className="lc-block">
              <div style={{ height: "160px" }}>
                <h4 className="fw-bold">{t("Bulk_Payment_Solutions")}</h4>
                <p
                  className="text-mute fw-normal"
                  style={{ fontSize: "0.8rem" }}
                >
                  {t("Bulk_Payment_Solutions_content")}
                </p>
              </div>
              <a href="mailto:sales@nsano.com">
                <img className="mail" src={Mail} alt="" />
                <button className="btn-product my-3">
                  {t("Contact_Sales")}
                </button>
              </a>
            </div>
          </Col>
          <Col md={6} xl={4} className=" mb-4 p-4 anim">
            <div className="lc-block">
              <div style={{ height: "160px" }}>
                <h4 className="fw-bold">{t("Mobile_Money_Aggregation")}</h4>
                <p
                  className="text-mute fw-normal"
                  style={{ fontSize: "0.8rem" }}
                >
                  {t("Mobile_Money_Aggregation_content")}
                </p>
              </div>
              <a href="mailto:sales@nsano.com">
                <img className="mail" src={Mail} alt="" />
                <button className="btn-product ">
                  {t("Contact_Sales")}
                </button>
              </a>
            </div>
          </Col>
          <Col md={6} xl={4} sm={12} className=" mb-4 p-4 anim">
            <div className="lc-block">
              <div style={{ height: "160px" }}>
                <h4 className="fw-bold">{t("Remittance")}</h4>
                <p
                  className="text-mute fw-normal"
                  style={{ fontSize: "0.8rem" }}
                >
                  {t("Remittance_content")}
                </p>
              </div>
              <a href="mailto:sales@nsano.com">
                <img className="mail" src={Mail} alt="" />
                <button className="btn-product ">
                  {t("Contact_Sales")}
                </button>
              </a>
            </div>
          </Col>


        </Row>
      </Container>
      <Footer>
        <div className="">
          <div className=" btn-footer-regulators ">
            <div className="d-flex items-align-center">
              <div className=""><Image src={Logo1} className="" style={{ height: "30px", width: "30px", objectFit: "contain" }} /></div>
              <div ><Image src={Logo2} className="" style={{ height: "30px", width: "50px", objectFit: "contain" }} /></div>
              <div className=""><Image src={Logo3} className="" style={{ height: "30px", width: "40px", objectFit: "contain" }} /></div>
              <div className=""><Image src={Logo4} className="" style={{ height: "30px", width: "50px", objectFit: "contain" }} /></div>
            </div>
          </div>
        </div>

        <CountriesForFooter />
      </Footer>
    </div>
  );
}

export default OurPRODUCTS;
