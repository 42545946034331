import { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../styles/blog.css";
import axios from "axios";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Button, Card, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Pagination, Navigation, Mousewheel } from "swiper";
import "./swiper_pagination_style_sif.css";
import Modal from "react-bootstrap/Modal";
import ReactPlayer from "react-player/youtube";
import "../../styles/blog.css";
import "swiper/swiper-bundle.css";
import ContentShimmer from "react-content-shimmer";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { spawn } from "child_process";

interface dataType {
  id: id;
  title: title;
  content: content;
  acf: acf;
  better_featured_image: better_featured_image;
}
interface title {
  rendered: string;
}
interface id {
  id: number;
}
interface content {
  rendered: string;
}
interface acf {
  staff: string;
  video: string;
  staff_content: any;
}
interface better_featured_image {
  media_details: media_details;
}
interface media_details {
  sizes: sizes;
}
interface sizes {
  thumbnail: thumbnail;
}

interface thumbnail {
  source_url: any;
}

function Staff_infocus_desktop_view() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };

  const [data, setData] = useState<dataType[]>([]);
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);

  const pagination = {
    clickable: true,
    dynamicBullets: true,
  };

  const fetch = async () => {
    try {
      const data = await axios
        .get(`${process.env.REACT_APP_BASE_URL}/${t("staffs_in_focus")}`, {
          headers: {
            // "ngrok-skip-browser-warning": "69420",
            //         Authorization: `Bearer ${process.env.AUTH_TOKEN}`,
          },
        })
        .then((res) => {
          setData(res.data);
        });
      setLoading(true);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetch();
  }, []);



  const [vid, setVid] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    var source = e.target.id;
    setVid(source);
    setShow(true);
  };

  

 
  return (
    <>
      {loading ? (
        <div>
          <Container>
            <div className="fs-1 my-4 fw-semibold d-flex justify-content-between content">
              <div>{t("focus")}</div>
            </div>
          </Container>

          <Swiper
            slidesPerView={9}
            spaceBetween={1}
            centeredSlides={true}
            pagination={pagination}
            modules={[Pagination, Mousewheel]}
            direction={"horizontal"}
            // navigation={true}
            mousewheel={{
              forceToAxis: true,
            }}
            className="mySwiper"
            style={{
              marginLeft: "-950px",
              height: "auto",
              paddingBottom: "5%",
            }}
          >
            {data.map((item, key) => {


              return (
                <SwiperSlide key={key}>
                  <Card
                    className={
                      ((key + 1) % 2 == 0 ? `bg-image` : `bg-white`) +
                      " p-3 border-0"
                    }
                    style={{
                      width: "90%",
                      borderRadius: "20px ",
                      minHeight: "450px",
                    }}
                  >
                    <Card.Header className="bg-transparent border-0">
                      <Card.Title
                        className="d-flex bg-transparent"
                        style={{ height: "60px" }}
                      >
                        <div
                          className=" m-0 p-0"
                          style={{ width: "60px", height: "100%" }}
                        >
                          <img
                            src={
                              item.better_featured_image.media_details.sizes
                                .thumbnail.source_url
                            }
                            alt=""
                            className="img-thumbnail rounded-circle"
                            style={{
                              objectFit: "cover",
                              maxWidth: "60px",
                              height: "100%",
                            }}
                          />
                        </div>

                        <div
                          className="px-2 mt-3 text-uppercase fw-semibold"
                          style={{ fontSize: "10px" }}
                        >
                          {item.acf.staff}
                        </div>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body className="mb-2" style={{ height: "auto" }}>

                      <div style={{ fontSize: "10px !important" }}>
                        <ReactReadMoreReadLess
                          charLimit={200}
                          readMoreText={"Read more ▼"}
                          readLessText={"Read less ▲"}
                          readMoreClassName="read-more-less--more"
                          readLessClassName="read-more-less--less"
                          className="read"
                        >
                          {item?.acf?.staff_content}
                        </ReactReadMoreReadLess>
                      </div>

                      <div className="lc-block d-flex list-unstyled mt-auto ms-auto"></div>
                    </Card.Body>
                    <Card.Footer className="border-0 bg-transparent">
                      <div className="btn btn-link btn-sm " role="button">
                        <Button
                          className="btn-new text-muted"
                          variant="primary"
                          style={{
                            border: "2px solid silver",
                            borderRadius: "30px",
                            backgroundColor: "transparent",
                            color: "#358ED7",
                            fontWeight: "500",
                            fontSize: "12px",
                          }}
                          onClick={handleShow}
                          id={item.acf.video}
                        >
                          Watch Video
                          {/* </a> */}
                        </Button>
                      </div>
                    </Card.Footer>
                  </Card>
                </SwiperSlide>
              );
            })}
          </Swiper>

          <Modal
            show={show}
            onHide={() => setShow(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            style={{ backdropFilter: "blur(8px)" }}
          >
            <Modal.Header closeButton className="border-0"></Modal.Header>
            <Modal.Body className="mx-auto mb-5">
              <ReactPlayer url={vid} control={true}  width="1000px"
            height="500px"/>
            </Modal.Body>
          </Modal>
        </div>
      ) : (
        <div>
          <Container>
            <div className="d-flex">
              <div>
                <div className="d-flex">
                  <div className="p-2 d-flex align-items-center">
                    <div
                      className="bg-light"
                      style={{
                        width: "300px",
                        borderRadius: "20px",
                        height: "400px",
                      }}
                    >
                      <div className="p-2 mt-4 d-flex align-items-center ">
                        <ContentShimmer
                          size={{ height: 60, width: 60 }}
                          rounded="50%"
                        />
                        <div className="px-3">
                          <ContentShimmer
                            style={{ marginBottom: "1rem" }}
                            size={{ height: 15, width: 150 }}
                          />
                          {/* <ContentShimmer size={{ height: 15, width: 100 }} /> */}
                        </div>
                      </div>
                      <div className="px-4">
                        <ContentShimmer
                          style={{ marginTop: "1rem" }}
                          rounded={"10px"}
                          size={{ height: 15, width: 250 }}
                        />
                        <ContentShimmer
                          style={{ marginTop: "1rem" }}
                          rounded={"10px"}
                          size={{ height: 15, width: 250 }}
                        />
                        <ContentShimmer
                          style={{ marginTop: "1rem" }}
                          rounded={"10px"}
                          size={{ height: 15, width: 250 }}
                        />
                        <ContentShimmer
                          style={{ marginTop: "1rem" }}
                          rounded={"10px"}
                          size={{ height: 15, width: 250 }}
                        />
                        <ContentShimmer
                          style={{ marginTop: "1rem" }}
                          rounded={"10px"}
                          size={{ height: 15, width: 250 }}
                        />
                      </div>
                      <div className="px-4 mt-5">
                        <ContentShimmer
                          style={{ marginTop: "1rem" }}
                          rounded={"50px"}
                          size={{ height: 45, width: 100 }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex">
                  <div className="p-2 d-flex align-items-center">
                    <div
                      className="bg-light"
                      style={{
                        width: "300px",
                        borderRadius: "20px",
                        height: "400px",
                      }}
                    >
                      <div className="p-2 mt-4 d-flex align-items-center ">
                        <ContentShimmer
                          size={{ height: 60, width: 60 }}
                          rounded="50%"
                        />
                        <div className="px-3">
                          <ContentShimmer
                            style={{ marginBottom: "1rem" }}
                            size={{ height: 15, width: 150 }}
                          />
                          {/* <ContentShimmer size={{ height: 15, width: 100 }} /> */}
                        </div>
                      </div>
                      <div className="px-4">
                        <ContentShimmer
                          style={{ marginTop: "1rem" }}
                          rounded={"10px"}
                          size={{ height: 15, width: 250 }}
                        />
                        <ContentShimmer
                          style={{ marginTop: "1rem" }}
                          rounded={"10px"}
                          size={{ height: 15, width: 250 }}
                        />
                        <ContentShimmer
                          style={{ marginTop: "1rem" }}
                          rounded={"10px"}
                          size={{ height: 15, width: 250 }}
                        />
                        <ContentShimmer
                          style={{ marginTop: "1rem" }}
                          rounded={"10px"}
                          size={{ height: 15, width: 250 }}
                        />
                        <ContentShimmer
                          style={{ marginTop: "1rem" }}
                          rounded={"10px"}
                          size={{ height: 15, width: 250 }}
                        />
                      </div>
                      <div className="px-4 mt-5">
                        <ContentShimmer
                          style={{ marginTop: "1rem" }}
                          rounded={"50px"}
                          size={{ height: 35, width: 100 }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="d-flex">
                  <div className="p-2 d-flex align-items-center">
                    <div
                      className="bg-light"
                      style={{
                        width: "300px",
                        borderRadius: "20px",
                        height: "400px",
                      }}
                    >
                      <div className="p-2 mt-4 d-flex align-items-center ">
                        <ContentShimmer
                          size={{ height: 60, width: 60 }}
                          rounded="50%"
                        />
                        <div className="px-3">
                          <ContentShimmer
                            style={{ marginBottom: "1rem" }}
                            size={{ height: 15, width: 150 }}
                          />
                          {/* <ContentShimmer size={{ height: 15, width: 100 }} /> */}
                        </div>
                      </div>
                      <div className="px-4">
                        <ContentShimmer
                          style={{ marginTop: "1rem" }}
                          rounded={"10px"}
                          size={{ height: 15, width: 250 }}
                        />
                        <ContentShimmer
                          style={{ marginTop: "1rem" }}
                          rounded={"10px"}
                          size={{ height: 15, width: 250 }}
                        />
                        <ContentShimmer
                          style={{ marginTop: "1rem" }}
                          rounded={"10px"}
                          size={{ height: 15, width: 250 }}
                        />
                        <ContentShimmer
                          style={{ marginTop: "1rem" }}
                          rounded={"10px"}
                          size={{ height: 15, width: 250 }}
                        />
                        <ContentShimmer
                          style={{ marginTop: "1rem" }}
                          rounded={"10px"}
                          size={{ height: 15, width: 250 }}
                        />
                      </div>
                      <div className="px-4 mt-5">
                        <ContentShimmer
                          style={{ marginTop: "1rem" }}
                          rounded={"50px"}
                          size={{ height: 35, width: 100 }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="d-flex">
                  <div className="p-2 d-flex align-items-center">
                    <div
                      className="bg-light"
                      style={{
                        width: "300px",
                        borderRadius: "20px",
                        height: "400px",
                      }}
                    >
                      <div className="p-2 mt-4 d-flex align-items-center ">
                        <ContentShimmer
                          size={{ height: 60, width: 60 }}
                          rounded="50%"
                        />
                        <div className="px-3">
                          <ContentShimmer
                            style={{ marginBottom: "1rem" }}
                            size={{ height: 15, width: 150 }}
                          />
                          {/* <ContentShimmer size={{ height: 15, width: 100 }} /> */}
                        </div>
                      </div>
                      <div className="px-4">
                        <ContentShimmer
                          style={{ marginTop: "1rem" }}
                          rounded={"10px"}
                          size={{ height: 15, width: 250 }}
                        />
                        <ContentShimmer
                          style={{ marginTop: "1rem" }}
                          rounded={"10px"}
                          size={{ height: 15, width: 250 }}
                        />
                        <ContentShimmer
                          style={{ marginTop: "1rem" }}
                          rounded={"10px"}
                          size={{ height: 15, width: 250 }}
                        />
                        <ContentShimmer
                          style={{ marginTop: "1rem" }}
                          rounded={"10px"}
                          size={{ height: 15, width: 250 }}
                        />
                        <ContentShimmer
                          style={{ marginTop: "1rem" }}
                          rounded={"10px"}
                          size={{ height: 15, width: 250 }}
                        />
                      </div>
                      <div className="px-4 mt-5">
                        <ContentShimmer
                          style={{ marginTop: "1rem" }}
                          rounded={"50px"}
                          size={{ height: 35, width: 100 }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="d-flex">
                  <div className="p-2 d-flex align-items-center">
                    <div
                      className="bg-light"
                      style={{
                        width: "300px",
                        borderRadius: "20px",
                        height: "400px",
                      }}
                    >
                      <div className="p-2 mt-4 d-flex align-items-center ">
                        <ContentShimmer
                          size={{ height: 60, width: 60 }}
                          rounded="50%"
                        />
                        <div className="px-3">
                          <ContentShimmer
                            style={{ marginBottom: "1rem" }}
                            size={{ height: 15, width: 150 }}
                          />
                          {/* <ContentShimmer size={{ height: 15, width: 100 }} /> */}
                        </div>
                      </div>
                      <div className="px-4">
                        <ContentShimmer
                          style={{ marginTop: "1rem" }}
                          rounded={"10px"}
                          size={{ height: 15, width: 250 }}
                        />
                        <ContentShimmer
                          style={{ marginTop: "1rem" }}
                          rounded={"10px"}
                          size={{ height: 15, width: 250 }}
                        />
                        <ContentShimmer
                          style={{ marginTop: "1rem" }}
                          rounded={"10px"}
                          size={{ height: 15, width: 250 }}
                        />
                        <ContentShimmer
                          style={{ marginTop: "1rem" }}
                          rounded={"10px"}
                          size={{ height: 15, width: 250 }}
                        />
                        <ContentShimmer
                          style={{ marginTop: "1rem" }}
                          rounded={"10px"}
                          size={{ height: 15, width: 250 }}
                        />
                      </div>
                      <div className="px-4 mt-5">
                        <ContentShimmer
                          style={{ marginTop: "1rem" }}
                          rounded={"50px"}
                          size={{ height: 35, width: 100 }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      )}
    </>
  );
}
export default Staff_infocus_desktop_view;
