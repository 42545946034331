import BlogSidebar from "../blog-sidebar";
import styles from "./PostDetailsWithSidebar.module.scss";
import { Link } from "react-router-dom";
import HeroCarousel from "./HeroCarousel";
import Experience from "./cards_for_experience";
import VirtualReality from "./virtual_reality_card";
import { Col, Row } from "react-bootstrap";
import LatestNews from "../blog-sidebar/latest_news";
import Footer from "../Footer";
import LatestNewsIndex from "../blog-sidebar/latest_news_index";

const PostDetailsWithSidebar = (sidebarPosition: any) => {
  return (
    <div className="blog__details__area pt--120 pb--110 bg--white mt-5">
      <div className="container">
            <Row>
              <Col>
                <Row className="gy-3 mb-5">
                  <Col md={7} lg={9} sm={12} xs={12}>
                  <HeroCarousel />
                  </Col>
                  <Col>
                  {/* <LatestNews/> */}
                  <LatestNewsIndex/>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="">
              <Col className="mb-4" md={12} lg={8} sm={12} xs={12}>
                <Experience/>
              </Col>
              <Col   className=" m-0 p-0">
                <div className="position-relative top-50 start-50 translate-middle">
                <VirtualReality />

                </div>
              </Col>
            </Row>
                       
          </div>
          </div>
  );
};

export default PostDetailsWithSidebar;
