import React, { useState } from "react";
import { Card, Col, Nav, Navbar, Row, Tab } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Logo from "../../../core/resources/assets/logos/logo.png";
import "../../styles/Navbars.css";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { RiMenu5Fill } from "react-icons/ri";


function NavbarOffCanvas({ name, ...props }) {
  const { t } = useTranslation();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  return (
    <>
      <Button
        variant="light"
        onClick={handleShow}
        className="me-2 mt-4 shadow-lg"
      >
        <RiMenu5Fill />
      </Button>
      <Offcanvas
        show={show}
        onHide={handleClose}
        className=" border border-0"
        style={{
          height: "auto",
          background: "transparent",
          marginRight: "3%",
          borderRadius: "20px",
          marginTop: "3%",
          border: "none",
            // width:"auto"
        }}
        {...props}
      >
       
          <div className="bg-white shadow-lg " style={{ borderRadius: "20px" }}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>
                
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              

              <div
                id="carouselTestimonial"
                className="carousel slide pb-4"
                data-bs-ride="false"
                // data-bs-interval="false"
              >
                <div className="carousel-inner ">
                  <div className="carousel-item active" >
                    <Navbar style={{ marginLeft: "-3%" }}>
                      <ul className="m-0 p-0" id="menu">
                        <li className="menu-item menu-item-type-custom mb-2 list-unstyled menu-item-object-custom nav-item nav-item-32739 px-4">
                          <NavLink to="/why-nsano" className="nav-link"  style={{fontSize:"13px"}}>
                            {t("Why_Nsano")}
                          </NavLink>
                        </li>
                        <li className="menu-item menu-item-type-custom mb-2 list-unstyled menu-item-object-custom menu-item-home nav-item nav-item-32738 px-4 dropdown position-static">
                        
                          <NavLink  to="/our-products" className="nav-link" style={{fontSize:"13px",width:"auto"}}>
                            <span className="mr-4">{t("Our_Products")}</span>
                            <a
                              className="carousel-control-next  position-relative d-inline "
                              href="#carouselTestimonial"
                              data-bs-slide="next"
                            ><button className=" bg-transparent border-0">
                              <svg
                                width="1em"
                                height="1em"
                                viewBox="0 0 16 16"
                                className="text-dark"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                                >
                                  {" "}
                                </path>
                              </svg>
                            </button>
                              
                            </a>
                          </NavLink>

                          {/* </NavLink> */}
                        </li>
                        <li className="menu-item menu-item-type-custom mb-2 list-unstyled menu-item-object-custom menu-item-home nav-item nav-item-32738 px-4">
                          <NavLink to="/our-people" className="nav-link " style={{fontSize:"13px"}}>
                            {t("Our_People")}
                          </NavLink>
                        </li>
                        <li className="menu-item menu-item-type-custom mb-2 list-unstyled menu-item-object-custom menu-item-home nav-item nav-item-32738 px-4">
                          <NavLink to="/blog" className="nav-link "  style={{fontSize:"13px"}}>
                            {t("Blog")}
                          </NavLink>
                        </li>
                        <li className="menu-item menu-item-type-custom mb-2 menu-item-object-custom list-unstyled menu-item-home nav-item nav-item-32738 px-4">
                          <NavLink to="/careers" className="nav-link"  style={{fontSize:"13px"}}>
                            {t("Careers")}
                          </NavLink>
                        </li>
                      </ul>
                      <Nav.Link></Nav.Link>
                    </Navbar>{" "}
                  </div>
                  <div className="carousel-item">
                    {" "}
                    <Card style={{ border: "none", borderRadius: "20px" }}>
                      <Card.Body>
                        <Row className="mb-3 gy-3">
                          <Col
                            md={6}
                            className="bg-white"
                            style={{ fontSize: "12px" }}
                          >
                            <NavLink
                              to="/our-products"
                              className="list-group-item bg-white list-group-item-action border-0 "
                            ><span className="fw-semibold">{t("Third_Party_Payment_Processing")}</span>
                              
                              <br />
                              <span style={{ fontSize: "0.7rem" }} className=" text-muted">
                                {t("Third_Party_Payment_Processing_content")}
                              </span>
                            </NavLink>
                          </Col>
                          <Col md={6} style={{ fontSize: "12px" }}>
                            <NavLink
                              to="/our-products"
                              className="list-group-item bg-white list-group-item-action border-0"
                            ><span className="fw-semibold">
                              {t("Mobile_Money_Aggregation")}
                              </span>
                              <br />
                              <span style={{ fontSize: "0.7rem" }} className=" text-muted">
                                {t("Mobile_Money_Aggregation_content")}
                              </span>
                            </NavLink>
                          </Col>{" "}
                        </Row>
                        <Row className="mb-3 gy-3">
                          <Col md={6} style={{ fontSize: "12px" }}>
                            <NavLink
                              to="/our-products"
                              className="list-group-item bg-white list-group-item-action border-0"
                            ><span className="fw-semibold">
                              {t("Bulk_Payment_Solutions")}
                              </span>
                              <br />
                              <span style={{ fontSize: "0.7rem" }} className=" text-muted">
                                {t("Bulk_Payment_Solutions_content")}
                              </span>
                            </NavLink>
                          </Col>
                          <Col md={6} style={{ fontSize: "12px" }}>
                            <NavLink
                              to="/our-products"
                              className="list-group-item bg-white list-group-item-action border-0"
                            ><span className="fw-semibold">
                              {t("Remittance")}
                              </span>
                              <br />
                              <span style={{ fontSize: "0.7rem" }} className=" text-muted">
                                {t("Remittance_content")}
                              </span>
                            </NavLink>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                    <a
                      className="carousel-control-prev position-relative  d-inline me-4"
                      href="#carouselTestimonial"
                      data-bs-slide="prev"
                    >
                      <button className="bounce bg-transparent border-0">
                      <svg
                        width="30px"
                        height="1em"
                        viewBox="0 0 16 16"
                        className="text-dark"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                        ></path>
                      </svg>
                      <span className="visually-hidden">Previous</span>
                      </button>
                    </a>
                    <a className=" text-decoration-none text-muted position-relative  d-inline "
                      href="#carouselTestimonial"
                      data-bs-slide="prev" style={{fontSize:"12px"}}>{t("Back")}</a>
                  </div>
                </div>

              </div>
            </Offcanvas.Body>
          </div>
      </Offcanvas>
    </>
  );
}

export default NavbarOffCanvas;
