import { useState, useEffect } from "react";
import Navbars from "../navbar/Navbars";
import axios from "axios";
import { Col, Row } from "react-bootstrap";
import { Container } from "react-bootstrap";
import * as ReactBootstrap from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ContentShimmer from "react-content-shimmer";

interface dataType {
  id?: number;
  title?: title;
  content?: content;
  better_featured_image?: better_featured_image;
}

interface title {
  rendered: string;
}

interface content {
  rendered: any;
  protected: boolean;
}

interface better_featured_image {
  source_url: any;
}

function SingleCarousel() {
  const { t } = useTranslation();
  const { id } = useParams();
  const [data, setData] = useState<dataType>();
  const [loading, setLoading] = useState<boolean>(false);

  const fetch = async () => {
    try {
      const {data} = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/${t("blog_carousel")}/${id}`,
        {
          headers: {
            // "ngrok-skip-browser-warning": "69420",
            //         Authorization: `Bearer ${process.env.AUTH_TOKEN}`,
          },
        }
      );
      setData(data);
      setLoading(true);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetch();
  });

  return (
    <div>
      <Navbars />
      {loading ? (
        <>
          <Container className="p-5 ">
            <Row>
              <Col lg={8} className="mx-auto">
                <h3 className="fw-semibold display-2 for-mobile-text animate__animated animate__fadeInRight animate__delay-1s">
                  {data && data.title?.rendered}
                </h3>

                <img
                  src={data && data.better_featured_image?.source_url}
                  width="800px"
                  alt=""
                  height="auto"
                  style={{ objectFit: "cover", objectPosition:"top" }}
                  className="d-block w-100 for-mobile rounded-4 animate__animated animate__fadeInRight animate__delay-2s"
                />
                <div
                  className="mt-4 justify fs-6 animate__animated animate__fadeInRight animate__delay-3s"
                  dangerouslySetInnerHTML={{
                    __html: data && data.content?.rendered,
                  }}
                  style={{fontFamily:"Times New Roman"}}
                />
              </Col>
            </Row>
          </Container>

          {/* <Footer onLanguageChange={(v) => fetch()}/> */}
        </>
      ) : (
        <>
          <div style={{ width: "100%", height: "100%", borderRadius: "20px" }}>
            {" "}
            <div>
              <div style={{ width: "100%" }}>
                {/* <div className="p-2 lign-items-center "> */}
                <div
                  style={{
                    width: "100%",
                    borderRadius: "",
                    height: "100vh",
                  }}
                >
                  <Container>
                    <div className="px-5 py-5">
                      <div className="row mb-3">
                        <div className="col-lg-8 mx-auto">
                          <div className="d-inline">
                            <div className="px-2 mt-3">
                              <ContentShimmer
                                style={{
                                  marginTop: "1rem",
                                  width: "40%",
                                  height: "25px",
                                }}
                                rounded={"10px"}
                              />
                              <ContentShimmer
                                style={{
                                  marginTop: "1rem",
                                  width: "60%",
                                  height: "25px",
                                }}
                                rounded={"10px"}
                              />
                              <ContentShimmer
                                style={{
                                  marginTop: "1rem",
                                  width: "w-100",
                                  height: "25px",
                                }}
                                rounded={"10px"}
                              />
                            </div>
                            <div className="px-2 mt-3">
                              <div>
                                <ContentShimmer
                                  style={{ width: "100%", height: "400px" }}
                                  rounded="5%"
                                />
                              </div>
                            </div>
                            <div className="px-2 mt-3">
                              <ContentShimmer
                                style={{
                                  marginTop: "1rem",
                                  width: "w-100",
                                  height: "10px",
                                }}
                                rounded={"10px"}
                              />
                              <ContentShimmer
                                style={{
                                  marginTop: "1rem",
                                  width: "w-100",
                                  height: "10px",
                                }}
                                rounded={"10px"}
                              />
                              <ContentShimmer
                                style={{
                                  marginTop: "1rem",
                                  width: "w-100",
                                  height: "10px",
                                }}
                                rounded={"10px"}
                              />
                              <ContentShimmer
                                style={{
                                  marginTop: "1rem",
                                  width: "w-100",
                                  height: "10px",
                                }}
                                rounded={"10px"}
                              />
                              <ContentShimmer
                                style={{
                                  marginTop: "1rem",
                                  width: "w-100",
                                  height: "10px",
                                }}
                                rounded={"10px"}
                              />
                              <ContentShimmer
                                style={{
                                  marginTop: "1rem",
                                  width: "w-100",
                                  height: "10px",
                                }}
                                rounded={"10px"}
                              />
                              <ContentShimmer
                                style={{
                                  marginTop: "1rem",
                                  width: "w-100",
                                  height: "10px",
                                }}
                                rounded={"10px"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>

                <div className=" h-100">
                  <div className="position-absolute top-50 start-50 translate-middle">
                    <div className="">
                      <ReactBootstrap.Spinner animation="border" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default SingleCarousel;
