// import {  Ghana, Nigeria, UK, Mali,Rwanda, Tanzania, Cameroon, Gabon, Cote, Zambia, Kenya, Togo, Sierre, Uganda, Burkina, } from "../../core/resources/assets";

import { Cote, Ghana, Rwanda, Sierre, UK, Uganda, Zambia } from "../../core/resources/assets";


export const CountryData =[
    {
      country: "Ghana (HQ)",
      flag:Ghana,
      list: [
        {
          id: 1,
          content: "The Voyager, 3 Bearded Street ",
        },
        {
          id: 2,
          content: "North Legon - Accra, Ghana ",
        },
        {
          id: 3,
          content: "business@nsano.com ",
        },
        {
          id: 4,
          content: "sales@nsano.com ",
        },
        {
          id: 5,
          content: "+233 302 90 9018",
        },
      ],
    },
    {
        country: "Côte d'Ivoire",
        flag:Cote,
        list: [
          {
            id: 1,
            content: "2 Plateaux les perles en face de la pharmacie St Gabriel Abidjan, Côte d’Ivoire.",
          },
          {
            id: 2,
            content: " businessci@nsano.com ",
          },
          {
            id: 3,
            content: " sales@nsano.com ",
          },
          {
            id: 4,
            content: "+225 07 07 300 033 / +225 05 46 008 846 ",
          },    
        ],
      },
      {
        country: "Zambia",
        flag: Zambia,
        list: [
          {
            id: 1,
            content: "Lusaka - Plot number 6755, ",
          },
          {
            id: 2,
            content: "Chainama Road Olympia Ext.  ",
          },
          {
            id: 3,
            content: "business.zm@nsano.com  ",
          },
          {
            id: 4,
            content: "sales.zm@nsano.com  ",
          },
          {
            id: 5,
            content: "+260 76 7636343",
          },
        ],
      },
      // {
      //   country: "Sierra Leone",
      //   flag:Sierre,
      //   list: [
      //     {
      //       id: 1,
      //       content: "84 Pademba Road, ",
      //     },
      //     {
      //       id: 2,
      //       content: "Freetown, Sierra Leone  ",
      //     },
      //     {
      //       id: 3,
      //       content: " g.pabi@nsano.com ",
      //     },
      //     {
      //       id: 4,
      //       content: "+232 73 599 031",
      //     },
         
      //   ],
      // },
      {
        country: "United Kingdom",
        flag: UK,
        list: [
          {
            id: 1,
            content: "443 Streatham High Road, London, SW16 3PH ",
          },
          // {
          //   id: 2,
          //   content: "Bugolobi Kampala, Uganda. ",
          // },
          {
            id: 2,
            content: "business@nsano.com ",
          },
          {
            id: 3,
            content: "sales@nsano.com ",
          },
        ],
      },
      {
        country: "Rwanda",
        flag:Rwanda,
        list: [
          {
            id: 1,
            content: "4th Floor, Kigali Heights",
          }, 
          {
            id: 2,
            content: "KG 7 Avenue",
          },
          {
            id: 3,
            content: "Kigali, Rwanda",
          },
          {
            id: 4,
            content: "business.rw@nsano.com  ",
          },
          {id:5,
          content:"+250794089062"}
        ],
      },
      {
        country: "Sierra Leone",
        flag:Sierre,
        list: [
          {
            id: 1,
            content: "84 Pademba Road, Freetown,",
          }, 
          {
            id: 2,
            content: " Sierra Leone",
          },
          {
            id: 3,
            content: "sales@nsano.com",
          },
          {
            id: 4,
            content: "+232 73 599 031 ",
          },
        ],
      },
      // {
      //   country: "UK",
      //   flag:UK,
      //   list: [
      //     {
      //       id: 1,
      //       content: "",
      //     }, 
      //     {
      //       id: 2,
      //       content: " ",
      //     },
      //     {
      //       id: 3,
      //       content: " ",
      //     },
      //   ],
      // },
      // {
      //   country: "Burkina Faso",
      //   flag:Burkina,
      //   list: [
      //     {
      //       id: 1,
      //       content: "",
      //     }, 
      //     {
      //       id: 2,
      //       content: " ",
      //     },
      //     {
      //       id: 3,
      //       content: " ",
      //     },
      //   ],
      // },
      // {
      //   country: "Kenya",
      //   flag:Kenya,
      //   list: [
      //     {
      //       id: 1,
      //       content: "",
      //     }, 
      //     {
      //       id: 2,
      //       content: " ",
      //     },
      //     {
      //       id: 3,
      //       content: " ",
      //     },
      //   ],
      // },
      // {
      //   country: "Tanzania",
      //   flag:Tanzania,
      //   list: [
      //     {
      //       id: 1,
      //       content: "",
      //     }, 
      //     {
      //       id: 2,
      //       content: " ",
      //     },
      //     {
      //       id: 3,
      //       content: " ",
      //     },
      //   ],
      // },
      // {
      //   country: "Nigeria",
      //   flag:Nigeria,
      //   list: [
      //     {
      //       id: 1,
      //       content: "",
      //     }, 
      //     {
      //       id: 2,
      //       content: " ",
      //     },
      //     {
      //       id: 3,
      //       content: " ",
      //     },
      //   ],
      // },
      // {
      //   country: "Mali",
      //   flag:Mali,
      //   list: [
      //     {
      //       id: 1,
      //       content: "",
      //     }, 
      //     {
      //       id: 2,
      //       content: " ",
      //     },
      //     {
      //       id: 3,
      //       content: " ",
      //     },
      //   ],
      // },
      // {
      //   country: "Gabon",
      //   flag:Gabon,
      //   list: [
      //     {
      //       id: 1,
      //       content: "",
      //     }, 
      //     {
      //       id: 2,
      //       content: " ",
      //     },
      //     {
      //       id: 3,
      //       content: " ",
      //     },
      //   ],
      // },
      // {
      //   country: "Cameroon",
      //   flag:Cameroon,
      //   list: [
      //     {
      //       id: 1,
      //       content: "",
      //     }, 
      //     {
      //       id: 2,
      //       content: " ",
      //     },
      //     {
      //       id: 3,
      //       content: " ",
      //     },
      //   ],
      // },
     
    
  ];